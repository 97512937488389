import React, { useState } from "react";
import EIIButton from "../global_utils/EIIButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/forgotPassword`,
        { email }
      );
      if (response.data.status === "success") {
        setSuccessMessage("A reset link has been sent to your email address.");
        setErrorMessage("");

        navigate("/PasswordResetEmailSent");
      } else {
        setErrorMessage("Unable to process your request. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message ||
            "An error occurred. Please try again later."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="colored-section login-page">
      <div className="login-container">
        <h2 className="login-heading">Forgot/Reset Password</h2>
        <form className="login-form">
          <input
            type="text"
            placeholder="Email"
            className="login-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <EIIButton
            color="green"
            btnBig
            fullWidth
            onClickHandler={handleForgotPassword}
            disabled={isLoading}
          >
            {isLoading ? <div className="throbber"></div> : "Send Reset Link"}
          </EIIButton>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
        </form>
        <div className="login-options">
          <a href="/#/Login" className="login-link">
            Return to Log In
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
