import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom"; // Import Link for redirection
import EIIButton from "../global_utils/EIIButton"; // Import the EIIButton component
import "./UserVerified.css";

const UserVerified = () => {
  return (
    <div className="colored-section title-section-uv">
      <div className="center-content">
        <div className="email-icon">
          <FontAwesomeIcon icon={faCheckCircle} size="2x" />
        </div>
        <h2 className="verif-title-text">Account Verified</h2>
        <hr className="separation-line" />
      </div>
      <p className="center-text main-text">
        Your account has been successfully verified. You can now log in and
        start using our services.
      </p>
      <div className="center-text">
        <EIIButton
          color="green"
          text="Log In"
          onClickHandler={() => {
            // Redirect to the /Login page
            window.location.href = "/#/Login";
          }}
          btnBig={true}
        />
      </div>
    </div>
  );
};

export default UserVerified;
