import React, { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

// Create a context
const UserContext = createContext();

// Create a UserProvider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const RefreshUserData = useCallback(async () => {
    // Only refresh data if user is logged in or first time
    if (Cookies.get("jwt_eii")) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/me`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );
        if (response.data.status === "success") {
          setUser(response.data.data.user);
        } else {
          console.log("pepito");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Set user as logged out
        Logout();
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const ForceRefreshUserData = (data) => {
    setUser(data);
    setLoading(false);
  };

  /* Routine to add a new language */
  const AddLanguage = async () => {
    const newLanguageObj = {
      language: "",
      grammarLevel: "",
      speakingLevel: "",
      certificate: "",
      certificateGrade: 0,
      certificateIssuanceYear: 0,
    };
    const newLanguageList = [...user.languages, newLanguageObj];

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
        {
          languages: newLanguageList,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        RefreshUserData();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  /* Routine to add a new study */
  const AddStudy = async () => {
    const newStudyObj = {
      studyLevel: "",
      degreeTitle: "",
      studyCenter: "",
      startingYear: 0,
      endingYear: 0,
    };
    const newStudyList = [...user.studies, newStudyObj];

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
        {
          studies: newStudyList,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        RefreshUserData();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  /* Routine to add a new professional experience */
  const AddProfessionalExp = async () => {
    const newProfessionalExpObj = {
      engPosition: "",
      engIndustry: "",
      companyName: "",
      startingDate: 0,
      endingDate: 0,
    };
    const newProfessionalExpList = [...user.experiences, newProfessionalExpObj];

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
        {
          experiences: newProfessionalExpList,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        RefreshUserData();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  /*  Routine to delete a language  */
  const DeleteLanguage = async (languageIndex) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/deleteLanguage/${languageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        // Assuming that the response contains the updated user data
        setUser(response.data.data.user);
      }
    } catch (error) {
      console.error("Error deleting language:", error);
    }
  };

  /*  Routine to delete a study  */
  const DeleteStudy = async (studyIndex) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/deleteStudy/${studyIndex}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        // Assuming that the response contains the updated user data
        setUser(response.data.data.user);
      }
    } catch (error) {
      console.error("Error deleting study:", error);
    }
  };

  /*  Routine to delete a professional experience  */
  const DeleteProfessionalExp = async (professionalExpIndex) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/deleteProfessionalExp/${professionalExpIndex}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        // Assuming that the response contains the updated user data
        setUser(response.data.data.user);
      }
    } catch (error) {
      console.error("Error deleting professional experience:", error);
    }
  };

  const VerifyEmail = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/verify/${token}`
      );

      if (response.data.status === "success") {
        navigate("/AccountVerified");
      } else {
        console.error("Failed to verify email");
        // Handle failed verification
      }
    } catch (error) {
      console.error("Error during email verification:", error);
      // Handle error scenario
    }
  };

  const Logout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/logout`
      );
      Cookies.remove("jwt_eii");
      window.location.href = "/"; // Navigates to the root page
    } catch (error) {
      console.error("Logout error:", error);
    }

    // Then set the user to null
    setUser(undefined);
  };

  const Login = () => {
    // TODO: Implement this function
  };

  useEffect(() => {
    // Call the fetchUserData function when the component mounts
    RefreshUserData();
  }, [RefreshUserData]);

  // Routine to delete the current logged user.
  const DeleteUser = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/deleteMe`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      // Successful response (user deleted)
      if (response.status === 204) {
        Logout();
      } else {
        console.error("Failed to delete user:", response.data);
      }
    } catch (error) {
      console.error(
        "Error deleting user:",
        error.response ? error.response.data : error
      );
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        RefreshUserData,
        Logout,
        Login,
        ForceRefreshUserData,
        AddLanguage,
        AddStudy,
        AddProfessionalExp,
        DeleteLanguage,
        DeleteStudy,
        DeleteProfessionalExp,
        DeleteUser,
        VerifyEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
