import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../global_utils/UserProvider";
import EIIButton from "../global_utils/EIIButton";
import UASingleStudy from "./UASingleStudy";
import SeparationLine from "../global_utils/SeparationLine";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";

const UAStudies = () => {
  const [addingStudy, setAddingStudy] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { user, AddStudy } = useContext(UserContext);

  // Clear deleted message
  useEffect(() => {
    if (feedbackMessage) {
      const timer = setTimeout(() => {
        setFeedbackMessage("");
      }, 3000); // 3 seconds for the animation duration

      return () => clearTimeout(timer);
    }
  }, [feedbackMessage]);

  // Helper function to convert month names to numbers
  const monthToNumber = (monthName) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(monthName) + 1;
  };

  // The main sorting and indexing function
  const sortStudies = (studies) => {
    // Add original index to each study
    const studiesWithIndex = studies.map((study, index) => ({
      ...study,
      originalIndex: index,
      numericStartingMonth: monthToNumber(study.startingMonth),
      numericEndingMonth: study.endingMonth
        ? monthToNumber(study.endingMonth)
        : 12,
      isPopulated: study.degreeTitle && study.studyCenter, // Check if key fields are populated
    }));

    // Sort the studies
    studiesWithIndex.sort((a, b) => {
      // Prioritize fully populated entries
      if (a.isPopulated !== b.isPopulated) {
        return a.isPopulated ? -1 : 1;
      }

      const endYearA = a.endingYear || 9999;
      const endYearB = b.endingYear || 9999;
      const endMonthA = a.numericEndingMonth;
      const endMonthB = b.numericEndingMonth;

      if (endYearA !== endYearB) return endYearB - endYearA;
      if (endMonthA !== endMonthB) return endMonthB - endMonthA;
      if (a.startingYear !== b.startingYear)
        return b.startingYear - a.startingYear;
      return b.numericStartingMonth - a.numericStartingMonth;
    });

    return studiesWithIndex;
  };

  const addNewStudyHandler = async () => {
    // Check if there's already a study with an empty 'degreeTitle' field
    const hasEmptyStudy = user.studies.some((stud) => !stud.degreeTitle);

    if (hasEmptyStudy) {
      alert("Please fill out all existing studies before adding a new one.");
      return;
    }

    // Send the backend a new study
    setAddingStudy(true);
    await AddStudy();
    setAddingStudy(false);
  };

  const deleteStudyHandler = async () => {
    setFeedbackMessage("Study has been deleted");
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Studies</h2>
            <div className="row">
              {user &&
                sortStudies(user.studies).map((item, index) => (
                  <React.Fragment key={item.id || index}>
                    <UASingleStudy
                      studyIdx={item.originalIndex}
                      deleteStudyHandler={deleteStudyHandler}
                    />
                    <SeparationLine />
                  </React.Fragment>
                ))}
              {/* Feedback message */}
              {feedbackMessage && (
                <div className="ua-feedback-message">{feedbackMessage}</div>
              )}
            </div>
            <div className="row">
              <div className="subform-add-button-div subform-btn-col-center">
                <EIIButton
                  text={"+Add a new study"}
                  color={"green"}
                  btnBig={false}
                  onClickHandler={addNewStudyHandler}
                  disabled={addingStudy}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UAStudies;
