export const validateTextInput = (
  value,
  isRequired,
  textMaxLength,
  textMinLenght,
  isVisible
) => {
  // // TODO: Remove this
  // return true;

  // Field not required --> Always valid
  if (!isRequired) {
    return true;
  }

  // Field not visible --> Always valid
  if (!isVisible) {
    return true;
  }

  // Empty field validator
  if (value === "") {
    return false;
  }

  // Length validator
  if (value.length > textMaxLength || value.length < textMinLenght) {
    return false;
  }

  // Implement validation for other types as needed
  return true;
};

export const validateSelectInput = (value, isRequired, isVisible) => {
  // // TODO: Remove this
  // return true;

  // Field not required --> Always valid
  if (!isRequired) {
    return true;
  }

  // Field not visible --> Always valid
  if (!isVisible) {
    return true;
  }

  // Empty field validator
  if (!value || value === "") {
    return false;
  }

  // Implement validation for other types as needed
  return true;
};

export const validateDateInput = (value, isRequired, isVisible) => {
  // // TODO: Remove this
  // return true;

  // Field not required --> Always valid
  if (!isRequired) {
    return true;
  }

  // Field not visible --> Always valid
  if (!isVisible) {
    return true;
  }

  // Empty field validator
  if (value === "") {
    return false;
  }

  // Implement validation for other types as needed
  return true;
};

export const validateRadioButtonInput = (value, isRequired, isVisible) => {
  // Field not required --> Always valid
  if (!isRequired) {
    return true;
  }

  // Field not visible --> Always valid
  if (!isVisible) {
    return true;
  }

  // Empty field validator
  if (value === "") {
    return false;
  }

  // Implement validation for other types as needed
  return true;
};

export const validateCheckboxInput = (isChecked, isRequired, isVisible) => {
  // Field not required --> Always valid
  if (!isRequired) {
    return true;
  }

  // Field not visible --> Always valid
  if (!isVisible) {
    return true;
  }

  // Checked field validator for required fields
  return isChecked;
};
