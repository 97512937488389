import { Outlet } from "react-router-dom";
import EIINavbar from "../global_utils/EIINavbar";
import CookieBanner from "../global_utils/CookieBanner";

function Layout() {
  return (
    <div>
      <EIINavbar />
      <CookieBanner />
      {/* An <Outlet> renders whatever child route is currently active in App.js */}
      <Outlet />
    </div>
  );
}

export default Layout;
