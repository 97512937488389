import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPageSection from "./LandingPageSection";
import DemEngSection from "./DemEngSection";
import ForEngSection from "./ForEngSection";
import JoinCommunitySection from "./JoinCommunitySection";
import MoveToIrelandSection from "./MoveToIrelandSection";
import AboutUsSection from "./AboutUsSection";

const Home = () => {
  return (
    <>
      <LandingPageSection />
      <DemEngSection />
      <ForEngSection />
      <JoinCommunitySection />
      <MoveToIrelandSection />
      <AboutUsSection />
    </>
  );
};

export default Home;
