import React from "react";
import "./FormField.css";
import countryCodes from "../../assets/data/countryCodes.json";
import FormFieldSelect from "./FormFieldSelect";

export const maxNumCharTextField = 50;

const FormFieldPhonePrefix = ({
  fieldTitle,
  inPlaceholder,
  isRequired = false,
  activateRequired = false,
  textMaxLength = maxNumCharTextField,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  value = "",
  loading = false,
}) => {
  return (
    <>
      <FormFieldSelect
        fieldEnabled={fieldEnabled}
        inType={"select"}
        fieldTitle={fieldTitle}
        inPlaceholder={inPlaceholder}
        directInOptionsArray={countryCodes}
        isRequired={isRequired}
        activateRequired={activateRequired}
        onFieldChange={onFieldChange}
        value={value}
        textMaxLength={textMaxLength}
        isVisible={isVisible}
        loading={loading}
      />
    </>
  );
};

export default FormFieldPhonePrefix;
