import React, { useContext } from "react";
// import EIIButton from "./EIIButton";
import MapIrelandImg from "../images/HomeSection/MapaIrlanda.webp";
import GirlEngineerImg from "../images/HomeSection/AdobeStock_548475679.webp";
import { UserContext } from "../global_utils/UserProvider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EIIButton from "../global_utils/EIIButton";
import TextWithVector from "./TextWithVector";
import { useNavigate } from "react-router-dom";

const LandingPageSection = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  // Get started button handler
  const getStartedButtonHandler = () => {
    navigate("/SignUp");
  };

  return (
    <section id="Home">
      <div className="colored-section title-section">
        <Row>
          <Col sm={12} md={12} lg={6} className="title-text-col">
            <h1>Engineers in</h1>
            <TextWithVector title_type="h1" title_text="Ireland" />

            <div className="title-p-group">
              <p className="title-p">
                We provide engineers with all they need to live and work in
                Ireland.
              </p>
              <p className="title-p">Find your dream job with us!</p>
            </div>
            {!user && (
              <EIIButton
                text={"Get Started"}
                color={"blue"}
                transparent={false}
                btnBig={true}
                onClickHandler={getStartedButtonHandler}
                height="50px"
              />
            )}
          </Col>
          <Col sm={12} md={12} lg={6} className="title-img-col">
            <img
              className="title-img-ireland"
              src={MapIrelandImg}
              alt="Ireland"
            />
            <img
              className="title-img"
              src={GirlEngineerImg}
              alt="Woman Engineer"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default LandingPageSection;
