import React, { useEffect, useState } from "react";
import SideNavBarUserArea from "./SideNavBarUserArea";
import SplitScreen from "../global_utils/SplitScreen";
import EIINavbar from "../global_utils/EIINavbar";
import TopNavbarUserArea from "./TopNavbarUserArea";
import UAPersonalData from "./UAPersonalData";
import UAStudies from "./UAStudies";
import UAProfessionalExps from "./UAProfessionalExps";
import UALanguages from "./UALanguages";
import UASettings from "./UASettings";
import UASkills from "./UASkills";
import { useParams, useNavigate } from "react-router-dom";

function UserArea() {
  const { tab } = useParams();
  const navigate = useNavigate();
  // Get the computed styles of the document
  const styles = getComputedStyle(document.documentElement);
  // Access the values of CSS variables
  const leftColor = styles.getPropertyValue("--footer_bkg_color");
  const rightColor = "white";
  const [selectedMenu, setSelectedMenu] = useState(tab || "personal-data");
  // Add state for mobile view check
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize to set the initial state
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Update URL when selectedMenu changes
    navigate(`/UserArea/${selectedMenu}`);
  }, [selectedMenu, navigate]);

  const GetUAPage = () => {
    let ret_val;

    switch (selectedMenu) {
      case "personal-data":
        ret_val = <UAPersonalData />;
        break;
      case "studies":
        ret_val = <UAStudies />;
        break;
      case "professional-experience":
        ret_val = <UAProfessionalExps />;
        break;
      case "skills":
        ret_val = <UASkills />;
        break;
      case "languages":
        ret_val = <UALanguages />;
        break;
      case "settings":
        ret_val = <UASettings />;
        break;

      default:
        <UAPersonalData />;
        break;
    }

    return ret_val;
  };

  return (
    <>
      {isMobile ? (
        <>
          <TopNavbarUserArea setSelMenu={setSelectedMenu} />
          <div className="ua-mobile-form-div">{GetUAPage()}</div>
        </>
      ) : (
        <>
          <EIINavbar />
          <SplitScreen
            leftWidth={"20%"}
            leftColor={leftColor}
            leftContentCentered={false}
            rightColor={rightColor}
            leftContent={<SideNavBarUserArea setSelMenu={setSelectedMenu} />}
            rightContent={GetUAPage()}
          />
        </>
      )}
    </>
  );
}

export default UserArea;
