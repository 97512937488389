import React from "react";
import "./TagPill.css";

const TagPill = ({ tag, onRemove, isEditing = true }) => {
  const handleRemoveClick = (event) => {
    event.preventDefault();
    onRemove(tag);
  };

  // Add a class based on isEditing prop
  const pillClass = isEditing ? "tag-pill editing" : "tag-pill";

  return (
    <div className={pillClass}>
      {tag}
      <button className="remove-button" onClick={handleRemoveClick}>
        X
      </button>
    </div>
  );
};

export default TagPill;
