import React from "react";
import { validateDateInput } from "./FormFieldValidation";
import Throbber from "./Throbber";
import "./FormField.css";

const FormFieldDate = ({
  fieldTitle,
  inPlaceholder,
  isRequired = false,
  activateRequired = false,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  value = "",
  loading = false,
}) => {
  const handleInputChange = (e) => {
    onFieldChange({
      value: e.target.value,
      isValid: validateDateInput(e.target.value, isRequired, isVisible),
      isRequired,
    });
  };

  if (!isVisible) {
    return null;
  }

  const inputClass =
    activateRequired && !validateDateInput(value, isRequired, isVisible)
      ? "formfield-input-wrapper required"
      : "formfield-input-wrapper";

  // Set title-blocked class
  let title_blocked_class = "";
  if (!fieldEnabled) title_blocked_class = "title-blocked";

  return (
    <>
      {fieldTitle && (
        <p className={`formfield-p ${title_blocked_class}`}>
          {fieldTitle}
          {isRequired && fieldEnabled && (
            <span className="required-asterisk">*</span>
          )}
        </p>
      )}
      <div className={inputClass}>
        {loading ? (
          <Throbber justify="left" className={"loading-field"} />
        ) : (
          <input
            type={"date"}
            placeholder={inPlaceholder}
            className="formfield-input"
            value={value}
            disabled={!fieldEnabled}
            onChange={handleInputChange}
          />
        )}
      </div>
    </>
  );
};

export default FormFieldDate;
