import React from 'react';
import { HashLink } from 'react-router-hash-link';


const PostTpl = ({title, date, type, content}) => {

    return(
        <>
            <div className='content-section post-tpl-div'>
                <HashLink className='p-more-posts' smooth to="/#AboutIreland"><p>&#60; More posts</p></HashLink>
                <h2>{title}</h2>
                <h5 className='post-tpl-h5'>{date} - {type}</h5>
                <div className='post-tpl-content'>
                    {content}                
                </div>
            </div>
        </>
    )
}

export default PostTpl