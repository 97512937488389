import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import EIIButton from "../global_utils/EIIButton";
import "./UserVerificationSent.css";

const PasswordResetCompleted = () => {
  return (
    <div className="colored-section title-section-uvs">
      <div className="center-content">
        <div className="email-icon">
          <FontAwesomeIcon icon={faKey} size="2x" />
        </div>
        <h2 className="verif-title-text">Password has been reset!</h2>
        {/* <h4 className="verif-title-text">
          Please log in using your new password
        </h4> */}
        <hr className="separation-line" />
      </div>
      <p className="center-text secondary-text">
        Please log in using your new password
      </p>
      <div className="center-text">
        <EIIButton
          color="green"
          text="Log In"
          onClickHandler={() => {
            // Redirect to the /Login page
            window.location.href = "/#/Login";
          }}
          btnBig={true}
        />
      </div>
    </div>
  );
};

export default PasswordResetCompleted;
