import React from "react";
import PostParagraph from "../PostParagraph";
import PostTip from "../PostTip";
import PostTpl from "../PostTpl";
import "./Posts.css";

const PostWorkingVisa = () => {
  return (
    <>
      <PostTpl
        title="How to apply for a working visa in Ireland?"
        date="01 APR 2023"
        type="WORK"
        content={
          <>
            <PostParagraph
              text={
                <p className="content-p">
                  Citizens of EEA (the EU, plus Iceland, Norway and
                  Lichtenstein), Switzerland and the UK can live and work within
                  the Irish territory without the need for a working permit.
                  However, you will require a working visa if you are not from
                  any of the countries listed above. If you moved to Ireland to
                  join a family member, you may be allowed to&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://www.citizensinformation.ie/en/moving_country/working_in_ireland/migrant_workers/coming_to_work_in_ireland.html"
                  >
                    work without an employment permit
                  </a>
                  .
                </p>
              }
            />

            <h3 className="post-subtitle">
              Do I need a job before applying for a working visa in Ireland?
            </h3>

            <PostParagraph text="Yes, you usually need to have a job offer to apply for an employment permit (working visa) from a company registered with the Irish Revenue Commissioners. You can apply for it yourself or your employer can do it for you." />

            <h3 className="post-subtitle">
              What type of employment permit should I apply for?
            </h3>

            <PostParagraph
              text={
                <p className="content-p">
                  There are 9 different types of employment permits that you can
                  find described in the Department of Enterprise, Trade and
                  Employment (DETE)&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://epos.enterprise.gov.ie/#/app/findFormAssistant"
                  >
                    application guide
                  </a>
                  .
                </p>
              }
            />

            <PostParagraph text="The most common employment permits for engineers are the following:" />

            <PostParagraph
              text={
                <p className="content-p">
                  <strong>Critical Skills Employment Permit</strong> is intended
                  for people with professions that are listed within the&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://enterprise.gov.ie/en/what-we-do/workplace-and-skills/employment-permits/employment-permit-eligibility/highly-skilled-eligible-occupations-list/"
                  >
                    Critical Skills Occupation List
                  </a>
                  . Some of the engineering professions listed are the
                  following:
                </p>
              }
            />

            <PostParagraph
              text={
                <ul className="content-p">
                  <li className="post-list">Automation Engineers</li>
                  <li className="post-list">Civil Engineers</li>
                  <li className="post-list">
                    Structural Engineers and Site Engineers
                  </li>
                  <li className="post-list">Mechanical Engineers</li>
                  <li className="post-list">Electrical Engineers</li>
                  <li className="post-list">Electronics Engineers</li>
                  <li className="post-list">
                    Design and Development Engineers
                  </li>
                  <li className="post-list">Chemical Engineers</li>
                </ul>
              }
            />

            <PostParagraph
              text={
                <p className="content-p">
                  <strong>General Employment Permit</strong> is an employment
                  permit which allows the permit holder to be employed in
                  Ireland in a broad range of occupations. All occupations are
                  eligible unless excluded from the&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://enterprise.gov.ie/en/what-we-do/workplace-and-skills/employment-permits/employment-permit-eligibility/ineligible-categories-of-employment/"
                  >
                    list of Ineligible Categories of Employment
                  </a>
                  . The following engineering professions are on the list:
                </p>
              }
            />

            <PostParagraph
              text={
                <ul className="content-p">
                  <li className="post-list">TV, video and audio Engineers</li>
                </ul>
              }
            />

            <PostTip
              title="Check your eligibility in advance"
              text="Before you start your job search, make sure that your profession is not within the list of Ineligible Categories of Employment. If your profession is within the Critical Skills Occupation List you can mention it during your job application (e.g. in the Cover Letter)."
            />

            <h3 className="post-subtitle">
              I have received a job offer in Ireland, what’s next?
            </h3>

            <PostParagraph
              text={
                <p className="content-p">
                  Generally, when you receive a work offer in Ireland, your
                  employer will directly apply for an employment permit on your
                  behalf. However, if they require you to apply you can&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://enterprise.gov.ie/en/what-we-do/workplace-and-skills/employment-permits/contact/"
                  >
                    submit your application to the DETE
                  </a>
                  .
                </p>
              }
            />

            <PostParagraph
              text={
                <p className="content-p">
                  You can check the&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://enterprise.gov.ie/en/what-we-do/workplace-and-skills/employment-permits/current-application-processing-dates/"
                  >
                    current processing dates for employment permits
                  </a>
                  &nbsp;within DETE’s portal. If your application is refused,
                  you must be given the specific reasons for it and you can
                  appeal the decision within 28 days by submitting the&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://enterprise.gov.ie/en/publications/submission-of-a-decision-for-review-under-section-13-or-17-of-the-employment-permits-act-2006-as-amended-.html"
                  >
                    Review of Decision Form
                  </a>
                  .
                </p>
              }
            />

            <PostTip
              title="Apply for jobs strategically"
              text="Research industries are companies that are known to sponsor employment permits. Check on portals such as LinkedIn for professionals that are working within these companies and that might hold an employment permit. Review the job offers carefully as some of them might directly indicate that they are not sponsoring working visas."
            />
          </>
        }
      />
    </>
  );
};

export default PostWorkingVisa;
