// GoogleLoginButton.js
import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import EIIButton from "./EIIButton";
import GoogleIcon from "./GoogleIcon";
import Throbber from "./Throbber"; // Import your throbber component

const GoogleLoginButton = ({ buttonText, onSuccess, onFailure, color }) => {
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
      redirectUri={process.env.REACT_APP_FRONTEND_URL}
      render={(renderProps) => (
        <EIIButton
          color={color}
          text={buttonText}
          onClickHandler={renderProps.onClick}
          disabled={renderProps.disabled}
          transparent={true}
          custom_class="rounded"
          style={{ width: "100%" }}
          icon={<GoogleIcon />}
          iconPosition="left"
          fullWidth={true}
        />
      )}
    />
  );
};

export default GoogleLoginButton;
