import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRouteAgainsLoggedInUsers from "./components/global_utils/ProtectedRouteAgainsLoggedInUsers";
import ProtectedRouteAgainsLoggedOutUsers from "./components/global_utils/ProtectedRouteAgainsLoggedOutUsers";
import Layout from "./components/page_home/Layout";
import Home from "./components/page_home/Home";
import TestPost from "./components/page_home/TestPost";
import ScrollToTop from "./components/global_utils/ScrollToTop";
import PrivacyPolicy from "./components/page_home/PrivacyPolicy";
import TermsAndConditions from "./components/page_home/TermsAndConditions";
import PostDublinAccommodation from "./components/page_home/posts/PostDublinAccommodation";
import PostFindJobEngineer from "./components/page_home/posts/PostFindJobEngineer";
import PostWorkingVisa from "./components/page_home/posts/PostWorkingVisa";
import PostMoveIrelandEU from "./components/page_home/posts/PostMoveIrelandEU";
import PostAdjustingToLife from "./components/page_home/posts/PostAdjustingToLife";
import PostHighestPaidEngineers from "./components/page_home/posts/PostHighestPaidEngineers";
import PostDigitalNomadIreland from "./components/page_home/posts/PostDigitalNomadIreland";
import PostBestAreasToLive from "./components/page_home/posts/PostBestAreasToLive";
import PostAmericaWorkVisaIreland from "./components/page_home/posts/PostAmericaWorkVisaIreland";
import UserArea from "./components/user_area/UserArea";
import LogInPage from "./components/authorization/LogInPage";
import SignUpPage from "./components/authorization/SignUpPage";
import PasswrodResetEmailSent from "./components/authorization/PasswordResetEmailSent";
import UserVerificationSent from "./components/authorization/UserVerificationSent";
import UserVerified from "./components/authorization/UserVerified";
import Footer from "./components/global_utils/Footer";
import { UserProvider } from "./components/global_utils/UserProvider";
import ForgotPassword from "./components/authorization/ForgotPassword";
import ResetPassword from "./components/authorization/ResetPassword";
import PasswordResetCompleted from "./components/authorization/PasswordResetCompleted";
import VerifyEmail from "./components/authorization/VerifyEmail";
import CookieBanner from "./components/global_utils/CookieBanner";
import ReactGA4 from "react-ga4";
import Cookies from "js-cookie";

function App() {
  const location = useLocation();

  useEffect(() => {
    const handleConsentGranted = () => {
      const MEASUREMENT_ID = "G-9GPYB3QMYZ";
      ReactGA4.initialize(MEASUREMENT_ID);

      // Track the initial pageview upon consent
      if (Cookies.get("CookieConsentEII") === "accept") {
        ReactGA4.send({ hitType: "pageview", page: location.pathname });
      }
    };

    // Add event listener for consent
    document.addEventListener("consentGranted", handleConsentGranted);

    // Track pageview on location change only if consented
    if (Cookies.get("CookieConsentEII") === "accept") {
      ReactGA4.send({ hitType: "pageview", page: location.pathname });
    }

    // Cleanup
    return () => {
      document.removeEventListener("consentGranted", handleConsentGranted);
    };
  }, [location]);

  return (
    <>
      <UserProvider>
        <Routes>
          {/* Routes that include EIINavbar by default */}
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <>
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/TestPost"
              element={
                <>
                  <ScrollToTop />
                  <TestPost />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostAdjustingToLife"
              element={
                <>
                  <ScrollToTop />
                  <PostAdjustingToLife />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostDigitalNomadIreland"
              element={
                <>
                  <ScrollToTop />
                  <PostDigitalNomadIreland />
                  <Footer />
                </>
              }
            />
            <Route
              path="/DublinAccommodation"
              element={
                <>
                  <ScrollToTop />
                  <PostDublinAccommodation />
                  <Footer />
                </>
              }
            />
            <Route
              path="/FindEngJobIreland"
              element={
                <>
                  <ScrollToTop />
                  <PostFindJobEngineer />
                  <Footer />
                </>
              }
            />
            <Route
              path="/WorkingVisaIreland"
              element={
                <>
                  <ScrollToTop />
                  <PostWorkingVisa />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostMoveIrelandEU"
              element={
                <>
                  <ScrollToTop />
                  <PostMoveIrelandEU />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostHighetsPaidEngineers"
              element={
                <>
                  <ScrollToTop />
                  <PostHighestPaidEngineers />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostBestAreasToLive"
              element={
                <>
                  <ScrollToTop />
                  <PostBestAreasToLive />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PostAmericaWorkVisaIreland"
              element={
                <>
                  <ScrollToTop />
                  <PostAmericaWorkVisaIreland />
                  <Footer />
                </>
              }
            />

            <Route
              path="/PrivacyPolicy"
              element={
                <>
                  <ScrollToTop />
                  <PrivacyPolicy />
                  <Footer />
                </>
              }
            />
            <Route
              path="/TermsAndConditions"
              element={
                <>
                  <ScrollToTop />
                  <TermsAndConditions />
                  <Footer />
                </>
              }
            />
            <Route
              path="/LogIn"
              element={
                <ProtectedRouteAgainsLoggedInUsers>
                  <ScrollToTop />
                  <LogInPage />
                  <Footer />
                </ProtectedRouteAgainsLoggedInUsers>
              }
            />
            <Route
              path="/SignUp"
              element={
                <ProtectedRouteAgainsLoggedInUsers>
                  <ScrollToTop />
                  <SignUpPage />
                  {/* No footer */}
                </ProtectedRouteAgainsLoggedInUsers>
              }
            />
            <Route
              path="/ForgotPassword"
              element={
                <>
                  <ScrollToTop />
                  <ForgotPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path="/ResetPassword/:token"
              element={
                <>
                  <ScrollToTop />
                  <ResetPassword />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PasswordResetEmailSent"
              element={
                <>
                  <ScrollToTop />
                  <PasswrodResetEmailSent />
                  <Footer />
                </>
              }
            />
            <Route
              path="/PasswordResetCompleted"
              element={
                <>
                  <ScrollToTop />
                  <PasswordResetCompleted />
                  <Footer />
                </>
              }
            />
            <Route
              path="/VerifyEmail/:token"
              element={
                <>
                  <ScrollToTop />
                  <VerifyEmail />
                </>
              }
            />
            <Route
              path="/VerificationEmailSent"
              element={
                <>
                  <ScrollToTop />
                  <UserVerificationSent />
                  <Footer />
                </>
              }
            />
            <Route
              path="/AccountVerified"
              element={
                <>
                  <ScrollToTop />
                  <UserVerified />
                  <Footer />
                </>
              }
            />
            <Route path="*" element={<Home />} />
          </Route>

          {/* Routes that don't include EIINavbar by default */}
          <Route
            path="/UserArea/:tab?"
            element={
              <ProtectedRouteAgainsLoggedOutUsers>
                <ScrollToTop />
                <CookieBanner />
                <UserArea />
              </ProtectedRouteAgainsLoggedOutUsers>
            }
          />
        </Routes>
      </UserProvider>
    </>
  );
}

export default App;
