import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../global_utils/UserProvider";
import EIIButton from "../global_utils/EIIButton";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldDate from "../global_utils/FormFieldDate";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldPhonePrefix from "../global_utils/FormFieldPhonePrefix";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import nationalities from "../../assets/data/nationalities.json";
import readinessToStartList from "../../assets/data/readinessToStart.json";
import workLocationOptionsList from "../../assets/data/workLocationOptions.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const UAPersonalData = () => {
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const { user, loading, RefreshUserData } = useContext(UserContext);

  // State for information message
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [firstName, setFirstName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editFirstName, setEditFirstName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [lastName, setLastName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editLastName, setEditLastName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editDateOfBirth, setEditDateOfBirth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [gender, setGender] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editGender, setEditGender] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [phonePrefix, setPhonePrefix] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editPhonePrefix, setEditPhonePrefix] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editPhoneNumber, setEditPhoneNumber] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [firstCitizenship, setFirstCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editFirstCitizenship, setEditFirstCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [secondCitizenship, setSecondCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editSecondCitizenship, setEditSecondCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [readinessToStart, setReadinessToStart] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editReadinessToStart, setEditReadinessToStart] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [preferredWorkLocation, setPreferredWorkLocation] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editPreferredWorkLocation, setEditPreferredWorkLocation] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      firstName: editFirstName,
      lastName: editLastName,
      dateOfBirth: editDateOfBirth,
      gender: editGender,
      phonePrefix: editPhonePrefix,
      phoneNumber: editPhoneNumber,
      firstCitizenship: editFirstCitizenship,
      secondCitizenship: editSecondCitizenship,
      readinessToStart: editReadinessToStart,
      preferredWorkLocation: editPreferredWorkLocation,
    });
  }, [
    editFirstName,
    editLastName,
    editDateOfBirth,
    editGender,
    editPhonePrefix,
    editPhoneNumber,
    editFirstCitizenship,
    editSecondCitizenship,
    editReadinessToStart,
    editPreferredWorkLocation,
  ]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      firstName: {
        value: uData.firstName || "",
        isValid: true,
        isRequired: true,
      },
      lastName: {
        value: uData.lastName || "",
        isValid: true,
        isRequired: true,
      },
      dateOfBirth: {
        value: uData.dateOfBirth
          ? new Date(uData.dateOfBirth).toISOString().split("T")[0]
          : "",
        isValid: true,
        isRequired: true,
      },
      gender: {
        value: {
          value: uData.gender || "",
          label: uData.gender || "",
        },
        isValid: true,
        isRequired: true,
      },
      phonePrefix: {
        value: uData.phonePrefix || "",
        isValid: true,
        isRequired: true,
      },
      phoneNumber: {
        value: uData.phoneNumber || "",
        isValid: true,
        isRequired: true,
      },
      firstCitizenship: {
        value: {
          value: uData.firstCitizenship || "",
          label: uData.firstCitizenship || "",
        },
        isValid: true,
        isRequired: true,
      },
      secondCitizenship: {
        value: {
          value: uData.secondCitizenship || "",
          label: uData.secondCitizenship || "",
        },
        isValid: true,
        isRequired: false,
      },
      readinessToStart: {
        value: {
          value: uData.readinessToStart || "",
          label: uData.readinessToStart || "",
        },
        isValid: true,
        isRequired: false,
      },
      preferredWorkLocation: {
        value: {
          value: uData.preferredWorkLocation || "",
          label: uData.preferredWorkLocation || "",
        },
        isValid: true,
        isRequired: false,
      },
    };

    // Initialize First Name
    setFirstName(newFormData.firstName);
    setEditFirstName(newFormData.firstName);

    // Initialize Last Name
    setLastName(newFormData.lastName);
    setEditLastName(newFormData.lastName);

    // Initialize Date of Birth
    setDateOfBirth(newFormData.dateOfBirth);
    setEditDateOfBirth(newFormData.dateOfBirth);

    // Initialize Gender
    setGender(newFormData.gender);
    setEditGender(newFormData.gender);

    // Initialize phone prefix
    setPhonePrefix(newFormData.phonePrefix);
    setEditPhonePrefix(newFormData.phonePrefix);

    // Initialize phone number
    setPhoneNumber(newFormData.phoneNumber);
    setEditPhoneNumber(newFormData.phoneNumber);

    // Initialize first citizenship
    setFirstCitizenship(newFormData.firstCitizenship);
    setEditFirstCitizenship(newFormData.firstCitizenship);

    // Initialize second citizenship
    setSecondCitizenship(newFormData.secondCitizenship);
    setEditSecondCitizenship(newFormData.secondCitizenship);

    // Initialize readinessToStart
    setReadinessToStart(newFormData.readinessToStart);
    setEditReadinessToStart(newFormData.readinessToStart);

    // Initialize preferredWorkLocation
    setPreferredWorkLocation(newFormData.preferredWorkLocation);
    setEditPreferredWorkLocation(newFormData.preferredWorkLocation);
  };

  const validateForm = () => {
    const dob = new Date(editDateOfBirth.value);
    const today = new Date();
    const oldestValidDate = new Date();
    const youngestValidDate = new Date();

    oldestValidDate.setFullYear(today.getFullYear() - 120); // Person can't be more than 120 years old
    youngestValidDate.setFullYear(today.getFullYear() - 18); // Person must be at least 18 years old

    let isValid = true;

    if (dob > today) {
      setErrorMessage("Date of birth can't be in the future.");
      isValid = false;
    } else if (dob < oldestValidDate) {
      setErrorMessage(
        "Date of birth is not valid. Age limit exceeded (over 120 years old)."
      );
      isValid = false;
    } else if (dob > youngestValidDate) {
      setErrorMessage(
        "Registration denied. You must be at least 18 years old."
      );
      isValid = false;
    }

    if (!isValid) {
      // Clear error message after 3 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }

    return isValid;
  };

  const submitPersonalData = async (submitData) => {
    // Disable submit
    setEnableSubmit(false);

    // Validate all required fields
    const allRequiredFieldsValid = Object.keys(fieldsData).every(
      (key) => !fieldsData[key].isRequired || fieldsData[key].isValid // The field is required and valid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid && validateForm()) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
          {
            firstName: fieldsData.firstName.value,
            lastName: fieldsData.lastName.value,
            dateOfBirth: fieldsData.dateOfBirth.value,
            gender: fieldsData.gender.value.value,
            phonePrefix: fieldsData.phonePrefix.value,
            phoneNumber: fieldsData.phoneNumber.value,
            firstCitizenship: fieldsData.firstCitizenship.value?.value || "",
            secondCitizenship: fieldsData.secondCitizenship.value?.value || "",
            readinessToStart: fieldsData.readinessToStart.value?.value || "",
            preferredWorkLocation:
              fieldsData.preferredWorkLocation.value?.value || "",
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Ask UserProvider to refresh user data
          RefreshUserData();

          // Save the edited firstName
          setFirstName(editFirstName);
          // Save the edited lastName
          setLastName(editLastName);
          // Save the edited dateOfBirth
          setDateOfBirth(editDateOfBirth);
          // Save the edited gender
          setGender(editGender);
          // Save the edited phone prefix
          setPhonePrefix(editPhonePrefix);
          // Save the edited phone number
          setPhoneNumber(editPhoneNumber);
          // Save the edited first citizenship
          setFirstCitizenship(editFirstCitizenship);
          // Save the edited second citizenship
          setSecondCitizenship(editSecondCitizenship);
          // Save the edited readinessToStart
          setReadinessToStart(editReadinessToStart);
          // Save the edited preferredWorkLocation
          setPreferredWorkLocation(editPreferredWorkLocation);
        } else {
          console.error("Failed to update user");
        }

        // Enable submit
        setEnableSubmit(true);
      } catch (error) {
        console.error("Error updating user data:", error);
        // Enable submit
        setEnableSubmit(true);
      }

      setEditEnable(false);
    } else {
      // Activate Required Fields
      setActivateRequiredFields(true);

      // Enable submit
      setEnableSubmit(true);
    }
  };

  // Routine to handle first name
  const handleFirstNameChange = (value) => {
    if (editEnable) {
      setEditFirstName(value);
    }
  };

  // Routine to handle last name
  const handleLastNameChange = (value) => {
    if (editEnable) {
      setEditLastName(value);
    }
  };

  // Routine to handle date of birth
  const handleDateOfBirthChange = (value) => {
    if (editEnable) {
      setEditDateOfBirth(value);
    }
  };

  // Routine to handle gender
  const handleGenderChange = (value) => {
    if (editEnable) {
      setEditGender(value);
    }
  };

  // Routine to handle phone prefix
  const handlePhonePrefixChange = (value) => {
    if (editEnable) {
      setEditPhonePrefix(value);
    }
  };

  // Routine to handle phone number
  const handlePhoneNumberChange = (value) => {
    if (editEnable) {
      setEditPhoneNumber(value);
    }
  };

  // Routine to handle fist citizenship
  const handleFirstCitizenshipChange = (value) => {
    if (editEnable) {
      setEditFirstCitizenship(value);

      // Check visa required
      checkVisaRequired(value.value.value, secondCitizenship.value.value);
    }
  };

  // Routine to handle Second Citizenship
  const handleSecondCitizenshipChange = (value) => {
    if (editEnable) {
      setEditSecondCitizenship(value);

      // Check visa required
      checkVisaRequired(firstCitizenship.value.value, value.value.value);
    }
  };

  // Routine to handle readinessToStart
  const handleReadinessToStartChange = (value) => {
    if (editEnable) {
      setEditReadinessToStart(value);
    }
  };

  // Routine to handle preferredWorkLocation
  const handlePreferredWorkLocationChange = (value) => {
    if (editEnable) {
      setEditPreferredWorkLocation(value);
    }
  };

  // Routine to verify if visa is required and set info message
  const checkVisaRequired = (fCitizenship, sCitizenship) => {
    // No nationalities selected
    if (!fCitizenship && !sCitizenship) {
      setInfoMessage("");
      return;
    }

    if (
      fCitizenship in nationalities.nationalities &&
      !nationalities.nationalities[fCitizenship].visaRequiredIreland
    ) {
      setInfoMessage(
        `✔️ ${fCitizenship} nationality doesn't require a working visa in Ireland!`
      );
      return;
    } else if (
      sCitizenship in nationalities.nationalities &&
      !nationalities.nationalities[sCitizenship].visaRequiredIreland
    ) {
      setInfoMessage(
        `✔️ ${sCitizenship} nationality doesn't require a working visa in Ireland!`
      );
      return;
    } else if (fCitizenship && fCitizenship !== "") {
      setInfoMessage(" You require a working visa in Ireland..");
      return;
    }

    // Visa is required
    return;
  };

  // Handler for NEXT button
  const editButtonHandler = () => {
    setEditEnable(true);
  };

  // Handler for CANCEL button
  const cancelHandler = () => {
    // Revert the edit fields to the saved fields
    setEditFirstName(firstName);
    setEditLastName(lastName);
    setEditDateOfBirth(dateOfBirth);
    setEditGender(gender);
    setEditPhonePrefix(phonePrefix);
    setEditPhoneNumber(phoneNumber);
    setEditFirstCitizenship(firstCitizenship);
    setEditSecondCitizenship(secondCitizenship);
    setEditReadinessToStart(readinessToStart);
    setEditPreferredWorkLocation(preferredWorkLocation);

    // Deactivate required fields
    setActivateRequiredFields(false);

    // Disable Edit
    setEditEnable(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            {editEnable ? (
              <form className="signup-form">
                <h2 className={`signupform-title`}>Personal Data</h2>
                <div className="row">
                  <div className={"col-md-6"}>
                    <FormFieldText
                      fieldEnabled={editEnable}
                      fieldTitle={"First name"}
                      inPlaceholder={"Enter your first name"}
                      isRequired={true}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handleFirstNameChange}
                      value={editFirstName.value}
                      loading={loading}
                    />
                  </div>
                  <div className={"col-md-6"}>
                    <FormFieldText
                      fieldEnabled={editEnable}
                      fieldTitle={"Last name"}
                      inPlaceholder={"Enter your last name"}
                      isRequired={true}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handleLastNameChange}
                      value={editLastName.value}
                      loading={loading}
                    />
                  </div>
                </div>
                <FormFieldDate
                  fieldEnabled={editEnable}
                  fieldTitle={"Date of birth"}
                  inPlaceholder={"Enter your date of birth"}
                  isRequired={true}
                  activateRequired={activateRequiredFields}
                  onFieldChange={handleDateOfBirthChange}
                  value={editDateOfBirth.value}
                  loading={loading}
                />
                <FormFieldSelect
                  fieldEnabled={editEnable}
                  fieldTitle={"Gender"}
                  inPlaceholder={"Select your gender"}
                  inOptionsArray={["Male", "Female", "Other"]}
                  isRequired={true}
                  activateRequired={activateRequiredFields}
                  onFieldChange={handleGenderChange}
                  value={editGender.value}
                  loading={loading}
                />
                <div className="row">
                  <div className={"col-md-4"}>
                    <FormFieldPhonePrefix
                      fieldEnabled={editEnable}
                      fieldTitle={"Phone Number"}
                      inPlaceholder={"Prefix"}
                      isRequired={true}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handlePhonePrefixChange}
                      value={editPhonePrefix.value}
                      loading={loading}
                    />
                  </div>
                  <div className={"col-md-8 align-bottom"}>
                    <FormFieldText
                      fieldEnabled={editEnable}
                      inPlaceholder={"Enter your phone number"}
                      isRequired={true}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handlePhoneNumberChange}
                      value={editPhoneNumber.value}
                      allowOnlyIntegers
                      textMinLenght={7}
                      textMaxLength={15}
                      loading={loading}
                    />
                  </div>
                </div>

                <h2 className={`signupform-title2`}>Citizenship</h2>

                <div className="row">
                  <div className={"col-md-6"}>
                    <FormFieldSelect
                      fieldEnabled={editEnable}
                      fieldTitle={"First Citizenship"}
                      inPlaceholder={"Enter your first citizenship"}
                      inOptionsArray={Object.keys(nationalities.nationalities)}
                      isRequired={true}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handleFirstCitizenshipChange}
                      value={editFirstCitizenship.value}
                      loading={loading}
                    />
                  </div>
                  <div className={"col-md-6"}>
                    <FormFieldSelect
                      fieldEnabled={editEnable}
                      fieldTitle={"Second Citizenship"}
                      inPlaceholder={"Enter your second citizenship"}
                      inOptionsArray={[
                        "I don't have a second citizenship",
                        ...Object.keys(nationalities.nationalities),
                      ]}
                      isRequired={false}
                      activateRequired={activateRequiredFields}
                      onFieldChange={handleSecondCitizenshipChange}
                      value={editSecondCitizenship.value}
                      loading={loading}
                    />
                  </div>
                </div>
                {infoMessage && (
                  <div className="row">
                    <p
                      className="col text-center"
                      style={{
                        color: infoMessage.includes(
                          "nationality doesn't require a working visa in Ireland!"
                        )
                          ? "var(--btn_green_dark2_color)"
                          : "var(--btn_blue_dark_color)",
                        marginTop: "30px",
                      }}
                    >
                      {infoMessage.includes(
                        "You require a working visa in Ireland"
                      ) && (
                        <FontAwesomeIcon
                          icon={faFileCircleExclamation}
                          size="1x"
                        />
                      )}
                      {infoMessage}
                    </p>
                  </div>
                )}
                <h2 className={`signupform-title2`}>Working Preferences</h2>
                <div className="row">
                  <FormFieldSelect
                    fieldEnabled={editEnable}
                    fieldTitle={"Readiness to start working"}
                    inPlaceholder={
                      "Enter when can you start working in Ireland"
                    }
                    inOptionsArray={readinessToStartList}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleReadinessToStartChange}
                    value={editReadinessToStart.value}
                    loading={loading}
                  />
                </div>
                <div className="row">
                  <FormFieldSelect
                    fieldEnabled={editEnable}
                    fieldTitle={"Preferred work location"}
                    inPlaceholder={
                      "Enter where you prefer to work within Ireland"
                    }
                    inOptionsArray={workLocationOptionsList}
                    isRequired={true}
                    allowTyping={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handlePreferredWorkLocationChange}
                    value={editPreferredWorkLocation.value}
                    loading={loading}
                  />
                </div>
              </form>
            ) : (
              <>
                <h2 className={`signupform-title`}>Personal Data</h2>
                <p className="subform-text-spaced-p">
                  <strong>Name:</strong>{" "}
                  {editFirstName.value && editLastName.value
                    ? `${editFirstName.value} ${editLastName.value}`
                    : ""}
                </p>
                <p className="subform-text-spaced-p">
                  <strong>Date of Birth:</strong>{" "}
                  {editDateOfBirth.value
                    ? formatDate(editDateOfBirth.value)
                    : ""}
                </p>
                <p className="subform-text-spaced-p">
                  <strong>Gender:</strong>{" "}
                  {editGender.value ? editGender.value.label : ""}
                </p>
                <p className="subform-text-spaced-p">
                  <strong>Phone:</strong>{" "}
                  {editPhonePrefix.value && editPhoneNumber.value
                    ? `${editPhonePrefix.value.value} ${editPhoneNumber.value}`
                    : ""}
                </p>

                <h2 className={`signupform-title2`}>Citizenship</h2>
                <p className="subform-text-spaced-p">
                  <strong>First Citizenship:</strong>{" "}
                  {editFirstCitizenship.value
                    ? editFirstCitizenship.value.label
                    : ""}
                </p>
                {editSecondCitizenship &&
                  editSecondCitizenship.value.value !== "" && (
                    <p className="subform-text-spaced-p">
                      <strong>Second Citizenship:</strong>{" "}
                      {editSecondCitizenship.value
                        ? editSecondCitizenship.value.label
                        : ""}
                    </p>
                  )}

                <h2 className={`signupform-title2`}>Working Preferences</h2>
                <p className="subform-text-spaced-p">
                  <strong>Readiness to Start:</strong>{" "}
                  {editReadinessToStart.value
                    ? editReadinessToStart.value.label
                    : ""}
                </p>
                <p className="subform-text-spaced-p">
                  <strong>Preferred Work Location:</strong>{" "}
                  {editPreferredWorkLocation.value
                    ? editPreferredWorkLocation.value.label
                    : ""}
                </p>
              </>
            )}
          </div>
        </div>
        {errorMessage && (
          <div className="row">
            <div
              className="col text-center"
              style={{ color: "red", marginTop: "20px" }}
            >
              {errorMessage}
            </div>
          </div>
        )}
        <div className="row right-bottom-row">
          <div className="row">
            <div className="col-md-6 subform-btn-col-left">
              {editEnable && (
                <EIIButton
                  text={"Cancel"}
                  color={"green"}
                  transparent
                  btnBig={false}
                  onClickHandler={cancelHandler}
                  width="100px"
                  height="50px"
                />
              )}
            </div>
            <div className="col-md-6 subform-btn-col-right">
              {!editEnable && (
                <EIIButton
                  text={"Edit"}
                  color={"green"}
                  transparent
                  btnBig={false}
                  onClickHandler={editButtonHandler}
                  width="100px"
                  height="50px"
                />
              )}
              {editEnable && (
                <EIIButton
                  text={"Submit"}
                  color={"green"}
                  btnBig={false}
                  disabled={!enableSubmit}
                  onClickHandler={submitPersonalData}
                  width="100px"
                  height="50px"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UAPersonalData;
