import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "./SideNavBarUserArea.css";

const SideNavBarUserArea = ({ setSelMenu }) => {
  const { tab } = useParams(); // Get the current tab from the URL
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState(tab || "personal-data"); // Use URL param or default

  useEffect(() => {
    // Sent selected menu to the upper component
    setSelMenu(selectedMenu);
    // Update the URL when the selectedMenu changes
    navigate(`/UserArea/${selectedMenu}`);
  }, [selectedMenu, setSelMenu, navigate]);

  return (
    <>
      <Nav
        className="sidebar"
        activeKey={selectedMenu}
        onSelect={(selectedKey) => {
          setSelectedMenu(selectedKey);
        }}
      >
        <div className="sidebar-up-space"></div>
        <Nav.Item>
          <Nav.Link eventKey="personal-data">Personal Data</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="studies">Studies</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="professional-experience">
            Professional Experience
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="skills">Skills</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="languages">Languages</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="settings">Settings</Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default SideNavBarUserArea;
