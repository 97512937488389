import React, { useState } from "react";
import { validateRadioButtonInput } from "./FormFieldValidation";
import "./FormField.css";
import Throbber from "./Throbber";

const FormFieldRadioButton = ({
  fieldTitle,
  inPlaceholder,
  isRequired = false,
  activateRequired = false,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  inOptionsArray = undefined,
  directInOptionsArray = undefined,
  value = "",
  loading = false,
  radioButtonsInSingleRow = true,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  // Custom style for selection tab fields
  const btnGreenColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--btn_green_color")
    .trim();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? btnGreenColor : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${btnGreenColor}` : null,
      borderWidth: state.isFocused ? provided.borderWidth : 0,
      "&:hover": {
        borderColor: state.isFocused ? btnGreenColor : provided.borderColor,
      },
    }),
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.target.value);

    onFieldChange({
      value: selectedOption.target.value,
      isValid: true,
      isRequired,
    });
  };

  if (!isVisible) {
    return null;
  }

  // Set title-blocked class
  let title_blocked_class = "";
  if (!fieldEnabled) title_blocked_class = "title-blocked";

  // Set fieldWrapperClass
  const fieldWrapperClass =
    activateRequired && !validateRadioButtonInput(value, isRequired, isVisible)
      ? "field-wrapper required"
      : "field-wrapper";

  return (
    <>
      {fieldTitle && (
        <p className={`formfield-p ${title_blocked_class}`}>
          {fieldTitle}
          {isRequired && fieldEnabled && (
            <span className="required-asterisk">*</span>
          )}
          {!isRequired && fieldEnabled && <span> (optional)</span>}
        </p>
      )}
      <div className={""}>
        {loading ? (
          <Throbber justify="left" className={"loading-field"} />
        ) : (
          <div
            className={`${fieldWrapperClass} ${
              radioButtonsInSingleRow ? "single-row" : ""
            }`}
          >
            {inOptionsArray.map((option, index) => (
              <label key={index} className={title_blocked_class}>
                <input
                  type="radio"
                  name={fieldTitle}
                  value={option}
                  disabled={!fieldEnabled}
                  checked={value === option}
                  onChange={handleSelectChange}
                />
                {option}
              </label>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FormFieldRadioButton;
