import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../global_utils/UserProvider";
import UASinglePageButtons from "./UASinglePageButtons";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
} from "../global_utils/FormFieldValidation";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import languagesList from "../../assets/data/languages.json";
import studyLevels from "../../assets/data/studyLevels.json";

const UASingleLanguage = ({ langIdx, deleteLanguageHandler }) => {
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const [duplicateLanguageError, setDuplicateLanguageError] = useState("");
  const { user, loading, RefreshUserData, DeleteLanguage } =
    useContext(UserContext);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [language, setLanguage] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editLanguage, setEditLanguage] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [grammarLevel, setGrammarLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editGrammarLevel, setEditGrammarLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [speakingLevel, setSpeakingLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editSpeakingLevel, setEditSpeakingLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [haveCertificate, setHaveCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editHaveCertificate, setEditHaveCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [certificate, setCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCertificate, setEditCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [certificateGrade, setCertificateGrade] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editCertificateGrade, setEditCertificateGrade] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [certificateIssuanceYear, setCertificateIssuanceYear] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editCertificateIssuanceYear, setEditCertificateIssuanceYear] =
    useState({
      value: "",
      isValid: false,
      isRequired: false,
    });

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    // Update current language
    let l_languages;
    if (user.languages) {
      l_languages = JSON.parse(JSON.stringify(user.languages));
      l_languages[langIdx].language = editLanguage.value.value;
      l_languages[langIdx].grammarLevel = editGrammarLevel.value.value;
      l_languages[langIdx].speakingLevel = editSpeakingLevel.value.value;
      l_languages[langIdx].certificate =
        editHaveCertificate.value === "Yes" ? editCertificate.value : "";
      l_languages[langIdx].certificateGrade =
        editHaveCertificate.value === "Yes" ? editCertificateGrade.value : "";
      l_languages[langIdx].certificateIssuanceYear =
        editHaveCertificate.value === "Yes"
          ? editCertificateIssuanceYear.value
          : "";
    }

    setFieldsData({
      languages: l_languages,
      language: editLanguage,
      grammarLevel: editGrammarLevel,
      speakingLevel: editSpeakingLevel,
      certificate: editCertificate,
      certificateGrade: editCertificateGrade,
      certificateIssuanceYear: editCertificateIssuanceYear,
    });
  }, [
    editLanguage,
    editGrammarLevel,
    editSpeakingLevel,
    editHaveCertificate,
    editCertificate,
    editCertificateGrade,
    editCertificateIssuanceYear,
    user.languages,
    langIdx,
  ]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      language: {
        value: {
          value: uData.languages[langIdx].language || "",
          label: uData.languages[langIdx].language || "",
        },
        isValid: editLanguage.isValid,
        isRequired: editLanguage.isRequired,
      },
      grammarLevel: {
        value: {
          value: uData.languages[langIdx].grammarLevel || "",
          label: uData.languages[langIdx].grammarLevel || "",
        },
        isValid: editGrammarLevel.isValid,
        isRequired: editGrammarLevel.isRequired,
      },
      speakingLevel: {
        value: {
          value: uData.languages[langIdx].speakingLevel || "",
          label: uData.languages[langIdx].speakingLevel || "",
        },
        isValid: editSpeakingLevel.isValid,
        isRequired: editSpeakingLevel.isRequired,
      },
      haveCertificate: {
        value: uData.languages[langIdx].certificate === "" ? "No" : "Yes",
        isValid: editHaveCertificate.isValid,
        isRequired: editHaveCertificate.isRequired,
      },
      certificate: {
        value: uData.languages[langIdx].certificate || "",
        isValid: editCertificate.isValid,
        isRequired: editCertificate.isRequired,
      },
      certificateGrade: {
        value: uData.languages[langIdx].certificateGrade || "",
        isValid: editCertificateGrade.isValid,
        isRequired: editCertificateGrade.isRequired,
      },
      certificateIssuanceYear: {
        value: uData.languages[langIdx].certificateIssuanceYear || "",
        isValid: editCertificateIssuanceYear.isValid,
        isRequired: editCertificateIssuanceYear.isRequired,
      },
    };

    // Initialize Language
    setLanguage(newFormData.language);
    setEditLanguage(newFormData.language);

    // Initialize Grammar Level
    setGrammarLevel(newFormData.grammarLevel);
    setEditGrammarLevel(newFormData.grammarLevel);

    // Initialize Speaking Level
    setSpeakingLevel(newFormData.speakingLevel);
    setEditSpeakingLevel(newFormData.speakingLevel);

    // Initialize HaveCertificate
    setHaveCertificate(newFormData.haveCertificate);
    setEditHaveCertificate(newFormData.haveCertificate);

    // Initialize certificate
    setCertificate(newFormData.certificate);
    setEditCertificate(newFormData.certificate);

    // Initialize certificateGrade
    setCertificateGrade(newFormData.certificateGrade);
    setEditCertificateGrade(newFormData.certificateGrade);

    // Initialize certificateIssuanceYear
    setCertificateIssuanceYear(newFormData.certificateIssuanceYear);
    setEditCertificateIssuanceYear(newFormData.certificateIssuanceYear);
  };

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      language: {
        ...editLanguage,
        isValid: validateSelectInput(
          editLanguage.value.value,
          editLanguage.isRequired,
          true
        ),
      },
      grammarLevel: {
        ...editGrammarLevel,
        isValid: validateSelectInput(
          editGrammarLevel.value.value,
          editGrammarLevel.isRequired,
          true
        ),
      },
      speakingLevel: {
        ...editSpeakingLevel,
        isValid: validateSelectInput(
          editSpeakingLevel.value.value,
          editSpeakingLevel.isRequired,
          true
        ),
      },
      haveCertificate: {
        ...editHaveCertificate,
        isValid: validateRadioButtonInput(
          editHaveCertificate.value.value,
          editHaveCertificate.isRequired,
          true
        ),
      },
      certificate: {
        ...editCertificate,
        isValid: validateTextInput(
          editCertificate.value,
          editCertificate.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editHaveCertificate.value === "Yes"
        ),
      },
      certificateGrade: {
        ...editCertificateGrade,
        isValid: validateTextInput(
          editCertificateGrade.value,
          editCertificateGrade.isRequired,
          7, // textInputMaxLength
          textInputMinLength,
          editHaveCertificate.value === "Yes"
        ),
      },
      certificateIssuanceYear: {
        ...editCertificateIssuanceYear,
        isValid: validateTextInput(
          editCertificateIssuanceYear.value,
          editCertificateIssuanceYear.isRequired,
          4, // textInputMaxLength
          textInputMinLength,
          editHaveCertificate.value === "Yes"
        ),
      },
    };
  };

  // Function to check for duplicate language
  const isLanguageDuplicate = () => {
    return user.languages.some(
      (lang, idx) =>
        lang.language === editLanguage.value.value && idx !== langIdx
    );
  };

  // Handler to sumbit data to the backend
  const submitDataHandler = async () => {
    // Check for duplicate language first
    if (isLanguageDuplicate()) {
      setDuplicateLanguageError("This language has already been selected.");
      return; // Stop the function if there is a duplicate
    }

    // Clear any previous duplicate language errors
    setDuplicateLanguageError("");
    // Disable submit
    setEnableSubmit(false);

    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
          {
            languages: fieldsData.languages,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Ask UserProvider to refresh user data
          RefreshUserData();

          // Save the edited language
          setLanguage(editLanguage);
          // Save the edited grammarLevel
          setGrammarLevel(editGrammarLevel);
          // Save the edited speakingLevel
          setSpeakingLevel(editSpeakingLevel);
          // Save the edited haveCertificate
          setHaveCertificate(editHaveCertificate);
          // Save the edited certificate
          setCertificate(editCertificate);
          // Save the edited certificateGrade
          setCertificateGrade(editCertificateGrade);
          // Save the edited certificateIssuanceYear
          setCertificateIssuanceYear(editCertificateIssuanceYear);
        } else {
          console.error("Failed to update user");
        }

        // Enable submit
        setEnableSubmit(true);
      } catch (error) {
        console.error("Error updating user data:", error);
        // Enable submit
        setEnableSubmit(true);
      }

      setEditEnable(false);
    } else {
      // Activate Required Fields
      setActivateRequiredFields(true);

      // Enable submit
      setEnableSubmit(true);
    }
  };

  // Handle to delete data from the backend
  const deleteDataHandler = async () => {
    setIsDeleting(true); // Start of deletion process

    await DeleteLanguage(langIdx); // Perform the deletion

    setIsDeleting(false); // End of deletion process

    // Call dlete handler upstream
    deleteLanguageHandler();
  };

  // Routine to handle language
  const handleLanguageChange = (value) => {
    setEditLanguage(value);
  };

  // Routine to handle grammar level
  const handleGrammarLevelChange = (value) => {
    setEditGrammarLevel(value);
  };

  // Routine to handle speaking level
  const handleSpeakingLevelChange = (value) => {
    setEditSpeakingLevel(value);
  };

  // Routine to handle haveCertificate
  const handleHaveCertificateChange = (value) => {
    setEditHaveCertificate(value);
  };

  // Routine to handle certificate
  const handleCertificateChange = (value) => {
    setEditCertificate(value);
  };

  // Routine to handle have certificateGrade
  const handleCertificateGradeChange = (value) => {
    setEditCertificateGrade(value);
  };

  // Routine to handle have certificateIssuanceYear
  const handleCertificateIssuanceYearChange = (value) => {
    setEditCertificateIssuanceYear(value);
  };

  // Handler for NEXT button
  const editButtonHandler = () => {
    setEditEnable(true);
  };

  // Handler for CANCEL button
  const cancelHandler = () => {
    // Cancel is pressed and no language is slected, delete language
    if (language.value.value === "") {
      deleteDataHandler();
      return;
    }

    // Revert the edit fields to the saved fields
    setEditLanguage(language);
    setEditGrammarLevel(grammarLevel);
    setEditSpeakingLevel(speakingLevel);
    setEditHaveCertificate(haveCertificate);
    setEditCertificate(certificate);
    setEditCertificateGrade(certificateGrade);
    setEditCertificateIssuanceYear(certificateIssuanceYear);

    // Deactivate required fields
    setActivateRequiredFields(false);

    // Disable Edit
    setEditEnable(false);
  };

  // Always enable Edit if language is not defined
  if (
    !editEnable &&
    user &&
    langIdx < user.languages.length &&
    user.languages[langIdx].language === "" &&
    editLanguage.value.value === ""
  ) {
    setEditEnable(true);
  }

  return (
    <>
      {editEnable ? (
        <form className="signup-form">
          <div className="row">
            <FormFieldSelect
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Language"}
              inPlaceholder={"Enter the language"}
              inOptionsArray={languagesList}
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleLanguageChange}
              value={editLanguage.value}
              loading={loading}
              allowTyping
            />
          </div>
          <div className="row">
            <div className={"col-md-6"}>
              <FormFieldSelect
                fieldEnabled={editEnable && enableSubmit}
                fieldTitle={"Grammar Level"}
                inPlaceholder={"Enter your grammar level"}
                inOptionsArray={studyLevels}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleGrammarLevelChange}
                value={editGrammarLevel.value}
                loading={loading}
              />
            </div>
            <div className={"col-md-6"}>
              <FormFieldSelect
                fieldEnabled={editEnable && enableSubmit}
                fieldTitle={"Speaking Level"}
                inPlaceholder={"Enter your speaking level"}
                inOptionsArray={studyLevels}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleSpeakingLevelChange}
                value={editSpeakingLevel.value}
                loading={loading}
              />
            </div>
          </div>
          <div className="row">
            <FormFieldRadioButton
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle="Do you hold a certificate?"
              inOptionsArray={["Yes", "No"]}
              isRequired={true}
              radioButtonsInSingleRow={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleHaveCertificateChange}
              value={editHaveCertificate.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Language certificate"}
              inPlaceholder={
                "Enter your language certificate (e.g. TOEFL, IELTS)"
              }
              isRequired={true}
              isVisible={editHaveCertificate.value === "Yes"}
              activateRequired={activateRequiredFields}
              onFieldChange={handleCertificateChange}
              value={editCertificate.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Grade obtained"}
              inPlaceholder={"Enter the grade obtained (e.g. 7.50)"}
              isRequired={false}
              isVisible={editHaveCertificate.value === "Yes"}
              activateRequired={activateRequiredFields}
              onFieldChange={handleCertificateGradeChange}
              value={editCertificateGrade.value}
              allowOnlyFloats
              loading={loading}
              textMaxLength={7}
            />
          </div>
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Year of certificate issuance"}
              inPlaceholder={"Enter the year of the certificate issuance"}
              isRequired={false}
              isVisible={editHaveCertificate.value === "Yes"}
              activateRequired={activateRequiredFields}
              onFieldChange={handleCertificateIssuanceYearChange}
              value={editCertificateIssuanceYear.value}
              allowOnlyIntegers
              loading={loading}
              textMaxLength={4}
            />
          </div>
        </form>
      ) : (
        <>
          <p className="subform-title-p">
            {editLanguage ? editLanguage.value.label : ""}
          </p>
          <p className="subform-text-p">
            <strong>Grammar Level:</strong>{" "}
            {editGrammarLevel ? editGrammarLevel.value.label : ""}
          </p>
          <p className="subform-text-p">
            <strong>Speaking Level:</strong>{" "}
            {editSpeakingLevel ? editSpeakingLevel.value.label : ""}
          </p>
          {editHaveCertificate && editHaveCertificate.value === "Yes" && (
            <>
              <p className="subform-text-p">
                <strong>Certificate:</strong>{" "}
                {editCertificate ? editCertificate.value : ""}
              </p>
              {editCertificateGrade && editCertificateGrade.value !== "" && (
                <p className="subform-text-p">
                  <strong>Grade:</strong>{" "}
                  {editCertificateGrade ? editCertificateGrade.value : ""}
                </p>
              )}
              {editCertificateIssuanceYear &&
                editCertificateIssuanceYear.value !== "" && (
                  <p className="subform-text-p">
                    <strong>Year of Issuance:</strong>{" "}
                    {editCertificateIssuanceYear
                      ? editCertificateIssuanceYear.value
                      : ""}
                  </p>
                )}
            </>
          )}
        </>
      )}
      {duplicateLanguageError && (
        <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
          {duplicateLanguageError}
        </div>
      )}
      <UASinglePageButtons
        isEditEnabled={editEnable}
        isDeleting={isDeleting}
        onEditClick={editButtonHandler}
        onSubmitClick={submitDataHandler}
        onCancelClick={cancelHandler}
        onDeleteClick={deleteDataHandler}
        submitButtonDisabled={!enableSubmit}
        disableDeleteButton={
          language.value.value ? language.value.value === "English" : false
        }
      />
    </>
  );
};

export default UASingleLanguage;
