import React from 'react';
import { useState } from 'react'
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';


const MoveToIrelandCard = ({image, title, date, type, post_link}) => {
    const [hovered, setHovered] = useState(false);
    
    const text_block_classname = (type === "WORK") ? "mov-to-irl-img-text-block-blue" : "mov-to-irl-img-text-block-green"

    const toggleHover = () => {
        setHovered(!hovered);
    }

    return(
        <>
            <Col sm={12} md={6} lg={4}>
                <Link className="link-style" to={post_link}>
                    <div className="mov-to-irl-card hover-zoom"
                        onMouseEnter={toggleHover}
                        onMouseLeave={toggleHover}>
                        <div className="mov-to-irl-img-container">
                            <img src={image} 
                                className="mov-to-irl-img"
                                alt="Most Demanded Engineers Ireland"/>
                            <div className={"mov-to-irl-img-text-block " + (hovered ? text_block_classname : "mov-to-irl-img-text-block-white")}>
                                <h6 className="mov-to-irl-card-img-text">{type}</h6>
                            </div>
                        </div>
                        <div className="mov-to-irl-card-text-div">
                            <p className="mov-to-irl-card-title">{title}</p>
                        </div>
                        <div className="mov-to-irl-card-text-div">
                            <p className="mov-to-irl-card-sec-text">{date}</p>
                        </div>
                    </div>
                </Link>
            </Col>
        </>
    )
}

export default MoveToIrelandCard