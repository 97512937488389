import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LogoEII from "../images/NavBar/Logo 1_crop.webp";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import EIIButton from "./EIIButton";
import UserCircleMenu from "./UserCircleMenu";
import UserDropdownMenu from "./UserDropdownMenu";
import { UserContext } from "../global_utils/UserProvider";
import useOutsideClick from "./useOutsideClick";
import axios from "axios";
import Cookies from "js-cookie";
import "./EIINavbar.css";

const EIINavbar = () => {
  const { user, Logout } = useContext(UserContext);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(user !== undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for the dropdown menu
  const [jwtEII, setJwtEII] = useState(Cookies.get("jwt_eii"));
  // Add state for mobile view check
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const menuRef = useRef();

  useEffect(() => {
    // Function to update the isMobile state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize to set the initial state
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Use the custom hook
  useOutsideClick(menuRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  useEffect(() => {
    // Function to check the jwtEII cookie and update state if it changes
    const checkJwtEII = () => {
      const currentJwtEII = Cookies.get("jwt_eii");
      if (currentJwtEII !== jwtEII) {
        setJwtEII(currentJwtEII);
      }
    };

    // Call the function immediately when the component mounts
    checkJwtEII();

    // Set an interval to check the jwtEII cookie every 1 second
    const intervalId = setInterval(checkJwtEII, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [jwtEII]);

  useEffect(() => {
    if (user && jwtEII) {
      checkAuthentication();
    } else {
      setIsAuthenticated(false);
    }
  }, [jwtEII, user]);

  const checkAuthentication = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/check-auth`,
        {
          headers: {
            Authorization: `Bearer ${jwtEII}`,
          },
        }
      );
      if (response.status === 200) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Error on verifying authentication: ", error);
    }
  };

  const handleNavLinkClick = () => {
    setNavbarExpanded(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogOutClick = async () => {
    Logout();
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      expanded={navbarExpanded}
      className="mb-4 mb-lg-0"
    >
      <Container fluid className="navbar-container">
        <Navbar.Brand as={Link} to="/#Home">
          <img
            src={LogoEII}
            className="navbar-logo"
            alt="Most Demanded Engineers Ireland"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setNavbarExpanded(!navbarExpanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" onClick={handleNavLinkClick}>
            {isMobile &&
              (isAuthenticated ? (
                <div className="d-flex justify-content-start navbar-mobile-login-div align-items-center mb-3 mb-lg-0">
                  <UserCircleMenu
                    isOpen={isMenuOpen}
                    toggleMenu={toggleMenu}
                    onLogoutClick={handleLogOutClick}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-start navbar-mobile-login-div align-items-center mb-3 mb-lg-0">
                  <HashLink smooth to="/LogIn">
                    <EIIButton
                      color="black"
                      text="Log In"
                      btnBig={false}
                      transparent={true}
                      customClass="rounded"
                    />
                  </HashLink>
                  <div className="mx-2"></div>
                  <HashLink smooth to="/Signup">
                    <EIIButton
                      color="black"
                      text="Sign Up"
                      btnBig={false}
                      transparent={false}
                      customClass="rounded"
                    />
                  </HashLink>
                </div>
              ))}
            {/* Add user menu in mobile version and if the user is logged in */}
            {isMobile && isAuthenticated && (
              <>
                <Nav.Link as={Link} to="/UserArea">
                  User Area
                </Nav.Link>
                <Nav.Link as={Link} to="#" onClick={handleLogOutClick}>
                  Log Out
                </Nav.Link>
                <hr /> {/* Horizontal line */}
              </>
            )}
            <Nav.Link as={HashLink} smooth to="/#Home">
              Home
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#DemEng">
              Irish Salaries
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#JoinCommunity">
              Join our Community
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#AboutIreland">
              About Ireland
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#AboutUs">
              About us
            </Nav.Link>
          </Nav>
          {!isMobile &&
            (isAuthenticated ? (
              <div className="d-flex justify-content-end align-items-center mb-3 mb-lg-0">
                <UserCircleMenu
                  isOpen={isMenuOpen}
                  toggleMenu={toggleMenu}
                  onLogoutClick={handleLogOutClick}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-end align-items-center mb-3 mb-lg-0">
                <HashLink smooth to="/LogIn">
                  <EIIButton
                    color="black"
                    text="Log In"
                    btnBig={false}
                    transparent={true}
                    customClass="rounded"
                  />
                </HashLink>
                <div className="mx-2"></div>
                <HashLink smooth to="/Signup">
                  <EIIButton
                    color="black"
                    text="Sign Up"
                    btnBig={false}
                    transparent={false}
                    customClass="rounded"
                  />
                </HashLink>
              </div>
            ))}
          {!isMobile && isAuthenticated && isMenuOpen && (
            <div className="d-flex justify-content-end align-items-center mb-3 mb-lg-0">
              <UserDropdownMenu ref={menuRef} toggleMenu={toggleMenu} />
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default EIINavbar;
