import React from "react";
import EIIButton from "../global_utils/EIIButton";
import { FaRegTrashAlt } from "react-icons/fa";

const UASinglePageButtons = ({
  isEditEnabled,
  isDeleting,
  onEditClick,
  onSubmitClick,
  onCancelClick,
  onDeleteClick,
  submitButtonDisabled,
  disableDeleteButton = false,
}) => {
  return (
    <div className="row subform-buttons-row">
      <div className="col-md-6 subform-btn-col-left">
        {isEditEnabled && !disableDeleteButton && (
          <EIIButton
            text={""}
            icon={<FaRegTrashAlt />}
            color={"gray"}
            transparent
            btnBig={false}
            onClickHandler={onDeleteClick}
            disabled={isDeleting}
          />
        )}
      </div>
      <div className="col-md-6 subform-btn-col-right">
        {!isEditEnabled && (
          <EIIButton
            text={"Edit"}
            color={"green"}
            btnBig={false}
            transparent
            onClickHandler={onEditClick}
          />
        )}
        {isEditEnabled && (
          <div className="row">
            <div className="col-md-6">
              <EIIButton
                text={"Cancel"}
                color={"green"}
                transparent
                btnBig={false}
                onClickHandler={onCancelClick}
              />
            </div>
            <div className="col-md-6">
              <EIIButton
                text={"Submit"}
                color={"green"}
                btnBig={false}
                disabled={submitButtonDisabled}
                onClickHandler={onSubmitClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UASinglePageButtons;
