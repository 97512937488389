import React, { useEffect, useState } from "react";
import EIIButton from "../global_utils/EIIButton";
import SeparationLine from "../global_utils/SeparationLine";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import FormFieldCheckbox from "../global_utils/FormFieldCheckbox";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
  validateCheckboxInput,
} from "../global_utils/FormFieldValidation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignUpForm1.css";
import studyLevels from "../../assets/data/studyLevels.json";

const SignUpForm1PageEnglishLevel = ({
  onClickNextHandler,
  onClickBackHandler,
  formData,
  submitting,
}) => {
  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState("");

  // State for buttonBig
  const [buttonBig, setButtonBig] = useState(window.innerWidth > 768);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [englishGrammarLevel, setEnglishGrammarLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEnglishGrammarLevel, setEditEnglishGrammarLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [englishSpeakingLevel, setEnglishSpeakingLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEnglishSpeakingLevel, setEditEnglishSpeakingLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [haveEnglishCertificate, setHaveEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editHaveEnglishCertificate, setEditHaveEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [englishCertificate, setEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEnglishCertificate, setEditEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [gradeCertificate, setGradeCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editGradeCertificate, setEditGradeCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [yearEnglishCertificate, setYearEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editYearEnglishCertificate, setEditYearEnglishCertificate] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editTermsAndConditionsAccepted, setEditTermsAndConditionsAccepted] =
    useState({
      value: "",
      isValid: false,
      isRequired: false,
    });

  // User data is updated
  useEffect(() => {
    if (formData) {
      transformAndSetUserData(formData);
    }
  }, [formData]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      englishGrammarLevel: {
        value: {
          value: uData.englishGrammarLevel
            ? uData.englishGrammarLevel.value.value
            : "",
          label: uData.englishGrammarLevel
            ? uData.englishGrammarLevel.value.value
            : "",
        },
        isValid: editEnglishGrammarLevel.isValid,
        isRequired: editEnglishGrammarLevel.isRequired,
      },
      englishSpeakingLevel: {
        value: {
          value: uData.englishSpeakingLevel
            ? uData.englishSpeakingLevel.value.value
            : "",
          label: uData.englishSpeakingLevel
            ? uData.englishSpeakingLevel.value.value
            : "",
        },
        isValid: editEnglishSpeakingLevel.isValid,
        isRequired: editEnglishSpeakingLevel.isRequired,
      },
      haveEnglishCertificate: {
        value: uData.haveEnglishCertificate
          ? uData.haveEnglishCertificate.value
          : "",
        isValid: editHaveEnglishCertificate.isValid,
        isRequired: editHaveEnglishCertificate.isRequired,
      },
      englishCertificate: {
        value: uData.englishCertificate ? uData.englishCertificate.value : "",
        isValid: editEnglishCertificate.isValid,
        isRequired: editEnglishCertificate.isRequired,
      },
      gradeCertificate: {
        value: uData.gradeCertificate ? uData.gradeCertificate.value : "",
        isValid: editGradeCertificate.isValid,
        isRequired: editGradeCertificate.isRequired,
      },
      yearEnglishCertificate: {
        value: uData.yearEnglishCertificate
          ? uData.yearEnglishCertificate.value
          : "",
        isValid: editYearEnglishCertificate.isValid,
        isRequired: editYearEnglishCertificate.isRequired,
      },
      termsAndConditionsAccepted: {
        value: uData.termsAndConditionsAccepted
          ? uData.termsAndConditionsAccepted.value
          : "",
        isValid: editTermsAndConditionsAccepted.isValid,
        isRequired: editTermsAndConditionsAccepted.isRequired,
      },
    };

    // Initialize englishGrammarLevel
    setEnglishGrammarLevel(newFormData.englishGrammarLevel);
    setEditEnglishGrammarLevel(newFormData.englishGrammarLevel);
    // Initialize englishSpeakingLevel
    setEnglishSpeakingLevel(newFormData.englishSpeakingLevel);
    setEditEnglishSpeakingLevel(newFormData.englishSpeakingLevel);
    // Initialize haveEnglishCertificate
    setHaveEnglishCertificate(newFormData.haveEnglishCertificate);
    setEditHaveEnglishCertificate(newFormData.haveEnglishCertificate);
    // Initialize englishCertificate
    setEnglishCertificate(newFormData.englishCertificate);
    setEditEnglishCertificate(newFormData.englishCertificate);
    // Initialize gradeCertificate
    setGradeCertificate(newFormData.gradeCertificate);
    setEditGradeCertificate(newFormData.gradeCertificate);
    // Initialize yearEnglishCertificate
    setYearEnglishCertificate(newFormData.yearEnglishCertificate);
    setEditYearEnglishCertificate(newFormData.yearEnglishCertificate);
    // Initialize termsAndConditionsAccepted
    setTermsAndConditionsAccepted(newFormData.termsAndConditionsAccepted);
    setEditTermsAndConditionsAccepted(newFormData.termsAndConditionsAccepted);
  };

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      englishGrammarLevel: editEnglishGrammarLevel,
      englishSpeakingLevel: editEnglishSpeakingLevel,
      haveEnglishCertificate: editHaveEnglishCertificate,
      englishCertificate: editEnglishCertificate,
      gradeCertificate: editGradeCertificate,
      yearEnglishCertificate: editYearEnglishCertificate,
      termsAndConditionsAccepted: editTermsAndConditionsAccepted,
    });
  }, [
    editEnglishGrammarLevel,
    editEnglishSpeakingLevel,
    editHaveEnglishCertificate,
    editEnglishCertificate,
    editGradeCertificate,
    editYearEnglishCertificate,
    editTermsAndConditionsAccepted,
  ]);

  // useEffect hook to resize the screen
  useEffect(() => {
    const handleResize = () => {
      setButtonBig(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      englishGrammarLevel: {
        ...editEnglishGrammarLevel,
        isValid: validateSelectInput(
          editEnglishGrammarLevel.value.value,
          editEnglishGrammarLevel.isRequired,
          true // isVisible
        ),
      },
      englishSpeakingLevel: {
        ...editEnglishSpeakingLevel,
        isValid: validateSelectInput(
          editEnglishSpeakingLevel.value.value,
          editEnglishSpeakingLevel.isRequired,
          true // isVisible
        ),
      },
      haveEnglishCertificate: {
        ...editHaveEnglishCertificate,
        isValid: validateRadioButtonInput(
          editHaveEnglishCertificate.value,
          editHaveEnglishCertificate.isRequired,
          true // isVisible
        ),
      },
      englishCertificate: {
        ...editEnglishCertificate,
        isValid: validateTextInput(
          editEnglishCertificate.value,
          editEnglishCertificate.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editHaveEnglishCertificate.value === "Yes" // isVisible
        ),
      },
      gradeCertificate: {
        ...editGradeCertificate,
        isValid: validateTextInput(
          editGradeCertificate.value,
          editGradeCertificate.isRequired,
          7, // textInputMaxLength
          textInputMinLength,
          editHaveEnglishCertificate.value === "Yes" // isVisible
        ),
      },
      yearEnglishCertificate: {
        ...editYearEnglishCertificate,
        isValid: validateTextInput(
          editYearEnglishCertificate.value,
          editYearEnglishCertificate.isRequired,
          4, // textInputMaxLength
          textInputMinLength,
          editHaveEnglishCertificate.value === "Yes" // isVisible
        ),
      },
      termsAndConditionsAccepted: {
        ...editTermsAndConditionsAccepted,
        isValid: validateCheckboxInput(
          editTermsAndConditionsAccepted.value,
          editTermsAndConditionsAccepted.isRequired,
          true // isVisible
        ),
      },
    };
  };

  const nextButtonHandler = async () => {
    const copyFieldsData = JSON.parse(JSON.stringify(fieldsData));

    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    if (allRequiredFieldsValid && editTermsAndConditionsAccepted.value) {
      // Save the edited values
      setEnglishGrammarLevel(editEnglishGrammarLevel);
      setEnglishSpeakingLevel(editEnglishSpeakingLevel);
      setHaveEnglishCertificate(editHaveEnglishCertificate);
      setEnglishCertificate(editEnglishCertificate);
      setGradeCertificate(editGradeCertificate);
      setYearEnglishCertificate(editYearEnglishCertificate);
      setTermsAndConditionsAccepted(editTermsAndConditionsAccepted);

      // Only proceed if all required fields are valid
      await onClickNextHandler(copyFieldsData);
    } else if (!allRequiredFieldsValid) {
      // Some fields are not valid
      setActivateRequiredFields(true);
    } else if (!editTermsAndConditionsAccepted.value) {
      // Terms and conditions not accepted
      setErrorMessage("You must accept the Terms and Conditions to Sign Up.");
    }
  };

  // BACK button handler
  const backButtonHandler = () => {
    // Revert the edit fields to the saved fields
    setEditEnglishGrammarLevel(englishGrammarLevel);
    setEditEnglishSpeakingLevel(englishSpeakingLevel);
    setEditHaveEnglishCertificate(haveEnglishCertificate);
    setEditEnglishCertificate(englishCertificate);
    setEditGradeCertificate(gradeCertificate);
    setEditYearEnglishCertificate(yearEnglishCertificate);
    setEditTermsAndConditionsAccepted(termsAndConditionsAccepted);

    onClickBackHandler(fieldsData);
  };

  // Routine to handle englishGrammarLevel
  const handleEnglishGrammarLevelChange = (value) => {
    setEditEnglishGrammarLevel(value);
  };

  // Routine to handle englishSpeakingLevel
  const handleEnglishSpeakingLevelChange = (value) => {
    setEditEnglishSpeakingLevel(value);
  };

  // Routine to handle haveEnglishCertificate
  const handleHaveEnglishCertificateChange = (value) => {
    setEditHaveEnglishCertificate(value);
  };

  // Routine to handle englishCertificate
  const handleEnglishCertificateChange = (value) => {
    setEditEnglishCertificate(value);
  };

  // Routine to handle gradeCertificate
  const handleGradeCertificateChange = (value) => {
    setEditGradeCertificate(value);
  };

  // Routine to handle yearEnglishCertificate
  const handleYearEnglishCertificateChange = (value) => {
    setEditYearEnglishCertificate(value);
  };

  // Routine to handle termsAndConditionsAccepted
  const handleTermsAndConditionsAcceptedChange = (value) => {
    setEditTermsAndConditionsAccepted(value);

    // If terms and conditions are accepted --> Clear error message
    if (value.value) {
      setErrorMessage("");
    }
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">English level</h2>
            <form className="signup-form">
              <FormFieldSelect
                fieldTitle={"Grammar Level"}
                inPlaceholder={"Enter your grammar level"}
                inOptionsArray={studyLevels}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEnglishGrammarLevelChange}
                value={editEnglishGrammarLevel.value}
                fieldEnabled={!submitting}
              />
              <FormFieldSelect
                fieldTitle={"Speaking Level"}
                inPlaceholder={"Enter your speaking level"}
                inOptionsArray={studyLevels}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEnglishSpeakingLevelChange}
                value={editEnglishSpeakingLevel.value}
                fieldEnabled={!submitting}
              />
              <FormFieldRadioButton
                fieldTitle="Do you hold an English certificate?"
                inOptionsArray={["Yes", "No"]}
                isRequired={true}
                radioButtonsInSingleRow={false}
                activateRequired={activateRequiredFields}
                onFieldChange={handleHaveEnglishCertificateChange}
                value={editHaveEnglishCertificate.value}
                fieldEnabled={!submitting}
              />
              <FormFieldText
                fieldTitle={"English certificate"}
                inPlaceholder={
                  "Enter your English certificate (e.g. TOEFL, IELTS)"
                }
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEnglishCertificateChange}
                value={editEnglishCertificate.value}
                isVisible={editHaveEnglishCertificate.value === "Yes"}
                fieldEnabled={!submitting}
              />
              <FormFieldText
                fieldTitle={"Grade obtained"}
                inPlaceholder={"Enter the grade obtained (e.g. 7.50)"}
                isRequired={false}
                isVisible={editHaveEnglishCertificate.value === "Yes"}
                activateRequired={activateRequiredFields}
                onFieldChange={handleGradeCertificateChange}
                value={editGradeCertificate.value}
                allowOnlyFloats
                textMaxLength={7}
                fieldEnabled={!submitting}
              />
              <FormFieldText
                fieldTitle={"Year of certificate issuance"}
                inPlaceholder={"Enter the year of the certificate issuance"}
                isRequired={false}
                isVisible={editHaveEnglishCertificate.value === "Yes"}
                activateRequired={activateRequiredFields}
                onFieldChange={handleYearEnglishCertificateChange}
                value={editYearEnglishCertificate.value}
                allowOnlyIntegers
                textMaxLength={4}
                fieldEnabled={!submitting}
              />
              <SeparationLine />
              <FormFieldCheckbox
                checkboxLabel={
                  <p style={{ marginBottom: 0 }}>
                    I accept the&nbsp;
                    <a
                      className="colored-a"
                      href="#/TermsAndConditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </p>
                }
                isRequired={true}
                isVisible={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleTermsAndConditionsAcceptedChange}
                value={editTermsAndConditionsAccepted.value}
                fieldEnabled={!submitting}
              />
            </form>
            {errorMessage && (
              <div className="row">
                <div
                  className="col text-center"
                  style={{ color: "red", marginTop: "20px" }}
                >
                  {errorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row right-bottom-row">
          <div className="col-md-6 right-bottom-col-left">
            {!submitting && (
              <EIIButton
                text="Back"
                color="green"
                btnBig={buttonBig}
                transparent
                onClickHandler={backButtonHandler}
              />
            )}
          </div>
          <div className="col-md-6 right-bottom-col-right">
            <EIIButton
              text="Submit"
              color="blue"
              btnBig={buttonBig}
              onClickHandler={nextButtonHandler}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm1PageEnglishLevel;
