import React, { forwardRef } from "react";
import "./SplitScreen.css";

const SplitScreen = forwardRef(
  (
    {
      leftColor,
      rightColor,
      leftContent,
      rightContent,
      leftContentCentered = true,
      leftWidth = "40%",
    },
    ref
  ) => {
    const leftStyle = {
      backgroundColor: leftColor,
      width: leftWidth,
    };

    const rightStyle = {
      backgroundColor: rightColor,
      width: `calc(100% - ${leftWidth})`,
    };

    const leftContentClass = leftContentCentered ? "centered-content" : "";

    return (
      <div className="split-screen-container">
        <div className="split-screen-left" style={leftStyle}>
          <div className={leftContentClass}>{leftContent}</div>
        </div>
        <div className="split-screen-right" style={rightStyle} ref={ref}>
          {rightContent}
        </div>
      </div>
    );
  }
);

export default SplitScreen;
