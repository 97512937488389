import React from "react";
import PostParagraph from "./PostParagraph";
import PostTip from "./PostTip";
import PostTpl from "./PostTpl";

const TestPost = () => {
  return (
    <>
      <PostTpl
        title="Find accommodation in Dublin"
        date="07 MAR 2023"
        type="LIFE"
        content={
          <>
            <PostParagraph text="Dublin has become one of the most attractive European capitals as it is a hub of culture, history and commerce. Tourists, students, and professionals chose this city as their destination, making finding accommodation a very challenging task. Dublin offers a range of housing options to suit every budget and lifestyle. However, the high demand for housing in the city means that finding a suitable place to live can be a daunting experience, particularly for those who are new to the city. In this post, we will provide you with some useful tips and resources to help you find the perfect accommodation in Dublin." />

            <h3 className="post-subtitle">
              How do I get an accommodation in Dublin from abroad?
            </h3>
            <PostParagraph text="If you are coming from abroad it is going to be challenging finding your dream accommodation before arriving to Ireland. Apartments that are displayed at a reasonable price and in a convenient location usually disappear in a matter of hours. There are long queues of people willing to visit them and pay the deposit in cash which makes it challenging for people that want to find an apartment without physically being in Dublin." />
            <PostTip
              tip_number="1"
              title="Start by a temporary accommodation if you are abroad."
              text="Don't try to find your dream accommodation before coming to Ireland as you might find it frustrating. It is much more recommendable to find a temporary for a few weeks even if it is more expensive. Then you can start looking for accommodation once you are in the country."
            />
            <PostTip
              tip_number="2"
              title="Make sure that you can get your proof of address."
              text="It is better to look for a temporary room or apartment at the beginning rather than a hotel. The reason for that is that can ask in advance if the landlord can facilitate you a letter that will suffice as a proof of address. This proof of address is essential for your initial steps in the city such as getting the PPS number (Irish ID) or opening a bank account. The address can be changed once you move to a new accommodation."
            />

            <h3 className="post-subtitle">
              What are the best websites to find accommodation in Dublin?
            </h3>
            <PostParagraph
              text={
                <ol>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.daft.ie/"
                    >
                      Daft.ie
                    </a>{" "}
                    – This is the most popular website for finding accommodation
                    in Dublin. It has a wide range of properties and will let
                    you filter by price, location and other criteria. It also
                    lets you select apartments for sharing, which would make you
                    safe some extra money.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.myhome.ie/"
                    >
                      Myhome.ie
                    </a>{" "}
                    – This is another popular website that lists a wide range of
                    properties to rent in Dublin. It has a nice user interface
                    and a good set of filters to adjust price, location, etc.
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.rent.ie/"
                    >
                      Rent.ie
                    </a>{" "}
                    – This website will let you select apartments for yourself,
                    for share or event for students. It also offers you other
                    options to search such as parking spaces or homeware.
                  </li>
                </ol>
              }
            />
            <PostTip
              tip_number="3"
              title="Stay alert for new accommodations!"
              text="Accommodation websites and apps let you create alerts based on your filters. Select your preferred Dublin districts, a range of prices and any other options of your preference and set alerts to start receiving notifications for new available accommodations. Be very mindful of these alerts and try to arrange a visit fast when an apartment meets your expectations. Being fast to apply gives you good chances of getting the chance to visit the places that you like!"
            />

            <h3 className="post-subtitle">
              Requirements and documents for renting in Dublin
            </h3>
            <PostParagraph text="For renting in Dublin you are going to be required the following documents:" />
            <PostParagraph
              text={
                <ol>
                  <li>
                    <strong className="post-bold-text">Passport</strong> – This
                    will also include a copy of the Irish visa for non-EU
                    citizens.
                  </li>
                  <li>
                    <strong className="post-bold-text">PPS number</strong> –
                    This is the Irish ID and you won’t be able to present this
                    if it is your first accommodation in the country. Indicate
                    to the landlord that you will initiate the application for
                    this ID once you get the accommodation as you will require a
                    proof of address.
                  </li>
                  <li>
                    <strong className="post-bold-text">
                      Employer reference
                    </strong>{" "}
                    – landlords usually require this to check if you have a
                    permanent or temporary contract. If you come to Ireland
                    without a job you can ask for a reference as soon as you
                    find one.
                  </li>
                  <li>
                    <strong className="post-bold-text">
                      Reference of previous landlord
                    </strong>{" "}
                    – this documentation is also very usual to be demanded by
                    landlords. Therefore, if you are coming from abroad try to
                    get a reference letter indicating where you lived and a
                    short text that shows that you kept the place in good
                    conditions. You will also require a contact and phone/email
                    to be contacted.
                  </li>
                  <li>
                    <strong className="post-bold-text">
                      Proof of finances
                    </strong>{" "}
                    – bank statement that shows your financial situation.
                  </li>
                </ol>
              }
            />

            <h3 className="post-subtitle">
              More tips for finding an accommodation in Dublin
            </h3>
            <PostTip
              tip_number="4"
              title="Get started and make connections to improve!"
              text="Finding an accommodation in a city like Dublin can be frustrating if you are new to the city but don’t despair! Due to the lack of accommodations and the high demand people try to transfer good places to other friends once they go. These places then are never advertised on the accommodation websites. Therefore, try to find a temporary accommodation at the beginning (even if it is not your ideal one) and you will get to know better places once you start making connections in the city (e.g. people you know at work, practicing sports, etc.)."
            />
            <PostTip
              tip_number="5"
              title="Start sharing and try to find your own apartment once you are in the city."
              text="It is much easier to start finding a room in a shared house/apartment rather than a whole place for yourself. Sharing can be tough, but it is recommendable at the beginning as it can be more accessible and will also help you to minimize your expenses if you come without a job. Once you are stablished you can always improve and try to find you own accommodation."
            />
            <PostTip
              tip_number="6"
              title="Join communities where they advertise accommodations!"
              text="Try to join communities such as Facebook or Whatsapp groups with people that life already in Dublin. For example, some groups from your home country tend to be called “[nationality] in Dublin” (e.g. Españoles en Dubín – Spaniards in Dublin). You can send messages asking for accommodation and someone might have a spare room waiting for you!"
            />
            <PostTip
              tip_number="7"
              title="Don’t get alarmed by the prices!"
              text={
                <>
                  Dublin is one of the cities with the highest{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://www.numbeo.com/cost-of-living/region_prices_by_city?itemId=27&region=150"
                  >
                    rental prices in Europe
                  </a>
                  . However, so are the salaries! It is always difficult to get
                  started as you will have high expenses. Try to minimize them
                  and you will be able to get a decent salary once you find a
                  job. This will help you find your dream accommodation."
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default TestPost;
