import React, { useState, useEffect, useContext } from "react";
import EIIButton from "../global_utils/EIIButton";
import { UserContext } from "../global_utils/UserProvider";
import GoogleLoginButton from "../global_utils/GoogleLoginButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const LogInPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { Login, ForceRefreshUserData } = useContext(UserContext);
  const navigate = useNavigate();

  // Add a variable to track whether the component is mounted
  let isMounted = true;

  useEffect(() => {
    const handleMouseUp = () => {
      setPasswordVisible(false);
    };

    const handlePasswordInputKeyDown = (e) => {
      if (e.key === "Enter") {
        // Trigger the click event of the login button
        document.getElementById("login-button").click();
      }
    };

    // Event listeners
    document.addEventListener("mouseup", handleMouseUp);
    // Add event listener to the password input element
    document
      .getElementById("password-input")
      .addEventListener("keydown", handlePasswordInputKeyDown);

    return () => {
      isMounted = false;
      document.removeEventListener("mouseup", handleMouseUp);

      // Remove the event listener when the component unmounts if the element exists
      const passwordInput = document.getElementById("password-input");
      if (passwordInput) {
        passwordInput.removeEventListener(
          "keydown",
          handlePasswordInputKeyDown
        );
      }
    };
  }, []);

  const handleLogIn = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/login`,
        { email, password }
      );

      const { token } = response.data;
      const { user } = response.data.data;

      if (!user.verified) {
        if (isMounted) {
          setErrorMessage(
            "Your account is not verified. Please verify your email first."
          );
        }
      } else {
        if (isMounted) {
          // Store the token in a cookie using the set method from Cookies
          Cookies.set("jwt_eii", token, { expires: 90 }); // Set the expiration (e.g., 90 days)

          // Refresh user context
          ForceRefreshUserData(user);

          // Set user as logged in
          Login();

          // Navigates to the root page
          navigate("/UserArea");
        }
      }
    } catch (error) {
      if (isMounted) {
        if (error.response) {
          switch (error.response.status) {
            case 400: // Bad Request
              setErrorMessage(
                error.response.data.message || "Invalid request."
              );
              break;
            case 401: // Unauthorized
              setErrorMessage(
                error.response.data.message ||
                  "Incorrect email or password. Please try again."
              );
              break;
            case 404: // Not Found
              setErrorMessage(error.response.data.message || "User not found.");
              break;
            case 500: // Server Error
              setErrorMessage(
                error.response.data.message || "Internal server error."
              );
              break;
            default:
              setErrorMessage("An error occurred. Please try again later.");
              break;
          }
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      }
    }
  };

  const handlePasswordToggleMouseDown = () => {
    setPasswordVisible(true);
  };

  const handlePasswordToggleMouseUp = () => {
    setPasswordVisible(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const successResponseGoogle = (response) => {
    if (response.tokenId) {
      // Send the Google token for backend verification
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/googleLogin`, {
          token: response.tokenId,
        })
        .then((res) => {
          const { token } = res.data;
          const { user } = res.data.data;

          // Store the token in a cookie
          Cookies.set("jwt_eii", token, { expires: 90 });

          // Update context
          ForceRefreshUserData(user);

          // Set user as logged in
          Login();

          // Navigate to desired route
          navigate("/UserArea");
        })
        .catch((error) => {
          console.error("Google Sign-In error:", error);

          if (error.response) {
            switch (error.response.status) {
              case 400: // Bad Request
                setErrorMessage(
                  error.response.data.message || "Invalid request."
                );
                break;
              case 401: // Unauthorized
                setErrorMessage(
                  error.response.data.message ||
                    "Google Account is not registered, please sign up."
                );
                break;
              case 404: // Not Found
                setErrorMessage(
                  error.response.data.message || "User not found."
                );
                break;
              case 500: // Server Error
                setErrorMessage(
                  error.response.data.message || "Internal server error."
                );
                break;
              default:
                setErrorMessage("An error occurred. Please try again later.");
                break;
            }
          } else {
            setErrorMessage("An error occurred. Please try again later.");
          }
        });
    } else {
      setErrorMessage("Google Sign-In was unsuccessful. Please try again.");
    }
  };

  const failureResponseGoogle = (error) => {
    console.error("Google Sign-In error:", error);
    setErrorMessage("Google Sign-In was unsuccessful. Please try again.");
  };

  return (
    <div className="colored-section login-page">
      <div className="login-container">
        <h2 className="login-heading">Access Ireland's career gateway!</h2>
        <form className="login-form">
          <input
            type="text"
            placeholder="Email"
            className="login-input"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              className="login-input"
              onChange={handlePasswordChange}
              autoComplete="current-password"
              id="password-input"
            />
            <span
              className="password-toggle-icon"
              onMouseDown={handlePasswordToggleMouseDown}
              onMouseUp={handlePasswordToggleMouseUp}
              onTouchStart={handlePasswordToggleMouseDown} // Added touch event for mobile
              onTouchEnd={handlePasswordToggleMouseUp} // Added touch event for mobile
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <EIIButton
            text="Log In"
            color="green"
            btnBig
            fullWidth
            onClickHandler={handleLogIn}
            id="login-button"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
        <div className="or-separator">
          <div className="or-line"></div>
          <span className="or-text">or</span>
          <div className="or-line"></div>
        </div>
        <GoogleLoginButton
          buttonText="Continue with Google"
          onSuccess={successResponseGoogle}
          onFailure={failureResponseGoogle}
          color="green"
        />

        <div className="login-options">
          <a href="/#/ForgotPassword" className="login-link">
            Forgot Password?
          </a>
          <span className="login-separator">|</span>
          <a href="#/SignUp" className="login-link">
            Create Account
          </a>
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
