import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../global_utils/UserProvider";
import EIIButton from "../global_utils/EIIButton";
import UASingleLanguage from "./UASingleLanguage";
import SeparationLine from "../global_utils/SeparationLine";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";

const UALanguages = () => {
  const [addingLanguage, setAddingLanguage] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { user, AddLanguage } = useContext(UserContext);

  // Clear deleted message
  useEffect(() => {
    if (feedbackMessage) {
      const timer = setTimeout(() => {
        setFeedbackMessage("");
      }, 3000); // 3 seconds for the animation duration

      return () => clearTimeout(timer);
    }
  }, [feedbackMessage]);

  const addNewLanguageHandler = async () => {
    // Check if there's already a language with an empty 'language' field
    const hasEmptyLanguage = user.languages.some((lang) => !lang.language);

    if (hasEmptyLanguage) {
      alert("Please fill out all existing languages before adding a new one.");
      return;
    }

    // Send the backend a new language
    setAddingLanguage(true);
    await AddLanguage();
    setAddingLanguage(false);
  };

  const deleteLanguageHandler = async (languageId) => {
    setFeedbackMessage("Language has been deleted");
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Languages</h2>
            <div className="row">
              {user &&
                user.languages.map((item, index) => (
                  <React.Fragment key={item.id || index}>
                    <UASingleLanguage
                      langIdx={index}
                      deleteLanguageHandler={deleteLanguageHandler}
                    />
                    <SeparationLine />
                  </React.Fragment>
                ))}
              {/* Feedback message */}
              {feedbackMessage && (
                <div className="ua-feedback-message">{feedbackMessage}</div>
              )}
            </div>
            <div className="row">
              <div className="subform-add-button-div subform-btn-col-center">
                <EIIButton
                  text={"+Add a new language"}
                  color={"green"}
                  btnBig={false}
                  onClickHandler={addNewLanguageHandler}
                  disabled={addingLanguage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UALanguages;
