import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../global_utils/UserProvider";
import "./UserCircleMenu.css";

const UserCircleMenu = ({ isOpen, toggleMenu, onLogoutClick }) => {
  const [userAcronym, setUserAcronym] = useState("");
  const { user } = useContext(UserContext);

  // user has changed
  useEffect(() => {
    // user is defined
    if (user && user.firstName && user.lastName) {
      setUserAcronym(user.firstName[0] + user.lastName[0]);
    }
  }, [user]);

  return (
    <div className="user-circle-menu">
      <div
        className={`circle-profile ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        {userAcronym}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <button className="dropdown-item" onClick={onLogoutClick}>
            Log Out
          </button>
        </div>
      )}
    </div>
  );
};

export default UserCircleMenu;
