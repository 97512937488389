import React from 'react';
import DemEngBox from './DemEngBox';
import CenterTitleTextCont from './CenterTitleTextCont';


const DemEngSection = () => {

    return(
        <section id='DemEng'>
            <div className="content-section">
                <CenterTitleTextCont
                    // title={<>For engineers <span className='h2-secondary'>&</span> recruiters</>}
                    title="Top demanded engineers in Ireland"
                    text="Ireland's growth in recent years has created a huge demand for qualified engineers that the country cannot supply. A large number of engineers come to Ireland every year from all corners of the world, attracted by high salaries and the job opportunities in cutting-edge sectors as the pharmaceutical industry, the tech sector or the chemical industry."
                    content={<DemEngBox />}
                />
            </div>
        </section>
    )
}

export default DemEngSection