import React from "react";
import "./Throbber.css";

const Throbber = ({ className, justify = "center" }) => {
  // Justify content
  let justifyClass;
  if (justify === "left") {
    justifyClass = "justify-left";
  } else if (justify === "right") {
    justifyClass = "justify-right";
  } else {
    justifyClass = "justify-center";
  }

  return (
    <div className={`throbber ${className} ${justifyClass}`}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default Throbber;
