import React from "react";
import "./FormField.css";

const FormFieldCheckbox = ({
  fieldTitle,
  checkboxLabel, // This can be a string or HTML
  isRequired = false,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  value = false,
  loading = false,
}) => {
  if (!isVisible) {
    return null;
  }

  // Set title-blocked class
  let title_blocked_class = "";
  if (!fieldEnabled) title_blocked_class = "title-blocked";

  const handleCheckboxChange = (e) => {
    onFieldChange({
      value: e.target.checked,
      isValid: true, // You can add validation logic here
      isRequired,
    });
  };

  return (
    <div
      className={`formfield-checkbox-container ${isVisible ? "" : "hidden"}`}
    >
      {fieldTitle && (
        <p className={`formfield-p ${title_blocked_class}`}>
          {fieldTitle}
          {isRequired && fieldEnabled && (
            <span className="required-asterisk">*</span>
          )}
          {!isRequired && fieldEnabled && <span> (optional)</span>}
        </p>
      )}
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          className={`formfield-checkbox ${fieldEnabled ? "" : "disabled"}`}
          checked={value}
          disabled={!fieldEnabled}
          onChange={handleCheckboxChange}
        />
        {loading ? (
          <div className="loading-field">Loading...</div>
        ) : (
          <div className="checkbox-label">{checkboxLabel}</div>
        )}
      </div>
    </div>
  );
};

export default FormFieldCheckbox;
