import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import LogoEII from "../images/NavBar/Logo 1_crop.webp";
import "./TopNavbarUserArea.css";

const TopNavbarUserArea = ({ setSelMenu }) => {
  const [selectedMenu, setSelectedMenu] = useState("personal-data");

  useEffect(() => {
    setSelMenu(selectedMenu);
  }, [selectedMenu, setSelMenu]);

  return (
    <Navbar collapseOnSelect expand="lg" className="top-navbar-user-area">
      <Container>
        <Navbar.Brand as={Link} to="/#Home">
          <img src={LogoEII} className="navbar-logo" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={selectedMenu}
            onSelect={(selectedKey) => {
              setSelectedMenu(selectedKey);
            }}
          >
            <Nav.Link
              eventKey="personal-data"
              as={Link}
              to="/UserArea/personal-data"
            >
              Personal Data
            </Nav.Link>
            <Nav.Link eventKey="studies" as={Link} to="/UserArea/studies">
              Studies
            </Nav.Link>
            <Nav.Link
              eventKey="professional-experience"
              as={Link}
              to="/UserArea/professional-experience"
            >
              Professional Experience
            </Nav.Link>
            <Nav.Link eventKey="skills" as={Link} to="/UserArea/skills">
              Skills
            </Nav.Link>
            <Nav.Link eventKey="languages" as={Link} to="/UserArea/languages">
              Languages
            </Nav.Link>
            <Nav.Link eventKey="settings" as={Link} to="/UserArea/settings">
              Settings
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbarUserArea;
