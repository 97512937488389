import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./UserVerificationSent.css";

const PasswrodResetEmailSent = () => {
  return (
    <div className="colored-section title-section-uvs">
      <div className="center-content">
        <div className="email-icon">
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </div>
        <h2 className="verif-title-text">Email Sent!</h2>
        <h4 className="verif-title-text">
          Please check your email to reset your password
        </h4>
        <hr className="separation-line" />
      </div>
      <p className="center-text secondary-text">
        If you do not receive the email within a few minutes, please check your
        spam folder.
      </p>
    </div>
  );
};

export default PasswrodResetEmailSent;
