import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import EIIButton from "../global_utils/EIIButton";
import { UserContext } from "../global_utils/UserProvider";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const navigate = useNavigate();
  const { Logout } = useContext(UserContext);

  useEffect(() => {
    const handleMouseUp = () => {
      setPasswordVisible(false);
      setPasswordConfirmVisible(false);
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+=\-])[A-Za-z\d@$!%*#?&_+=\-]{8,}$/;
    return passwordPattern.test(password);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!password || !passwordConfirm) {
      setErrorMessage("Please fill in all fields.");
      return;
    } else if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match.");
      return;
    } else if (!validatePassword(password)) {
      setErrorMessage(
        "Password must have at least 8 characters, including letters, numbers, and special characters."
      );
      return;
    }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/resetPassword/${token}`,
        { password, passwordConfirm }
      );

      if (response.data.status === "success") {
        setSuccessMessage("Your password has been reset successfully!");
        setErrorMessage("");

        // Log out user
        Logout();

        // Navigate to PasswordResetCompleted
        navigate("/PasswordResetCompleted");
      } else {
        setErrorMessage("Unable to reset your password. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message ||
            "An error occurred. Please try again later."
        );
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    }
  };

  const handlePasswordMouseDown = () => {
    setPasswordVisible(true);
  };

  const handlePasswordMouseUp = () => {
    setPasswordVisible(false);
  };

  const handlePasswordConfirmMouseDown = () => {
    setPasswordConfirmVisible(true);
  };

  const handlePasswordConfirmMouseUp = () => {
    setPasswordConfirmVisible(false);
  };

  return (
    <div className="colored-section login-page">
      <div className="login-container">
        <h2 className="login-heading">Reset Password</h2>
        <form className="login-form">
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="New Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="password-toggle-icon"
              onMouseDown={handlePasswordMouseDown}
              onMouseUp={handlePasswordMouseUp}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="password-field">
            <input
              type={passwordConfirmVisible ? "text" : "password"}
              placeholder="Confirm New Password"
              className="login-input"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <span
              className="password-toggle-icon"
              onMouseDown={handlePasswordConfirmMouseDown}
              onMouseUp={handlePasswordConfirmMouseUp}
            >
              {passwordConfirmVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <EIIButton
            text="Reset Password"
            color="green"
            btnBig
            fullWidth
            onClickHandler={handlePasswordReset}
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
        </form>
        <div className="login-options">
          <a href="/#/Login" className="login-link">
            Remembered your password? Log In
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
