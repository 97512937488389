import React, { useEffect, useState } from "react";
import EIIButton from "../global_utils/EIIButton";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldDate from "../global_utils/FormFieldDate";
import FormFieldPhonePrefix from "../global_utils/FormFieldPhonePrefix";
import {
  validateTextInput,
  validateSelectInput,
  validateDateInput,
} from "../global_utils/FormFieldValidation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignUpForm1.css";
import nationalities from "../../assets/data/nationalities.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import readinessToStartList from "../../assets/data/readinessToStart.json";
import workLocationOptionsList from "../../assets/data/workLocationOptions.json";

const SignUpForm1PagePersonalData = ({
  onClickNextHandler,
  buttonText = "Next",
  buttonColor = "green",
  formData,
}) => {
  // State for information message
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // State for buttonBig
  const [buttonBig, setButtonBig] = useState(window.innerWidth > 768);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  // const [firstName, setFirstName] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editFirstName, setEditFirstName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [lastName, setLastName] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editLastName, setEditLastName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [dateOfBirth, setDateOfBirth] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editDateOfBirth, setEditDateOfBirth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [gender, setGender] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editGender, setEditGender] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [phonePrefix, setPhonePrefix] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editPhonePrefix, setEditPhonePrefix] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [phoneNumber, setPhoneNumber] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editPhoneNumber, setEditPhoneNumber] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [firstCitizenship, setFirstCitizenship] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editFirstCitizenship, setEditFirstCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [secondCitizenship, setSecondCitizenship] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editSecondCitizenship, setEditSecondCitizenship] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  // const [readinessToStart, setReadinessToStart] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editReadinessToStart, setEditReadinessToStart] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  // const [preferredWorkLocation, setPreferredWorkLocation] = useState({
  //   value: "",
  //   isValid: false,
  //   isRequired: true,
  // });
  const [editPreferredWorkLocation, setEditPreferredWorkLocation] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });

  // User data is updated
  useEffect(() => {
    if (formData) {
      transformAndSetUserData(formData);
    }
  }, [formData]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      firstName: {
        value: uData.firstName ? uData.firstName.value : "",
        isValid: editFirstName.isValid,
        isRequired: editFirstName.isRequired,
      },
      lastName: {
        value: uData.lastName ? uData.lastName.value : "",
        isValid: editLastName.isValid,
        isRequired: editLastName.isRequired,
      },
      dateOfBirth: {
        value: uData.dateOfBirth ? uData.dateOfBirth.value : "",
        isValid: editDateOfBirth.isValid,
        isRequired: editDateOfBirth.isRequired,
      },
      gender: {
        value: {
          value: uData.gender ? uData.gender.value.value : "",
          label: uData.gender ? uData.gender.value.value : "",
        },
        isValid: true,
        isRequired: true,
      },
      phonePrefix: {
        value: uData.phonePrefix ? uData.phonePrefix.value : "",
        isValid: true,
        isRequired: true,
      },
      phoneNumber: {
        value: uData.phoneNumber ? uData.phoneNumber.value : "",
        isValid: true,
        isRequired: true,
      },
      firstCitizenship: {
        value: {
          value: uData.firstCitizenship
            ? uData.firstCitizenship.value.value
            : "",
          label: uData.firstCitizenship
            ? uData.firstCitizenship.value.value
            : "",
        },
        isValid: true,
        isRequired: true,
      },
      secondCitizenship: {
        value: {
          value: uData.secondCitizenship
            ? uData.secondCitizenship.value.value
            : "",
          label: uData.secondCitizenship
            ? uData.secondCitizenship.value.value
            : "",
        },
        isValid: true,
        isRequired: false,
      },
      readinessToStart: {
        value: {
          value: uData.readinessToStart
            ? uData.readinessToStart.value.value
            : "",
          label: uData.readinessToStart
            ? uData.readinessToStart.value.value
            : "",
        },
        isValid: true,
        isRequired: false,
      },
      preferredWorkLocation: {
        value: {
          value: uData.preferredWorkLocation
            ? uData.preferredWorkLocation.value.value
            : "",
          label: uData.preferredWorkLocation
            ? uData.preferredWorkLocation.value.value
            : "",
        },
        isValid: true,
        isRequired: false,
      },
    };

    // Initialize firstName
    // setFirstName(newFormData.firstName);
    setEditFirstName(newFormData.firstName);
    // Initialize lastName
    // setLastName(newFormData.lastName);
    setEditLastName(newFormData.lastName);
    // Initialize dateOfBirth
    // setDateOfBirth(newFormData.dateOfBirth);
    setEditDateOfBirth(newFormData.dateOfBirth);
    // Initialize gender
    // setGender(newFormData.gender);
    setEditGender(newFormData.gender);
    // Initialize phonePrefix
    // setPhonePrefix(newFormData.phonePrefix);
    setEditPhonePrefix(newFormData.phonePrefix);
    // Initialize phoneNumber
    // setPhoneNumber(newFormData.phoneNumber);
    setEditPhoneNumber(newFormData.phoneNumber);
    // Initialize firstCitizenship
    // setFirstCitizenship(newFormData.firstCitizenship);
    setEditFirstCitizenship(newFormData.firstCitizenship);
    // Initialize secondCitizenship
    // setSecondCitizenship(newFormData.secondCitizenship);
    setEditSecondCitizenship(newFormData.secondCitizenship);
    // Initialize readinessToStart
    // setReadinessToStart(newFormData.readinessToStart);
    setEditReadinessToStart(newFormData.readinessToStart);
    // Initialize preferredWorkLocation
    // setPreferredWorkLocation(newFormData.preferredWorkLocation);
    setEditPreferredWorkLocation(newFormData.preferredWorkLocation);
  };

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      firstName: editFirstName,
      lastName: editLastName,
      dateOfBirth: editDateOfBirth,
      gender: editGender,
      phonePrefix: editPhonePrefix,
      phoneNumber: editPhoneNumber,
      firstCitizenship: editFirstCitizenship,
      secondCitizenship: editSecondCitizenship,
      readinessToStart: editReadinessToStart,
      preferredWorkLocation: editPreferredWorkLocation,
    });
  }, [
    editFirstName,
    editLastName,
    editDateOfBirth,
    editGender,
    editPhonePrefix,
    editPhoneNumber,
    editFirstCitizenship,
    editSecondCitizenship,
    editReadinessToStart,
    editPreferredWorkLocation,
  ]);

  // useEffect hook for resizing window and next/back/submit buttons
  useEffect(() => {
    const handleResize = () => {
      setButtonBig(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Routine to verify if visa is required and set info message
  const checkVisaRequired = (fCitizenship, sCitizenship) => {
    // // Only check this if user doesn't know if visa is required
    // if (visaRequired.value !== "I don't know") {
    //   setInfoMessage("");
    //   return;
    // }

    // No nationalities selected
    if (!fCitizenship && !sCitizenship) {
      setInfoMessage("");
      return;
    }

    if (
      fCitizenship in nationalities.nationalities &&
      !nationalities.nationalities[fCitizenship].visaRequiredIreland
    ) {
      setInfoMessage(
        `✔️ ${fCitizenship} nationality doesn't require a working visa in Ireland!`
      );
      return;
    } else if (
      sCitizenship in nationalities.nationalities &&
      !nationalities.nationalities[sCitizenship].visaRequiredIreland
    ) {
      setInfoMessage(
        `✔️ ${sCitizenship} nationality doesn't require a working visa in Ireland!`
      );
      return;
    } else if (fCitizenship && fCitizenship !== "") {
      setInfoMessage(" You require a working visa in Ireland..");
      return;
    }

    // Visa is required
    return;
  };

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      firstName: {
        ...editFirstName,
        isValid: validateTextInput(
          editFirstName.value,
          editFirstName.isRequired,
          textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      lastName: {
        ...editLastName,
        isValid: validateTextInput(
          editLastName.value,
          editLastName.isRequired,
          textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      dateOfBirth: {
        ...editDateOfBirth,
        isValid: validateDateInput(
          editDateOfBirth.value,
          editDateOfBirth.isRequired,
          true // isVisible
        ),
      },
      gender: {
        ...editGender,
        isValid: validateSelectInput(
          editGender.value.value,
          editGender.isRequired,
          true // isVisible
        ),
      },
      phonePrefix: {
        ...editPhonePrefix,
        isValid: validateSelectInput(
          editPhonePrefix.value.value,
          editPhonePrefix.isRequired,
          true // isVisible
        ),
      },
      phoneNumber: {
        ...editPhoneNumber,
        isValid: validateTextInput(
          editPhoneNumber.value,
          editPhoneNumber.isRequired,
          15, //textInputMaxLength,
          7, //textInputMinLength,
          true // isVisible
        ),
      },
      firstCitizenship: {
        ...editFirstCitizenship,
        isValid: validateSelectInput(
          editFirstCitizenship.value.value,
          editFirstCitizenship.isRequired,
          true // isVisible
        ),
      },
      secondCitizenship: {
        ...editSecondCitizenship,
        isValid: validateSelectInput(
          editSecondCitizenship.value.value,
          editSecondCitizenship.isRequired,
          true // isVisible
        ),
      },
      readinessToStart: {
        ...editReadinessToStart,
        isValid: validateSelectInput(
          editReadinessToStart.value.value,
          editReadinessToStart.isRequired,
          true // isVisible
        ),
      },
      preferredWorkLocation: {
        ...editPreferredWorkLocation,
        isValid: validateSelectInput(
          editPreferredWorkLocation.value.value,
          editPreferredWorkLocation.isRequired,
          true // isVisible
        ),
      },
    };
  };

  const validateForm = () => {
    const dob = new Date(editDateOfBirth.value);
    const today = new Date();
    const oldestValidDate = new Date();
    const youngestValidDate = new Date();

    oldestValidDate.setFullYear(today.getFullYear() - 120); // Person can't be more than 120 years old
    youngestValidDate.setFullYear(today.getFullYear() - 18); // Person must be at least 18 years old

    if (dob > today) {
      setErrorMessage("Date of birth can't be in the future.");
      return false;
    } else if (dob < oldestValidDate) {
      setErrorMessage(
        "Date of birth is not valid. Age limit exceeded (over 120 years old)."
      );
      return false;
    } else if (dob > youngestValidDate) {
      setErrorMessage(
        "Registration denied. You must be at least 18 years old."
      );
      return false;
    }

    setErrorMessage(""); // Clear error message if date is valid
    return true;
  };

  // Handler for NEXT button
  const nextButtonHandler = () => {
    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid && validateForm()) {
      // Save the edited values
      // setFirstName(editFirstName);
      // setLastName(editLastName);
      // setDateOfBirth(editDateOfBirth);
      // setGender(editGender);
      // setPhonePrefix(editPhonePrefix);
      // setPhoneNumber(editPhoneNumber);
      // setFirstCitizenship(editFirstCitizenship);
      // setSecondCitizenship(editSecondCitizenship);
      // setReadinessToStart(editReadinessToStart);
      // setPreferredWorkLocation(editPreferredWorkLocation);

      onClickNextHandler(fieldsData);
    } else {
      setActivateRequiredFields(true);
    }
  };

  // Routine to handle firstName
  const handleFirstNameChange = (value) => {
    setEditFirstName(value);
  };

  // Routine to handle lastName
  const handleLastNameChange = (value) => {
    setEditLastName(value);
  };

  // Routine to handle dateOfBirth
  const handleDateOfBirthChange = (value) => {
    setEditDateOfBirth(value);
  };

  // Routine to handle gender
  const handleGenderChange = (value) => {
    setEditGender(value);
  };

  // Routine to handle phonePrefix
  const handlePhonePrefixChange = (value) => {
    setEditPhonePrefix(value);
  };

  // Routine to handle phoneNumber
  const handlePhoneNumberChange = (value) => {
    setEditPhoneNumber(value);
  };

  // Routine to handle fist citizenship
  const handleFirstCitizenshipChange = (value) => {
    setEditFirstCitizenship(value);

    // Check visa required
    checkVisaRequired(value.value.value, editSecondCitizenship.value.value);
  };

  // Routine to handle Second Citizenship
  const handleSecondCitizenshipChange = (value) => {
    setEditSecondCitizenship(value);

    // Check visa required
    checkVisaRequired(editFirstCitizenship.value.value, value.value.value);
  };

  // Routine to handle readinessToStart
  const handleReadinessToStartChange = (value) => {
    setEditReadinessToStart(value);
  };

  // Routine to handle preferredWorkLocation
  const handlePreferredWorkLocationChange = (value) => {
    setEditPreferredWorkLocation(value);
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Personal Data</h2>
            <form className="signup-form">
              <div className="row">
                <div className={"col-md-6"}>
                  <FormFieldText
                    fieldTitle={"First name"}
                    inPlaceholder={"Enter your first name"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleFirstNameChange}
                    value={editFirstName.value}
                  />
                </div>
                <div className={"col-md-6"}>
                  <FormFieldText
                    fieldTitle={"Last name"}
                    inPlaceholder={"Enter your last name"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleLastNameChange}
                    value={editLastName.value}
                  />
                </div>
              </div>
              <FormFieldDate
                fieldTitle={"Date of birth"}
                inPlaceholder={"Enter your date of birth"}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleDateOfBirthChange}
                value={editDateOfBirth.value}
              />
              <FormFieldSelect
                fieldTitle={"Gender"}
                inPlaceholder={"Select your gender"}
                inOptionsArray={["Male", "Female", "Other"]}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleGenderChange}
                value={editGender.value}
              />
              <div className="row">
                <div className={"col-md-4"}>
                  <FormFieldPhonePrefix
                    fieldTitle={"Phone Number"}
                    inPlaceholder={"Prefix"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handlePhonePrefixChange}
                    value={editPhonePrefix.value}
                  />
                </div>
                <div className={"col-md-8 align-bottom"}>
                  <FormFieldText
                    inPlaceholder={"Enter your phone number"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handlePhoneNumberChange}
                    value={editPhoneNumber.value}
                    allowOnlyIntegers={true}
                    textMinLenght={7}
                    textMaxLength={15}
                  />
                </div>
              </div>

              <h2 className="signupform-title2">Citizenship</h2>

              <div className="row">
                <div className={"col-md-6"}>
                  <FormFieldSelect
                    fieldTitle={"First Citizenship"}
                    inPlaceholder={"Enter your first citizenship"}
                    inOptionsArray={Object.keys(nationalities.nationalities)}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleFirstCitizenshipChange}
                    value={editFirstCitizenship.value}
                  />
                </div>
                <div className={"col-md-6"}>
                  <FormFieldSelect
                    fieldTitle={"Second Citizenship"}
                    inPlaceholder={"Enter your second citizenship"}
                    inOptionsArray={[
                      "I don't have a second citizenship",
                      ...Object.keys(nationalities.nationalities),
                    ]}
                    isRequired={false}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleSecondCitizenshipChange}
                    value={editSecondCitizenship.value}
                  />
                </div>
              </div>
              {infoMessage && (
                <div className="row">
                  <p
                    className="col text-center"
                    style={{
                      color: infoMessage.includes(
                        "nationality doesn't require a working visa in Ireland!"
                      )
                        ? "var(--btn_green_dark2_color)"
                        : "var(--btn_blue_dark_color)",
                      marginTop: "30px",
                    }}
                  >
                    {infoMessage.includes(
                      "You require a working visa in Ireland"
                    ) && (
                      <FontAwesomeIcon
                        icon={faFileCircleExclamation}
                        size="1x"
                      />
                    )}
                    {infoMessage}
                  </p>
                </div>
              )}

              <h2 className="signupform-title2">Working Preferences</h2>
              <div className="row">
                <FormFieldSelect
                  fieldTitle={"Readiness to start working"}
                  inPlaceholder={"Enter when can you start working in Ireland"}
                  inOptionsArray={readinessToStartList}
                  isRequired={true}
                  activateRequired={activateRequiredFields}
                  onFieldChange={handleReadinessToStartChange}
                  value={editReadinessToStart.value}
                />
              </div>
              <div className="row">
                <FormFieldSelect
                  fieldTitle={"Preferred work location"}
                  inPlaceholder={
                    "Enter where you prefer to work within Ireland"
                  }
                  allowTyping={true}
                  inOptionsArray={workLocationOptionsList}
                  isRequired={true}
                  activateRequired={activateRequiredFields}
                  onFieldChange={handlePreferredWorkLocationChange}
                  value={editPreferredWorkLocation.value}
                />
              </div>
            </form>
            {errorMessage && (
              <div className="row">
                <div
                  className="col text-center"
                  style={{ color: "red", marginTop: "20px" }}
                >
                  {errorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row right-bottom-row">
          <div className="col-md-6 right-bottom-col-left">
            {/* Empty field to keep the Next button in tha same place*/}
          </div>
          <div className="col-md-6 right-bottom-col-right">
            <EIIButton
              text={buttonText}
              color={buttonColor}
              btnBig={buttonBig}
              onClickHandler={nextButtonHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm1PagePersonalData;
