import React, { useContext, useEffect } from "react";
import { UserContext } from "../global_utils/UserProvider"; // Adjust the path as per your project structure
import { useParams } from "react-router-dom";

const VerifyEmail = () => {
  const { VerifyEmail } = useContext(UserContext);
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      VerifyEmail(token);
    }
  }, [token, VerifyEmail]);

  return <></>;
};

export default VerifyEmail;
