import React from "react";
import Vector from "../images/HomeSection/Vector 1.webp";

const TextWithVector = ({ title_type, title_text, title_class }) => {
  // title_type can only be set to h1 or h2. Any other value is going to be considered as h3.
  const title_class_name = "vector-txt";

  // Set vector div class depending on title_class
  let vector_div_class = "vector-div vector-inline";
  // if(title_class !== undefined) {
  //     vector_div_class += ' vector-inline'
  // }

  // Set image class depending on title type
  let vector_img_class = "vector-img";
  if (title_type === "h1") {
    vector_img_class += " vector-img-h1";
  } else if (title_type === "h2") {
    vector_img_class += " vector-img-h2";
  }

  return (
    <div className={vector_div_class}>
      {title_type === "h1" ? (
        <h1 className={title_class_name}>{title_text}</h1>
      ) : title_type === "h2" ? (
        <h2 className={title_class_name}>{title_text}</h2>
      ) : (
        <h3 className={title_class_name}>{title_text}</h3>
      )}
      <img
        className={vector_img_class}
        src={Vector}
        alt="Engineers In Ireland"
      />
    </div>
  );
};

export default TextWithVector;
