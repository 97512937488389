import React, { useContext } from "react";
import "./UserDropdownMenu.css";
import { UserContext } from "../global_utils/UserProvider";

const UserDropdownMenu = React.forwardRef(({ toggleMenu }, ref) => {
  const { Logout } = useContext(UserContext);

  const handleLogOut = async () => {
    Logout();
  };

  return (
    <div className="user-dropdown-menu" ref={ref}>
      <ul className="user-dropdown-ul">
        <li
          className="user-dropdown-li"
          onClick={() => {
            toggleMenu();
            window.location.href = "/#/UserArea";
          }}
        >
          User Area
        </li>
        <li className="user-dropdown-li" onClick={handleLogOut}>
          Log Out
        </li>
      </ul>
    </div>
  );
});

export default UserDropdownMenu;
