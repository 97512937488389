import React from "react";
import PostParagraph from "../PostParagraph";
import PostTip from "../PostTip";
import PostTpl from "../PostTpl";
import "./Posts.css";

const PostMoveIrelandEU = () => {
  return (
    <>
      <PostTpl
        title="Moving to Ireland as an EU citizen"
        date="15 APR 2023"
        type="LIFE"
        content={
          <>
            <PostParagraph text="Citizens of EEA (the EU, plus Iceland, Norway and Lichtenstein), Switzerland and the UK can live and work within the Irish territory without the need for a working permit. This is a very big advantage as companies prefer to employ engineers that don’t require a visa to work." />

            <PostParagraph text="If you have a nationality from one of the countries mentioned above, the only thing that you will be required for entering the Irish territory is a valid passport or ID." />

            <h3 className="post-subtitle">
              How long can I remain in Ireland as an EU citizen?
            </h3>
            <PostParagraph text="You can remain in Ireland for 6 months without registering with Immigration Service Delivery (the Irish immigration authorities). This period can also be extended if you can prove you can support yourself without requiring social Welfare assistance." />

            <h3 className="post-subtitle">
              Can I bring family that doesn’t hold an EU passport to Ireland?
            </h3>
            <p className="content-p">
              If you have family members, they can come with you without any
              restrictions if they are citizens of EEA (the EU, plus Iceland,
              Norway and Lichtenstein), Switzerland and the UK. However, if they
              have other nationalities, they&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="colored-a"
                href="https://www.irishimmigration.ie/coming-to-join-family-in-ireland/joining-an-eea-or-swiss-national/eu-treaty-rights/"
              >
                must apply for residence
              </a>
              . They will also verify if their citizenship&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="colored-a"
                href="https://www.citizensinformation.ie/en/moving_country/visas_for_ireland/visa_requirements_for_entering_ireland.html#l71449"
              >
                requires a visa to enter Ireland
              </a>
              &nbsp;before coming.
            </p>

            <PostParagraph
              text={
                <ol className="content-p">
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.linkedin.com/"
                    >
                      LinkedIn
                    </a>{" "}
                    – This has become the most popular business networking
                    platform where firms based in Ireland post engineering job
                    offers.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://ie.indeed.com/"
                    >
                      Indeed
                    </a>{" "}
                    – This platform aggregates job posting from different
                    sources, making the job search easily accessible from one
                    place.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.monster.ie/"
                    >
                      Monster
                    </a>{" "}
                    – This is one of the most worldwide known job platforms with
                    many job listings and career resources.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.irishjobs.ie/"
                    >
                      IrishJobs
                    </a>{" "}
                    – This is the leading job search website in Ireland
                    containing hundreds of job offers within the country.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://jobsireland.ie/"
                    >
                      JobsIreland
                    </a>{" "}
                    – This is the national employment portal for Ireland that
                    provides job listings and employment services to job
                    seekers.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.engineersireland.ie/Find-a-job/"
                    >
                      EngineersIreland
                    </a>{" "}
                    – A professional organization for engineers in Ireland that
                    contain national job listings focused on the engineering
                    sector.
                  </li>
                </ol>
              }
            />

            <PostTip
              title="Set job alerts to stay notified!"
              text="Even if you can’t find the job you are looking for immediately, you can configure your search filters and get notified when new jobs are posted. Being quick to apply for a job will also increase the chance of entering the selection process!"
            />

            <PostTip
              title="Contact engineers in Ireland on LinkedIn"
              text="You can send LinkedIn connections to engineers in your field that are already living and working in Ireland. Message them and tell them your intentions to work in Ireland, maybe they can recommend you to their company or provide you with valuable information such as recruiter contacts, salary ranges, etc. Don’t be shy, many nice people are willing to help others!"
            />

            <PostTip
              title="Search Irish recruiters on LinkedIn"
              text="Irish recruiters might not have visibility over your profile as you are not living in the country. However, you can go to the LinkedIn search bar and type something such as “Civil Engineering Recruiter Ireland” and send them a connection request and message them asking for potential jobs. Most likely they will provide you with interesting opportunities that match your profile!"
            />
          </>
        }
      />
    </>
  );
};

export default PostMoveIrelandEU;
