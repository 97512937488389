import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../global_utils/UserProvider";
import UASinglePageButtons from "./UASinglePageButtons";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
} from "../global_utils/FormFieldValidation";
import {
  startMonthYearValidation,
  startEndMonthYearValidation,
} from "../global_utils/GeneralValidation";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import industries from "../../assets/data/industries.json";
import monthsList from "../../assets/data/months.json";

const UASingleProfessionalExp = ({
  professionalExpIdx,
  deleteProfessionalExpHandler,
}) => {
  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const { user, loading, RefreshUserData, DeleteProfessionalExp } =
    useContext(UserContext);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [engPostion, setEngPosition] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEngPosition, setEditEngPosition] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [companyName, setCompanyName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCompanyName, setEditCompanyName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [engIndustry, setEngIndustry] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEngIndustry, setEditEngIndustry] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [startingMonth, setStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStartingMonth, setEditStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [startingYear, setStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStartingYear, setEditStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [currentlyWorking, setCurrentlyWorking] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCurrentlyWorking, setEditCurrentlyWorking] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [endingMonth, setEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editEndingMonth, setEditEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [endingYear, setEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editEndingYear, setEditEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [jobDescription, setJobDescription] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editJobDescription, setEditJobDescription] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    // Update current professional experience
    let l_experiences;
    if (user.experiences) {
      l_experiences = JSON.parse(JSON.stringify(user.experiences));
      l_experiences[professionalExpIdx].engPosition = editEngPosition.value;
      l_experiences[professionalExpIdx].companyName = editCompanyName.value;
      l_experiences[professionalExpIdx].engIndustry =
        editEngIndustry.value.value;
      l_experiences[professionalExpIdx].startingMonth =
        editStartingMonth.value.value;
      l_experiences[professionalExpIdx].startingYear = editStartingYear.value;
      l_experiences[professionalExpIdx].currentlyWorking =
        editCurrentlyWorking.value;
      l_experiences[professionalExpIdx].endingMonth =
        editEndingMonth.value?.value || "";
      l_experiences[professionalExpIdx].endingYear = editEndingYear.value;
      l_experiences[professionalExpIdx].jobDescription =
        editJobDescription.value;
    }

    setFieldsData({
      experiences: l_experiences,
      engPostion: editEngPosition,
      companyName: editCompanyName,
      engIndustry: editEngIndustry,
      startingMonth: editStartingMonth,
      startingYear: editStartingYear,
      currentlyWorking: editCurrentlyWorking,
      endingMonth: editEndingMonth,
      endingYear: editEndingYear,
      jobDescription: editJobDescription,
    });
  }, [
    editEngPosition,
    editCompanyName,
    editEngIndustry,
    editStartingMonth,
    editStartingYear,
    editCurrentlyWorking,
    editEndingMonth,
    editEndingYear,
    editJobDescription,
    user.experiences,
    professionalExpIdx,
  ]);

  // Clear Ending Month and Year if currently working in this company
  useEffect(() => {
    // If user is currently working here clear Ending Month and Year
    if (
      editCurrentlyWorking.value === "Yes" &&
      endingMonth.value.value &&
      (endingMonth.value.value !== "" || endingYear.value !== "")
    ) {
      setEditEndingMonth({
        value: {
          value: "",
          label: "",
        },
        isValid: editEndingMonth.isValid,
        isRequired: editEndingMonth.isRequired,
      });
      setEditEndingYear({
        value: "",
        isValid: editEndingYear.isValid,
        isRequired: editEndingYear.isRequired,
      });
    }
  }, [editCurrentlyWorking]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      engPosition: {
        value: uData.experiences[professionalExpIdx].engPosition || "",
        isValid: editEngPosition.isValid,
        isRequired: editEngPosition.isRequired,
      },
      companyName: {
        value: uData.experiences[professionalExpIdx].companyName || "",
        isValid: editCompanyName.isValid,
        isRequired: editCompanyName.isRequired,
      },
      engIndustry: {
        value: {
          value: uData.experiences[professionalExpIdx].engIndustry || "",
          label: uData.experiences[professionalExpIdx].engIndustry || "",
        },
        isValid: editEngIndustry.isValid,
        isRequired: editEngIndustry.isRequired,
      },
      startingMonth: {
        value: {
          value: uData.experiences[professionalExpIdx].startingMonth || "",
          label: uData.experiences[professionalExpIdx].startingMonth || "",
        },
        isValid: editStartingMonth.isValid,
        isRequired: editStartingMonth.isRequired,
      },
      startingYear: {
        value: uData.experiences[professionalExpIdx].startingYear || "",
        isValid: editStartingYear.isValid,
        isRequired: editStartingYear.isRequired,
      },
      currentlyWorking: {
        value:
          uData.experiences[professionalExpIdx].endingYear &&
          uData.experiences[professionalExpIdx].endingYear !== ""
            ? "No"
            : "Yes",
        isValid: editCurrentlyWorking.isValid,
        isRequired: editCurrentlyWorking.isRequired,
      },
      endingMonth: {
        value: {
          value: uData.experiences[professionalExpIdx].endingMonth || "",
          label: uData.experiences[professionalExpIdx].endingMonth || "",
        },
        isValid: editEndingMonth.isValid,
        isRequired: editEndingMonth.isRequired,
      },
      endingYear: {
        value: uData.experiences[professionalExpIdx].endingYear || "",
        isValid: editEndingYear.isValid,
        isRequired: editEndingYear.isRequired,
      },
      jobDescription: {
        value: uData.experiences[professionalExpIdx].jobDescription || "",
        isValid: editJobDescription.isValid,
        isRequired: editJobDescription.isRequired,
      },
    };

    // Initialize engPosition
    setEngPosition(newFormData.engPosition);
    setEditEngPosition(newFormData.engPosition);
    // Initialize companyName
    setCompanyName(newFormData.companyName);
    setEditCompanyName(newFormData.companyName);
    // Initialize engIndustry
    setEngIndustry(newFormData.engIndustry);
    setEditEngIndustry(newFormData.engIndustry);
    // Initialize startingMonth
    setStartingMonth(newFormData.startingMonth);
    setEditStartingMonth(newFormData.startingMonth);
    // Initialize startingYear
    setStartingYear(newFormData.startingYear);
    setEditStartingYear(newFormData.startingYear);
    // Initialize currentlyWorking
    setCurrentlyWorking(newFormData.currentlyWorking);
    setEditCurrentlyWorking(newFormData.currentlyWorking);
    // Initialize endingMonth
    setEndingMonth(newFormData.endingMonth);
    setEditEndingMonth(newFormData.endingMonth);
    // Initialize endingYear
    setEndingYear(newFormData.endingYear);
    setEditEndingYear(newFormData.endingYear);
    // Initialize jobDescription
    setJobDescription(newFormData.jobDescription);
    setEditJobDescription(newFormData.jobDescription);
  };

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      engPostion: {
        ...editEngPosition,
        isValid: validateTextInput(
          editEngPosition.value,
          editEngPosition.isRequired,
          textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      companyName: {
        ...editCompanyName,
        isValid: validateTextInput(
          editCompanyName.value,
          editCompanyName.isRequired,
          textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      engIndustry: {
        ...editEngIndustry,
        isValid: validateSelectInput(
          editEngIndustry.value.value,
          editEngIndustry.isRequired,
          true
        ),
      },
      startingMonth: {
        ...editStartingMonth,
        isValid: validateSelectInput(
          editStartingMonth.value.value,
          editStartingMonth.isRequired,
          true
        ),
      },
      startingYear: {
        ...editStartingYear,
        isValid: validateTextInput(
          editStartingYear.value,
          editStartingYear.isRequired,
          4, //textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      currentlyWorking: {
        ...editCurrentlyWorking,
        isValid: validateRadioButtonInput(
          editCurrentlyWorking.value,
          editCurrentlyWorking.isRequired,
          true
        ),
      },
      endingMonth: {
        ...editEndingMonth,
        isValid: validateSelectInput(
          editEndingMonth.value.value,
          editEndingMonth.isRequired,
          editCurrentlyWorking === "No" // isVisible
        ),
      },
      endingYear: {
        ...editEndingYear,
        isValid: validateTextInput(
          editEndingYear.value,
          editEndingYear.isRequired,
          4, //textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorking === "No" // isVisible
        ),
      },
      jobDescription: {
        ...editJobDescription,
        isValid: validateTextInput(
          editJobDescription.value,
          editJobDescription.isRequired,
          2000, //textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorking === "No" // isVisible
        ),
      },
    };
  };

  // Routine to add validation conditions
  const validateForm = () => {
    if (editCurrentlyWorking.value === "No") {
      return startEndMonthYearValidation(
        editStartingMonth.value.value,
        editStartingYear.value,
        editEndingMonth.value.value,
        editEndingYear.value,
        setErrorMessage
      );
    } else if (editCurrentlyWorking.value === "Yes") {
      return startMonthYearValidation(
        editStartingMonth.value.value,
        editStartingYear.value,
        setErrorMessage
      );
    }

    return true;
  };

  // Handler to sumbit data to the backend
  const submitDataHandler = async () => {
    // Disable submit
    setEnableSubmit(false);

    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid && validateForm()) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
          {
            experiences: fieldsData.experiences,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Ask UserProvider to refresh user data
          RefreshUserData();

          // Save the edited engPosition
          setEngPosition(editEngPosition);
          // Save the edited companyName
          setCompanyName(editCompanyName);
          // Save the edited engIndustry
          setEngIndustry(editEngIndustry);
          // Save the edited startingMonth
          setStartingMonth(editStartingMonth);
          // Save the edited startingYear
          setStartingYear(editStartingYear);
          // Save the edited currentlyWorking
          setCurrentlyWorking(editCurrentlyWorking);
          // Save the edited endingMonth
          setEndingMonth(editEndingMonth);
          // Save the edited endingYear
          setEndingYear(editEndingYear);
          // Save the edited jobDescription
          setJobDescription(editJobDescription);
        } else {
          console.error("Failed to update user");
        }

        // Enable submit
        setEnableSubmit(true);
      } catch (error) {
        console.error("Error updating user data:", error);
        // Enable submit
        setEnableSubmit(true);
      }

      setEditEnable(false);
    } else {
      // Activate Required Fields
      setActivateRequiredFields(true);

      // Enable submit
      setEnableSubmit(true);
    }
  };

  // Handle to delete data from the backend
  const deleteDataHandler = async () => {
    setIsDeleting(true); // Start of deletion process

    await DeleteProfessionalExp(professionalExpIdx); // Perform the deletion

    setIsDeleting(false); // End of deletion process

    // Call delete handler upstream
    deleteProfessionalExpHandler();
  };

  // Routine to handle engPosition
  const handleEngPositionChange = (value) => {
    setEditEngPosition(value);
  };

  // Routine to handle companyName
  const handleCompanyNameChange = (value) => {
    setEditCompanyName(value);
  };

  // Routine to handle engIndustry
  const handleEngIndustryChange = (value) => {
    setEditEngIndustry(value);
  };

  // Routine to handle startingMonth
  const handleStartingMonthChange = (value) => {
    setEditStartingMonth(value);
  };

  // Routine to handle startingYear
  const handleStartingYearChange = (value) => {
    setEditStartingYear(value);
  };

  // Routine to handle currentlyWorking
  const handleCurrentlyWorkingChange = (value) => {
    setEditCurrentlyWorking(value);
  };

  // Routine to handle endingMonth
  const handleEndingMonthChange = (value) => {
    setEditEndingMonth(value);
  };

  // Routine to handle endingYear
  const handleEndingYearChange = (value) => {
    setEditEndingYear(value);
  };

  // Routine to handle jobDescription
  const handleJobDescriptionChange = (value) => {
    setEditJobDescription(value);
  };

  // Handler for NEXT button
  const editButtonHandler = () => {
    setEditEnable(true);
  };

  // Handler for CANCEL button
  const cancelHandler = () => {
    // Cancel is pressed and no engPosition is set, delete professional experience
    if (engPostion.value === "") {
      deleteDataHandler();
      return;
    }

    // Revert the edit fields to the saved fields
    setEditEngPosition(engPostion);
    setEditCompanyName(companyName);
    setEditEngIndustry(engIndustry);
    setEditStartingMonth(startingMonth);
    setEditStartingYear(startingYear);
    setEditCurrentlyWorking(currentlyWorking);
    setEditEndingMonth(endingMonth);
    setEditEndingYear(endingYear);
    setEditJobDescription(jobDescription);

    // Deactivate required fields
    setActivateRequiredFields(false);

    // Disable Edit
    setEditEnable(false);
  };

  // Always enable Edit if professional experience is not defined
  if (
    !editEnable &&
    user &&
    professionalExpIdx < user.experiences.length &&
    user.experiences[professionalExpIdx].engPosition === "" &&
    editEngPosition.value === ""
  ) {
    setEditEnable(true);
  }

  return (
    <>
      {editEnable ? (
        <form className="signup-form">
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Job Position"}
              inPlaceholder={
                "Enter your job position (e.g. Automation Engineer)"
              }
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleEngPositionChange}
              value={editEngPosition.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Company Name"}
              inPlaceholder={"Enter your company name"}
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleCompanyNameChange}
              value={editCompanyName.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldSelect
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Company Industry"}
              inPlaceholder={"Enter the company industry"}
              inOptionsArray={industries}
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleEngIndustryChange}
              value={editEngIndustry.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <div className={"col-md-6"}>
              <FormFieldSelect
                fieldTitle={"Starting month"}
                inPlaceholder={"Enter your starting month"}
                fieldEnabled={editEnable && enableSubmit}
                inOptionsArray={monthsList}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStartingMonthChange}
                value={editStartingMonth.value}
                loading={loading}
              />
            </div>
            <div className={"col-md-6"}>
              <FormFieldText
                fieldTitle={"Starting Year"}
                inPlaceholder={"Enter the starting year"}
                fieldEnabled={editEnable && enableSubmit}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStartingYearChange}
                value={editStartingYear.value}
                allowOnlyIntegers
                textMaxLength={4}
                loading={loading}
              />
            </div>
          </div>
          <FormFieldRadioButton
            fieldTitle="Are you currently working in this company?"
            inOptionsArray={["Yes", "No"]}
            fieldEnabled={editEnable && enableSubmit}
            isRequired={true}
            radioButtonsInSingleRow={false}
            activateRequired={activateRequiredFields}
            onFieldChange={handleCurrentlyWorkingChange}
            value={editCurrentlyWorking.value}
            loading={loading}
          />
          <div className="row">
            <div
              className={`col-md-6 ${
                editCurrentlyWorking.value === "No" ? "" : "d-none"
              }`}
            >
              <FormFieldSelect
                fieldTitle={"Ending month"}
                inPlaceholder={"Enter your Ending month"}
                fieldEnabled={editEnable && enableSubmit}
                inOptionsArray={monthsList}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEndingMonthChange}
                value={editEndingMonth.value}
                isVisible={editCurrentlyWorking.value === "No"}
                loading={loading}
              />
            </div>
            <div
              className={`col-md-6 ${
                editCurrentlyWorking.value === "No" ? "" : "d-none"
              }`}
            >
              <FormFieldText
                fieldTitle={"Ending Year"}
                inPlaceholder={"Enter the Ending year"}
                fieldEnabled={editEnable && enableSubmit}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEndingYearChange}
                value={editEndingYear.value}
                allowOnlyIntegers
                textMaxLength={4}
                isVisible={editCurrentlyWorking.value === "No"}
                loading={loading}
              />
            </div>
          </div>
          <div className="row">
            <FormFieldText
              fieldTitle={"Job Description"}
              inPlaceholder={
                "Enter a description of the job (max. 2000 characters)"
              }
              fieldEnabled={editEnable && enableSubmit}
              isRequired={false}
              activateRequired={activateRequiredFields}
              onFieldChange={handleJobDescriptionChange}
              value={editJobDescription.value}
              textMaxLength={2000}
              isMultiline={true}
              loading={loading}
            />
          </div>
        </form>
      ) : (
        <>
          <p className="subform-title-p">
            {editEngPosition ? editEngPosition.value : ""}
          </p>
          <p className="subform-text-p">
            <strong>Company name:</strong>{" "}
            {editCompanyName ? editCompanyName.value : ""}
          </p>
          <p className="subform-text-p">
            <strong>Industry:</strong>{" "}
            {editEngIndustry ? editEngIndustry.value.label : ""}
          </p>
          <p className="subform-text-p">
            <strong>Working period:</strong>{" "}
            {editStartingMonth ? editStartingMonth.value.label : ""}{" "}
            {editStartingYear ? editStartingYear.value : ""} {" - "}
            {editCurrentlyWorking && editCurrentlyWorking.value === "Yes" ? (
              "Present"
            ) : (
              <>
                {editEndingMonth ? editEndingMonth.value.label : ""}{" "}
                {editEndingYear ? editEndingYear.value : ""}
              </>
            )}
          </p>
          <p className="subform-text-p">
            <strong>Description:</strong>
          </p>
          <pre style={{ whiteSpace: "pre-wrap" }} className="subform-text-pre">
            {editJobDescription ? editJobDescription.value : ""}
          </pre>
        </>
      )}
      {errorMessage && (
        <div className="row">
          <div
            className="col text-center"
            style={{ color: "red", marginTop: "20px" }}
          >
            {errorMessage}
          </div>
        </div>
      )}
      <UASinglePageButtons
        isEditEnabled={editEnable}
        isDeleting={isDeleting}
        onEditClick={editButtonHandler}
        onSubmitClick={submitDataHandler}
        onCancelClick={cancelHandler}
        onDeleteClick={deleteDataHandler}
        submitButtonDisabled={!enableSubmit}
      />
    </>
  );
};

export default UASingleProfessionalExp;
