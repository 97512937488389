import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRouteAgainsLoggedOutUsers = ({ children }) => {
  // Check if user exists by inspecting jwt_eii cookie
  if (!Cookies.get("jwt_eii")) {
    // Redirect users that are logged out to the home page
    return <Navigate to="/LogIn" />;
  }

  return children;
};

export default ProtectedRouteAgainsLoggedOutUsers;
