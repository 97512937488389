import React, { useEffect } from "react";
import { validateSelectInput } from "./FormFieldValidation";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "./FormField.css";
import Throbber from "./Throbber";

const FormFieldSelect = ({
  fieldTitle,
  inPlaceholder,
  isRequired = false,
  activateRequired = false,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  inOptionsArray = undefined,
  directInOptionsArray = undefined,
  value = "",
  loading = false,
  allowTyping = false,
}) => {
  // Custom style for selection tab fields
  const btnGreenColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--btn_green_color")
    .trim();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? btnGreenColor : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${btnGreenColor}` : null,
      borderWidth: state.isFocused ? provided.borderWidth : 0,
      "&:hover": {
        borderColor: state.isFocused ? btnGreenColor : provided.borderColor,
      },
    }),
  };

  const forceValidate = () => {
    const isValid = validateSelectInput(
      value ? value.value : "",
      isRequired,
      isVisible
    );
    onFieldChange({
      value: value,
      isValid: isValid,
      isRequired,
    });
  };

  useEffect(() => {
    forceValidate();
  }, [value]);

  const handleSelectChange = (selectedOption) => {
    onFieldChange({
      value: selectedOption,
      isValid: validateSelectInput(selectedOption.value, isRequired, isVisible),
      isRequired,
    });
  };

  if (!isVisible) {
    return null;
  }

  const inputClass =
    activateRequired && !validateSelectInput(value.value, isRequired, isVisible)
      ? "formfield-input-wrapper required"
      : "formfield-input-wrapper";

  const getInArrayOptions = () => {
    if (directInOptionsArray) {
      return directInOptionsArray;
    } else {
      return inOptionsArray.map((option, _) => ({
        value: option,
        label: option,
      }));
    }
  };

  // Set title-blocked class
  let title_blocked_class = "";
  if (!fieldEnabled) title_blocked_class = "title-blocked";

  // Decide whether to use Select or CreatableSelect
  const SelectComponent = allowTyping ? CreatableSelect : Select;

  // Create another value to display placeholder
  let setValue;
  if (value.value === "") {
    setValue = "";
  } else {
    setValue = value;
  }

  return (
    <>
      {fieldTitle && (
        <p className={`formfield-p ${title_blocked_class}`}>
          {fieldTitle}
          {isRequired && fieldEnabled && (
            <span className="required-asterisk">*</span>
          )}
          {!isRequired && fieldEnabled && <span> (optional)</span>}
        </p>
      )}
      <div className={""}>
        {loading ? (
          <Throbber justify="left" className={"loading-field"} />
        ) : (
          <SelectComponent
            options={getInArrayOptions()}
            value={setValue}
            isDisabled={!fieldEnabled}
            onChange={handleSelectChange}
            placeholder={inPlaceholder}
            className={inputClass}
            classNamePrefix="select"
            styles={customStyles}
          />
        )}
      </div>
    </>
  );
};

export default FormFieldSelect;
