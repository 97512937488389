import React, { useEffect, useState } from "react";
import EIIButton from "../global_utils/EIIButton";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
} from "../global_utils/FormFieldValidation";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignUpForm1.css";
import nfq from "../../assets/data/NFQualifications.json";
import monthsList from "../../assets/data/months.json";

const SignUpForm1PageStudies = ({
  onClickNextHandler,
  onClickBackHandler,
  formData,
}) => {
  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState("");

  // State for buttonBig
  const [buttonBig, setButtonBig] = useState(window.innerWidth > 768);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [isEngineer, setIsEngineer] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editIsEngineer, setEditIsEngineer] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyLevel, setStudyLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyLevel, setEditStudyLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [degreeTitle, setDegreeTitle] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editDegreeTitle, setEditDegreeTitle] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyCenter, setStudyCenter] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyCenter, setEditStudyCenter] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyStartingMonth, setStudyStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyStartingMonth, setEditStudyStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyStartingYear, setStudyStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyStartingYear, setEditStudyStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyEndingMonth, setStudyEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyEndingMonth, setEditStudyEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyEndingYear, setStudyEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyEndingYear, setEditStudyEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });

  // User data is updated
  useEffect(() => {
    if (formData) {
      transformAndSetUserData(formData);
    }
  }, [formData]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      isEngineer: {
        value: uData.isEngineer ? uData.isEngineer.value : "",
        isValid: editIsEngineer.isValid,
        isRequired: editIsEngineer.isRequired,
      },
      studyLevel: {
        value: {
          value: uData.studyLevel ? uData.studyLevel.value.value : "",
          label: uData.studyLevel ? uData.studyLevel.value.value : "",
        },
        isValid: editStudyLevel.isValid,
        isRequired: editStudyLevel.isRequired,
      },
      degreeTitle: {
        value: uData.degreeTitle ? uData.degreeTitle.value : "",
        isValid: editDegreeTitle.isValid,
        isRequired: editDegreeTitle.isRequired,
      },
      studyCenter: {
        value: uData.studyCenter ? uData.studyCenter.value : "",
        isValid: editStudyCenter.isValid,
        isRequired: editStudyCenter.isRequired,
      },
      studyStartingMonth: {
        value: {
          value: uData.studyStartingMonth
            ? uData.studyStartingMonth.value.value
            : "",
          label: uData.studyStartingMonth
            ? uData.studyStartingMonth.value.value
            : "",
        },
        isValid: editStudyStartingMonth.isValid,
        isRequired: editStudyStartingMonth.isRequired,
      },
      studyStartingYear: {
        value: uData.studyStartingYear ? uData.studyStartingYear.value : "",
        isValid: editStudyStartingYear.isValid,
        isRequired: editStudyStartingYear.isRequired,
      },
      studyEndingMonth: {
        value: {
          value: uData.studyEndingMonth
            ? uData.studyEndingMonth.value.value
            : "",
          label: uData.studyEndingMonth
            ? uData.studyEndingMonth.value.value
            : "",
        },
        isValid: editStudyEndingMonth.isValid,
        isRequired: editStudyEndingMonth.isRequired,
      },
      studyEndingYear: {
        value: uData.studyEndingYear ? uData.studyEndingYear.value : "",
        isValid: editStudyEndingYear.isValid,
        isRequired: editStudyEndingYear.isRequired,
      },
    };

    // Initialize isEngineer
    setIsEngineer(newFormData.isEngineer);
    setEditIsEngineer(newFormData.isEngineer);
    // Initialize studyLevel
    setStudyLevel(newFormData.studyLevel);
    setEditStudyLevel(newFormData.studyLevel);
    // Initialize degreeTitle
    setDegreeTitle(newFormData.degreeTitle);
    setEditDegreeTitle(newFormData.degreeTitle);
    // Initialize studyCenter
    setStudyCenter(newFormData.studyCenter);
    setEditStudyCenter(newFormData.studyCenter);
    // Initialize studyStartingMonth
    setStudyStartingMonth(newFormData.studyStartingMonth);
    setEditStudyStartingMonth(newFormData.studyStartingMonth);
    // Initialize studyStartingYear
    setStudyStartingYear(newFormData.studyStartingYear);
    setEditStudyStartingYear(newFormData.studyStartingYear);
    // Initialize studyStartingMonth
    setStudyEndingMonth(newFormData.studyEndingMonth);
    setEditStudyEndingMonth(newFormData.studyEndingMonth);
    // Initialize studyEndingYear
    setStudyEndingYear(newFormData.studyEndingYear);
    setEditStudyEndingYear(newFormData.studyEndingYear);
  };

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      isEngineer: editIsEngineer,
      studyLevel: editStudyLevel,
      degreeTitle: editDegreeTitle,
      studyCenter: editStudyCenter,
      studyStartingMonth: editStudyStartingMonth,
      studyStartingYear: editStudyStartingYear,
      studyEndingMonth: editStudyEndingMonth,
      studyEndingYear: editStudyEndingYear,
    });
  }, [
    editIsEngineer,
    editStudyLevel,
    editDegreeTitle,
    editStudyCenter,
    editStudyStartingMonth,
    editStudyStartingYear,
    editStudyEndingMonth,
    editStudyEndingYear,
  ]);

  // useEffect hook to resize the screen
  useEffect(() => {
    const handleResize = () => {
      setButtonBig(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Routine to return the month number
  const getMonthNumber = (monthName) => {
    // Finding the index of the month in the array
    const monthIndex = monthsList.indexOf(monthName);

    // If the month is found, return index + 1, else return 0
    return monthIndex >= 0 ? monthIndex + 1 : 0;
  };

  // Routine to add validation conditions
  const validateForm = () => {
    // Current year
    const currentYear = new Date().getFullYear();

    // Check if starting year is prior to ending year
    const localIsEngineer = editIsEngineer.value;
    const localStudyStartingMonth = editStudyStartingMonth.value.value;
    const localStudyStartingYear = editStudyStartingYear.value;
    const localStudyEndingMonth = editStudyEndingMonth.value.value;
    const localStudyEndingYear = editStudyEndingYear.value;

    if (
      localIsEngineer === "Yes" &&
      localStudyStartingMonth !== "" &&
      localStudyStartingYear !== "" &&
      localStudyEndingMonth !== "" &&
      localStudyEndingYear !== ""
    ) {
      const startYear = parseInt(localStudyStartingYear);
      const endYear = parseInt(localStudyEndingYear);
      const startMonth = getMonthNumber(localStudyStartingMonth);
      const endMonth = getMonthNumber(localStudyEndingMonth);

      // Check if the starting year is greater than the ending year
      if (startYear > endYear) {
        setErrorMessage("Starting year cannot be later than ending year");
        return false;
      }

      // If the years are the same, check if the starting month is greater than the ending month
      if (startYear === endYear && startMonth > endMonth) {
        setErrorMessage(
          "Starting month cannot be later than ending month in the same year"
        );
        return false;
      }
    }

    // Invalid starting month
    if (localIsEngineer === "Yes" && localStudyStartingMonth !== "") {
      const startMonth = getMonthNumber(localStudyStartingMonth);
      if (startMonth > 12) {
        setErrorMessage("Invalid starting month");
        return false;
      }
    }

    // Invalid ending month
    if (localIsEngineer === "Yes" && localStudyEndingMonth !== "") {
      const endMonth = getMonthNumber(localStudyEndingMonth);
      if (endMonth > 12) {
        setErrorMessage("Invalid ending month");
        return false;
      }
    }

    // Check if the ending year is in the future
    if (localIsEngineer !== "No" && localStudyStartingYear !== "") {
      const startYear = parseInt(localStudyStartingYear);
      if (startYear > currentYear) {
        setErrorMessage("Ending year cannot be in the future");
        return false;
      }
    }

    // Check if the ending year is in the future
    if (localIsEngineer === "Yes" && localStudyEndingYear !== "") {
      const endYear = parseInt(localStudyEndingYear);
      if (endYear > currentYear) {
        setErrorMessage("Ending year cannot be in the future");
        return false;
      }
    }

    setErrorMessage(""); // Clear the error message if validation passes
    return true;
  };

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      isEngineer: {
        ...editIsEngineer,
        isValid: validateRadioButtonInput(
          editIsEngineer.value,
          editIsEngineer.isRequired,
          true // isVisible
        ),
      },
      studyLevel: {
        ...editStudyLevel,
        isValid: validateSelectInput(
          editStudyLevel.value.value,
          editStudyLevel.isRequired,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      degreeTitle: {
        ...editDegreeTitle,
        isValid: validateTextInput(
          editDegreeTitle.value,
          editDegreeTitle.isRequired,
          200, // textInputMaxLength,
          textInputMinLength,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      studyCenter: {
        ...editStudyCenter,
        isValid: validateTextInput(
          editStudyCenter.value,
          editStudyCenter.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      studyStartingMonth: {
        ...editStudyStartingMonth,
        isValid: validateSelectInput(
          editStudyStartingMonth.value.value,
          editStudyStartingMonth.isRequired,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      studyStartingYear: {
        ...editStudyStartingYear,
        isValid: validateTextInput(
          editStudyStartingYear.value,
          editStudyStartingYear.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      studyEndingMonth: {
        ...editStudyEndingMonth,
        isValid: validateSelectInput(
          editStudyEndingMonth.value.value,
          editStudyEndingMonth.isRequired,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
      studyEndingYear: {
        ...editStudyEndingYear,
        isValid: validateTextInput(
          editStudyEndingYear.value,
          editStudyEndingYear.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editIsEngineer.value === "Yes" ||
            editIsEngineer.value === "No, but I'm studying engineering" // isVisible
        ),
      },
    };
  };

  // Hnadler routine for "NEXT" button
  const nextButtonHandler = () => {
    const copyFieldsData = JSON.parse(JSON.stringify(fieldsData));

    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    if (allRequiredFieldsValid && validateForm()) {
      // Save the edited values
      setIsEngineer(editIsEngineer);
      setStudyLevel(editStudyLevel);
      setDegreeTitle(editDegreeTitle);
      setStudyCenter(editStudyCenter);
      setStudyStartingMonth(editStudyStartingMonth);
      setStudyStartingYear(editStudyStartingYear);
      setStudyEndingMonth(editStudyEndingMonth);
      setStudyEndingYear(editStudyEndingYear);

      // Only proceed if all required fields are valid
      onClickNextHandler(copyFieldsData);
    } else {
      setActivateRequiredFields(true);
    }
  };

  // BACK button handler
  const backButtonHandler = () => {
    // Revert the edit fields to the saved fields
    setEditIsEngineer(isEngineer);
    setEditStudyLevel(studyLevel);
    setEditDegreeTitle(degreeTitle);
    setEditStudyCenter(studyCenter);
    setEditStudyStartingMonth(studyStartingMonth);
    setEditStudyStartingYear(studyStartingYear);
    setEditStudyEndingMonth(studyEndingMonth);
    setEditStudyEndingYear(studyEndingYear);

    // Call onClickBackHandler on the upper component
    onClickBackHandler(fieldsData);
  };

  // Routine to handle isEngineer
  const handleIsEngineerChange = (value) => {
    setEditIsEngineer(value);
  };

  // Routine to handle studyLevel
  const handleStudyLevelChange = (value) => {
    setEditStudyLevel(value);
  };

  // Routine to handle degreeTitle
  const handleDegreeTitleChange = (value) => {
    setEditDegreeTitle(value);
  };

  // Routine to handle studyCenter
  const handleStudyCenterChange = (value) => {
    setEditStudyCenter(value);
  };

  // Routine to handle studyStartingMonth
  const handleStudyStartingMonthChange = (value) => {
    setEditStudyStartingMonth(value);
  };

  // Routine to handle studyStartingYear
  const handleStudyStartingYearChange = (value) => {
    setEditStudyStartingYear(value);
  };

  // Routine to handle studyEndingMonth
  const handleStudyEndingMonthChange = (value) => {
    setEditStudyEndingMonth(value);
  };

  // Routine to handle studyEndingYear
  const handleStudyEndingYearChange = (value) => {
    setEditStudyEndingYear(value);
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Studies</h2>
            <form className="signup-form">
              <FormFieldRadioButton
                fieldTitle="Are you an engineer?"
                inOptionsArray={[
                  "Yes",
                  "No",
                  "No, but I'm studying engineering",
                ]}
                isRequired={true}
                radioButtonsInSingleRow={false}
                activateRequired={activateRequiredFields}
                onFieldChange={handleIsEngineerChange}
                value={editIsEngineer.value}
              />
              <FormFieldSelect
                fieldTitle={"Study Level"}
                inPlaceholder={"Enter your study level"}
                inOptionsArray={nfq.Qualifications}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStudyLevelChange}
                value={editStudyLevel.value}
                isVisible={
                  editIsEngineer.value === "Yes" ||
                  editIsEngineer.value === "No, but I'm studying engineering"
                }
              />
              <FormFieldText
                fieldTitle={"Degree title"}
                inPlaceholder={
                  "Enter your degree title (e.g. Software Engineering)"
                }
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleDegreeTitleChange}
                value={editDegreeTitle.value}
                textMaxLength={200}
                isVisible={
                  editIsEngineer.value === "Yes" ||
                  editIsEngineer.value === "No, but I'm studying engineering"
                }
              />
              <FormFieldText
                fieldTitle={"University / Education Center"}
                inPlaceholder={"Enter your University / Education Center"}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStudyCenterChange}
                value={editStudyCenter.value}
                isVisible={
                  editIsEngineer.value === "Yes" ||
                  editIsEngineer.value === "No, but I'm studying engineering"
                }
              />
              <div className="row">
                <div className={"col-md-6"}>
                  <FormFieldSelect
                    fieldTitle={"Starting month"}
                    inPlaceholder={"Enter your starting month"}
                    inOptionsArray={monthsList}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleStudyStartingMonthChange}
                    value={editStudyStartingMonth.value}
                    isVisible={
                      editIsEngineer.value === "Yes" ||
                      editIsEngineer.value ===
                        "No, but I'm studying engineering"
                    }
                  />
                </div>
                <div className={"col-md-6"}>
                  <FormFieldText
                    fieldTitle={"Starting Year"}
                    inPlaceholder={"Enter the starting year"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleStudyStartingYearChange}
                    value={editStudyStartingYear.value}
                    allowOnlyIntegers
                    textMaxLength={4}
                    isVisible={
                      editIsEngineer.value === "Yes" ||
                      editIsEngineer.value ===
                        "No, but I'm studying engineering"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-md-6 ${
                    editIsEngineer.value === "Yes" ? "" : "d-none"
                  }`}
                >
                  <FormFieldSelect
                    fieldTitle={"Ending month"}
                    inPlaceholder={"Enter your Ending month"}
                    inOptionsArray={monthsList}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleStudyEndingMonthChange}
                    value={editStudyEndingMonth.value}
                    isVisible={
                      editIsEngineer.value === "Yes" ||
                      editIsEngineer.value ===
                        "No, but I'm studying engineering"
                    }
                  />
                </div>
                <div
                  className={`col-md-6 ${
                    editIsEngineer.value === "Yes" ? "" : "d-none"
                  }`}
                >
                  <FormFieldText
                    fieldTitle={"Ending Year"}
                    inPlaceholder={"Enter the Ending year"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleStudyEndingYearChange}
                    value={editStudyEndingYear.value}
                    allowOnlyIntegers
                    textMaxLength={4}
                    isVisible={
                      editIsEngineer.value === "Yes" ||
                      editIsEngineer.value ===
                        "No, but I'm studying engineering"
                    }
                  />
                </div>
              </div>
            </form>
            {errorMessage && (
              <div className="row">
                <div
                  className="col text-center"
                  style={{ color: "red", marginTop: "20px" }}
                >
                  {errorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row right-bottom-row">
          <div className="col-md-6 right-bottom-col-left">
            <EIIButton
              text="Back"
              color="green"
              btnBig={buttonBig}
              transparent
              onClickHandler={backButtonHandler}
            />
          </div>
          <div className="col-md-6 right-bottom-col-right">
            <EIIButton
              text="Next"
              color="green"
              btnBig={buttonBig}
              onClickHandler={nextButtonHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm1PageStudies;
