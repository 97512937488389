import React from "react";
import TitleTextLeft from "../global_utils/TitleTextLeft";
import PostParagraph from "../page_home/PostParagraph";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="content-section">
        <TitleTextLeft
          title="Privacy Policy"
          title_upper_space={true}
          mainTitle={true}
          content_after_title={
            <>
              <PostParagraph
                text='This privacy policy explains how LENO Engineering Limited,
                referred to as "Our Company", uses the personal data collected
                from you when you use our website, www.engineersinireland.com
                ("EII Site" from now on).'
              />
              <PostParagraph text="Topics:" />
              <ul className="content-p">
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How we use and share your data</li>
                <li>Explicit Consent for Data Sharing</li>
                <li>Legal Basis for Processing Data</li>
                <li>How do we store your data?</li>
                <li>Data Retention Period</li>
                <li>Marketing</li>
                <li>What are your data protection rights?</li>
                <li>User Rights</li>
                <li>Children’s Privacy</li>
                <li>What are cookies?</li>
                <li>How do we use cookies?</li>
                <li>What types of cookies do we use?</li>
                <li>How to manage your cookies</li>
                <li>Third-Party Service Providers</li>
                <li>Privacy policies of other websites</li>
                <li>Procedure for Data Breach Notification</li>
                <li>Changes to our privacy policy</li>
                <li>Regular Updates and Reviews</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
              </ul>
            </>
          }
        />

        <TitleTextLeft
          title="What data do we collect?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Our Company collects the following data:" />
              <ul className="content-p">
                <li>
                  Personal identification information (Name, email address,
                  phone number, address, citizenship, etc.)
                </li>
                <li>Professional Experience</li>
                <li>General studies</li>
                <li>Language studies</li>
                <li>Skills</li>
                <li>Working status and preferences</li>
                <li>Company name and address</li>
                <li>Job offers</li>
                <li>IP address and cookies for Google Analytics</li>
                <li>
                  Metrics about your profile (times accessed, matches with
                  existing job offers, etc.)
                </li>
                <li>
                  Metrics of your actions performed within EII Site (job offers
                  accessed, etc.)
                </li>
              </ul>
            </>
          }
        />

        <TitleTextLeft
          title="How do we collect your data?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="You directly provide Our Company with most of the data we
                collect. We collect data and process data when you:"
              />
              <ul className="content-p">
                <li>Register online.</li>
                <li>Voluntarily upload your profile.</li>
                <li>Voluntarily upload job offers.</li>
                <li>
                  Voluntarily complete a customer survey or provide feedback on
                  any of our message boards or via email.
                </li>
                <li>Use or view our website via your browser’s cookies.</li>
              </ul>
              <PostParagraph
                text="Our Company may also receive your data indirectly from the
                following sources:"
              />
              <ul className="content-p">
                <li>Google Analytics</li>
              </ul>
            </>
          }
        />

        <TitleTextLeft
          title="How will we use your data?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Our Company collects your data so that we can:" />
              <ul className="content-p">
                <li>Email you with job offers based on your searches.</li>
                <li>Email you with the new updates on EII Site.</li>
                <li>Display metrics in your dashboard.</li>
                <li>
                  Provide you with the most suitable offers/candidates on EII
                  Site.
                </li>
                <li>Analyse the more accessed pages in EII Site.</li>
              </ul>
              <PostParagraph
                text="When Our Company processes your order, it may send your data to,
                and also use the resulting information from, credit reference
                agencies to prevent fraudulent purchases."
              />
            </>
          }
        />

        <TitleTextLeft
          title="How we use and share your data"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="In addition to the purposes mentioned earlier, Our Company also
                uses your data in the following ways:"
              />
              <ul className="content-p">
                <li>
                  To provide your information to recruiters and recruitment
                  agencies for the purpose of job placements and employment
                  opportunities.
                </li>
                <li>
                  To facilitate communication between you and potential
                  employers.
                </li>
                <li>
                  To assist recruiters and agencies in finding suitable
                  candidates for their job openings.
                </li>
              </ul>
              <PostParagraph
                text="We commit to handling your data responsibly and ensuring its
                confidentiality when sharing it with third parties for these
                purposes. We also ensure that all third parties comply with data
                protection laws and maintain the integrity and security of your
                data."
              />
              <PostParagraph
                text="Please note that by using our website and services, you consent
                to the sharing of your data for these purposes. You have the
                right to withdraw your consent at any time. If you do not wish
                for your data to be shared with recruiters and agencies, please
                contact us at the details provided below."
              />
            </>
          }
        />

        <TitleTextLeft
          title="Explicit Consent for Data Sharing"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="We value your privacy and are committed to transparently
                communicating how your data is used and shared. By using our
                services, you provide explicit consent for us to share your
                personal and professional information, including but not limited
                to your name, contact details, professional experiences,
                studies, language skills, and preferences with recruiters and
                recruitment agencies for employment opportunities."
              />
              <PostParagraph
                text="You have the right to withdraw your consent at any time. If you
                choose to withdraw your consent, please contact us using the
                details provided below."
              />
            </>
          }
        />

        <TitleTextLeft
          title="Legal Basis for Processing Data"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="The legal basis for processing your data includes providing you
                with the services you have requested, complying with our legal
                obligations, protecting your vital interests, and for our
                legitimate interests in operating and improving our services,
                unless those interests are overridden by your rights and
                interests."
              />
            </>
          }
        />

        <TitleTextLeft
          title="How do we store your data?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="Our Company securely stores your data in our servers and we
                adopt appropriate data collection, storage, processing practices
                and security measures to protect against unauthorised access,
                alteration, disclosure or destruction of your personal
                information, data stored in EII Site."
              />
              <PostParagraph
                text="Our Company will keep your data while your profile is active.
                Your data will be deleted in a period of 90 days after the
                deletion of your profile."
              />
            </>
          }
        />

        <TitleTextLeft
          title="Data Retention Period"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="Your data is retained on our servers as long as your profile
                remains active. Should you choose to delete your profile, your
                data will be removed immediately from our systems, aligning with
                our commitment to your privacy and data protection rights."
              />
            </>
          }
        />

        <TitleTextLeft
          title="Marketing"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="" />
              <PostParagraph
                text="Our Company would like to send you information about products
                and services of ours that we think you might like."
              />
              <PostParagraph
                text="If you have agreed to receive marketing, you may always opt out
                at a later date."
              />
              <PostParagraph
                text="You have the right at any time to stop Our Company from
                contacting you for marketing purposes or giving your data to
                other members of the Our Company Group."
              />
              {/*<PostParagraph text="If you no longer wish to be contacted for marketing purposes, please click here." />*/}
            </>
          }
        />

        <TitleTextLeft
          title="What are your data protection rights?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="Our Company would like to make sure you are fully aware of all
                of your data protection rights. Every user is entitled to the
                following:"
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to access</strong> – You have the right to
                    request Our Company for copies of your personal data. We may
                    charge you a small fee for this service.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to rectification</strong> – You have the
                    right to request that Our Company correct any information
                    you believe is inaccurate. You also have the right to
                    request Our Company to complete the information you believe
                    is incomplete.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to erasure</strong> – You have the right
                    to request that Our Company erase your personal data, under
                    certain conditions.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to restrict processing</strong> – You have
                    the right to request that Our Company restrict the
                    processing of your personal data, under certain conditions.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to object to processing</strong> – You
                    have the right to object to Our Company’s processing of your
                    personal data, under certain conditions.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    <strong>The right to data portability</strong> – You have
                    the right to request that Our Company transfer the data that
                    we have collected to another organization, or directly to
                    you, under certain conditions.
                  </p>
                }
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us at our email: engineersinireland@lenoeng.ie.
                  </p>
                }
              />
            </>
          }
        />

        <TitleTextLeft
          title="User Rights"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                You have several rights regarding your personal data, including
                the right to access, rectify, erase, restrict processing, object
                to processing, and data portability. To exercise any of these
                rights, please contact us.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="Children’s Privacy"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Our services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                children. If we become aware that we have inadvertently
                collected personal information from a child, we will take steps
                to promptly delete it.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="What are cookies?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Cookies are text files placed on your computer to collect
                standard Internet log information and visitor behavior
                information. When you visit our websites, we may collect
                information from you automatically through cookies or similar
                technology
              "
              />
              <PostParagraph
                text={
                  <p className="content-p">
                    For further information, click{" "}
                    <a className="colored-a" href="https://allaboutcookies.org">
                      here
                    </a>
                    .
                  </p>
                }
              />
            </>
          }
        />

        <TitleTextLeft
          title="How do we use cookies?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Our Company uses cookies in a range of ways to improve your
                experience on our website, including:
              "
              />
              <ul className="content-p">
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
              </ul>
              <PostParagraph
                text="
                You can set your browser not to accept cookies, and the above
                website tells you how to remove cookies from your browser.
                However, in a few cases, some of our website features may not
                function as a result.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="What types of cookies do we use?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                There are a number of different types of cookies, however, our
                website uses:
              "
              />
              <ul className="content-p">
                <li>
                  Functionality – Our Company uses these cookies so that we
                  recognize you on our website and remember your previously
                  selected preferences. These could include what language you
                  prefer and location you are in. A mix of first-party and
                  third-party cookies are used.
                </li>
                <li>
                  Advertising – Our Company uses these cookies to collect
                  information about your visit to our website, the content you
                  viewed, the links you followed and information about your
                  browser, device, and your IP address. Our Company sometimes
                  shares some limited aspects of this data with third parties
                  for advertising purposes. We may also share online data
                  collected through cookies with our advertising partners. This
                  means that when you visit another website, you may be shown
                  advertising based on your browsing patterns on our website.
                </li>
              </ul>
            </>
          }
        />

        <TitleTextLeft
          title="How do we manage your cookies?"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Our Company is committed to transparently managing cookies to
                enhance your experience on the EII Site. Here's how we handle
                cookies:
              "
              />
              <ul className="content-p">
                <li>
                  <strong>Cookie Consent:</strong> When you first visit the EII
                  Site, you will be prompted to accept or decline the use of
                  cookies. This consent enables us to use cookies that enhance
                  your experience and gather analytics data.
                </li>
                <li>
                  <strong>Customization of Cookie Settings:</strong> You have
                  the option to customize your cookie preferences. You can
                  choose to disable certain types of cookies that are not
                  essential for the website’s functionality.
                </li>
                <li>
                  <strong>Cookie Preferences:</strong> At any time, you can
                  change your cookie preferences by accessing the cookie
                  settings on our website. Here, you can choose to enable or
                  disable different categories of cookies according to your
                  preferences.
                </li>
                <li>
                  <strong>Automatic Session Termination:</strong> Session
                  cookies are automatically terminated when you close your
                  browser. These are used to remember your activities during a
                  single session on our website.
                </li>
                <li>
                  <strong>Regular Review:</strong> We regularly review the
                  cookies we use to ensure that only necessary cookies are used
                  and that your privacy is respected.
                </li>
              </ul>
              <PostParagraph
                text={
                  <p className="content-p">
                    Please note that disabling cookies might affect the
                    functionality and features of the EII Site. For more
                    information on how to manage and delete cookies, you can
                    visit&nbsp;
                    <a className="colored-a" href="https://allaboutcookies.org">
                      allaboutcookies.org
                    </a>
                    .
                  </p>
                }
              />
            </>
          }
        />

        <TitleTextLeft
          title="Third-Party Service Providers"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                We may use third-party service providers to process your data
                for various purposes, including data analytics, marketing, and
                service optimization. These providers are carefully selected and
                obligated to comply with our privacy standards and data
                protection laws.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="Privacy policies of other websites"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                The Our Company website contains links to other websites. Our
                privacy policy applies only to our website, so if you click on a
                link to another website, you should read their privacy policy.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="Procedure for Data Breach Notification"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                In the unlikely event of a data breach, we will promptly notify
                you and the appropriate authorities if your personal data is
                compromised. We are committed to taking all necessary steps to
                mitigate any potential harm and prevent future breaches.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="Changes to our privacy policy"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Our Company keeps its privacy policy under regular review and
                places any updates on this web page. This privacy policy was
                last updated on 6 March 2023.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="Regular Updates and Reviews"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Our privacy policy will be regularly reviewed and updated as
                necessary. We will inform you of any significant changes, but we
                encourage you to review this policy periodically.
              "
              />
            </>
          }
        />

        <TitleTextLeft
          title="How to contact us"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                If you have any questions about Our Company’s privacy policy,
                the data we hold on you, or you would like to exercise one of
                your data protection rights, please do not hesitate to contact
                us.
              "
              />
              <PostParagraph text="Email us at: engineersinireland@lenoeng.ie" />
            </>
          }
        />

        <TitleTextLeft
          title="How to contact the appropriate authorities"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph
                text="
                Should you wish to report a complaint or if you feel that Our
                Company has not addressed your concern in a satisfactory manner,
                you may contact the Information Commissioner’s Office.
              "
              />
              <PostParagraph text="Email: info@oic.ie" />
              <PostParagraph text="Phone: +35316395689" />
              <PostParagraph text="Address: 6 Earlsfort Terrace, Dublin 2, D02 W773" />
            </>
          }
        />
      </div>
    </>
  );
};

export default PrivacyPolicy;
