import React from "react";
import JoinCommunityTitleImg from "../images/JoinCommunitySection/JoinCommunityTitle.webp";
import FacebookGroupImg from "../images/JoinCommunitySection/FacebookAIImageEII2.webp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ForXSubSection from "./ForXSubSection";

const JoinCommunitySection = () => {
  // Handler for Join Community button
  const JoinCommunityButtonHandler = () => {
    // Redirect to the Facebook community link when the button is clicked
    window.open("https://www.facebook.com/groups/988309832050393/", "_blank");
  };

  return (
    <section id="JoinCommunity">
      <div className="content-section">
        <Row className="for-rec-reverse-colum-sm">
          <Col sm={12} md={12} lg={6}>
            <ForXSubSection
              for_text={
                <div
                  onClick={JoinCommunityButtonHandler}
                  className="clickable-image for-eng-titleimg"
                >
                  <img
                    src={JoinCommunityTitleImg}
                    className="for-eng-titleimg"
                    alt="Join our community"
                  />
                </div>
              }
              prim_title="Join our Engineers in Ireland community"
              content="Are you an engineer planning to move to Ireland? Join our Facebook community to get valuable insights and support. Here, you can ask questions, share experiences, and receive tips about life and work in Ireland. Our group is a hub for engineers like you, offering a friendly space to learn and connect. Whether it's about finding the best opportunities or understanding Irish culture, our community is here to help. We welcome engineers from all backgrounds to join our supportive and informative group."
              justifyContent={true}
              button_text="Join Community"
              buttonOnClickHandler={JoinCommunityButtonHandler} // Use the new handler for the button
              color="blue"
            />
          </Col>
          <Col sm={12} md={12} lg={6} className="for-eng-img-col">
            <div
              onClick={JoinCommunityButtonHandler}
              className="clickable-image joincom-titleimg"
            >
              <img
                className="for-eng-img joincom-titleimg"
                src={FacebookGroupImg}
                alt="Join our community"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default JoinCommunitySection;
