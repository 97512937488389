import React, { useState, useEffect }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const DemEngCard = ({icon, job_title, salary_range}) => {
    const [isSmartphone, setIsSmartphone] = useState("fa-4x dem-eng-icon");

    /* Check if is tablet */
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 481) {
            setIsSmartphone("fa-3x dem-eng-icon");
          } else {
            setIsSmartphone("fa-4x dem-eng-icon");
          }
        };
    
        // Call handleResize initially and on window resize
        handleResize();
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <>
            <div className="dem-eng-card">
                <FontAwesomeIcon icon={icon} className={isSmartphone} />
                <div className="dem-eng-card-title">
                    <h4>{job_title}</h4>
                </div>
                <p className="content-p">{salary_range}</p>
            </div>
        </>
    )
}

export default DemEngCard