import React from "react";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import "./EIIButton.css";

const EIIButton = ({
  color,
  text,
  customClass,
  onClickHandler,
  btnBig = true,
  transparent = false,
  icon,
  iconPosition = "center",
  fullWidth = false,
  width,
  height,
  children,
  disabled,
  id,
}) => {
  let localClass = "";

  // Set the different color classes
  if (color === "blue") {
    localClass = transparent
      ? "custom-btn-blue-transparent"
      : "custom-btn-blue";
  } else if (color === "green") {
    localClass = transparent
      ? "custom-btn-green-transparent"
      : "custom-btn-green";
  } else if (color === "black") {
    localClass = transparent
      ? "custom-btn-black-transparent"
      : "custom-btn-black";
  } else if (color === "gray") {
    localClass = transparent
      ? "custom-btn-gray-transparent"
      : "custom-btn-gray";
  }

  // Set the button as big
  if (btnBig) {
    localClass += " btn-big";
  } else if (!icon) {
    localClass += " btn-small";
  } else {
    localClass += " btn-icon-size";
  }

  // Set the button as full-width of parent
  if (fullWidth) {
    localClass += " btn-full-width";
  }

  // Determine the icon position class
  const iconClass = classNames({
    "btn-icon-left": iconPosition === "left",
    "btn-icon-right": iconPosition === "right",
    "btn-icon-center": iconPosition === "center",
  });

  // Combine the classes using classNames library
  const buttonClass = classNames(localClass, customClass, iconClass);

  const renderIcon = () => {
    if (icon) {
      return <span className="btn-icon">{icon}</span>;
    }
    return null;
  };

  const throbberStyle = transparent
    ? {
        border: `4px solid ${
          transparent ? "rgba(128, 128, 128, 0.3)" : "rgba(255, 255, 255, 0.3)"
        }`, // Gray color for transparent, white otherwise
        borderTop: `4px solid ${transparent ? "#808080" : "#fff"}`, // Gray color for transparent, white otherwise
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        animation: "spin 1s linear infinite",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        margin: "auto", // This will center the throbber
      }
    : {
        border: "4px solid rgba(255, 255, 255, 0.3)", // Default border for non-transparent button
        borderTop: "4px solid #fff", // Default top border for non-transparent button
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        animation: "spin 1s linear infinite",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        margin: "auto", // This will center the throbber
      };

  // Inline style for width/height
  const buttonStyle = {};
  if (width) {
    buttonStyle.width = width; // Set width if provided
  }
  if (height) {
    buttonStyle.height = height; // Set height if provided
  }

  return (
    <Button
      className={buttonClass}
      onClick={onClickHandler}
      disabled={disabled}
      id={id}
      style={buttonStyle}
    >
      <div className="btn-container" style={{ position: "relative" }}>
        {disabled ? (
          <div className="throbber" style={throbberStyle}></div>
        ) : (
          <>
            {iconPosition === "left" && renderIcon()}
            {iconPosition === "center" && icon ? (
              renderIcon()
            ) : (
              <>
                <span>{text || children}</span>
                {iconPosition === "right" && renderIcon()}
              </>
            )}
          </>
        )}
      </div>
    </Button>
  );
};

export default EIIButton;
