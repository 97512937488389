import React, { useEffect, useState } from "react";
import EIIButton from "../global_utils/EIIButton";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
} from "../global_utils/FormFieldValidation";
import TagPill from "../global_utils/TagPill";
import industries from "../../assets/data/industries.json";
import skillsObj from "../../assets/data/skills.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignUpForm1.css";
import monthsList from "../../assets/data/months.json";

const flattenObjectAndRemoveDuplicates = (
  obj,
  parentKey = "",
  separator = "_"
) => {
  let flatValues = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === "object" && !Array.isArray(value)) {
        flatValues = flatValues.concat(flattenObjectAndRemoveDuplicates(value));
      } else if (Array.isArray(value)) {
        flatValues = flatValues.concat(value);
      } else {
        flatValues.push(value);
      }
    }
  }

  // Remove duplicates from the flattened array
  const uniqueFlatValues = [...new Set(flatValues)];

  return uniqueFlatValues;
};

function filterSimilarStrings(stringList, comparedString) {
  if (!comparedString || typeof comparedString !== "string") {
    return [];
  }

  const lowerComparedString = comparedString.toLowerCase();
  const filteredList = stringList.filter((item) =>
    item.toLowerCase().includes(lowerComparedString)
  );
  return filteredList;
}

const SignUpForm1PageProfessionalExp = ({
  onClickNextHandler,
  onClickBackHandler,
  formData,
}) => {
  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState("");

  // State for buttonBig
  const [buttonBig, setButtonBig] = useState(window.innerWidth > 768);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [currentlyWorkingEng, setCurrentlyWorkingEng] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCurrentlyWorkingEng, setEditCurrentlyWorkingEng] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [prevExpEng, setPrevExpEng] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editPrevExpEng, setEditPrevExpEng] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [engPosition, setEngPosition] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEngPosition, setEditEngPosition] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [engIndustry, setEngIndustry] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEngIndustry, setEditEngIndustry] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [companyName, setCompanyName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCompanyName, setEditCompanyName] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [jobStartingMonth, setJobStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editJobStartingMonth, setEditJobStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [jobStartingYear, setJobStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editJobStartingYear, setEditJobStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [jobEndingMonth, setJobEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editJobEndingMonth, setEditJobEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [jobEndingYear, setJobEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editJobEndingYear, setEditJobEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [skillsFieldData, setSkillsFieldData] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editSkillsFieldData, setEditSkillsFieldData] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [skillsAddedList, setSkillsAddedList] = useState([]);
  const [skillsList, setSkillsList] = useState(
    filterSimilarStrings(
      flattenObjectAndRemoveDuplicates(skillsObj),
      skillsFieldData
    )
  );

  // User data is updated
  useEffect(() => {
    if (formData) {
      transformAndSetUserData(formData);
    }
  }, [formData]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      currentlyWorkingEng: {
        value: uData.currentlyWorkingEng ? uData.currentlyWorkingEng.value : "",
        isValid: editCurrentlyWorkingEng.isValid,
        isRequired: editCurrentlyWorkingEng.isRequired,
      },
      prevExpEng: {
        value: uData.prevExpEng ? uData.prevExpEng.value : "",
        isValid: editPrevExpEng.isValid,
        isRequired: editPrevExpEng.isRequired,
      },
      engPosition: {
        value: uData.engPosition ? uData.engPosition.value : "",
        isValid: editEngPosition.isValid,
        isRequired: editEngPosition.isRequired,
      },
      engIndustry: {
        value: {
          value: uData.engIndustry ? uData.engIndustry.value.value : "",
          label: uData.engIndustry ? uData.engIndustry.value.value : "",
        },
        isValid: editEngIndustry.isValid,
        isRequired: editEngIndustry.isRequired,
      },
      companyName: {
        value: uData.companyName ? uData.companyName.value : "",
        isValid: editCompanyName.isValid,
        isRequired: editCompanyName.isRequired,
      },
      jobStartingMonth: {
        value: {
          value: uData.jobStartingMonth
            ? uData.jobStartingMonth.value.value
            : "",
          label: uData.jobStartingMonth
            ? uData.jobStartingMonth.value.value
            : "",
        },
        isValid: editJobStartingMonth.isValid,
        isRequired: editJobStartingMonth.isRequired,
      },
      jobStartingYear: {
        value: uData.jobStartingYear ? uData.jobStartingYear.value : "",
        isValid: editJobStartingYear.isValid,
        isRequired: editJobStartingYear.isRequired,
      },
      jobEndingMonth: {
        value: {
          value: uData.jobEndingMonth ? uData.jobEndingMonth.value.value : "",
          label: uData.jobEndingMonth ? uData.jobEndingMonth.value.value : "",
        },
        isValid: editJobEndingMonth.isValid,
        isRequired: editJobEndingMonth.isRequired,
      },
      jobEndingYear: {
        value: uData.jobEndingYear ? uData.jobEndingYear.value : "",
        isValid: editJobEndingYear.isValid,
        isRequired: editJobEndingYear.isRequired,
      },
    };

    // Initialize currentlyWorkingEng
    setCurrentlyWorkingEng(newFormData.currentlyWorkingEng);
    setEditCurrentlyWorkingEng(newFormData.currentlyWorkingEng);
    // Initialize prevExpEng
    setPrevExpEng(newFormData.prevExpEng);
    setEditPrevExpEng(newFormData.prevExpEng);
    // Initialize engPosition
    setEngPosition(newFormData.engPosition);
    setEditEngPosition(newFormData.engPosition);
    // Initialize engIndustry
    setEngIndustry(newFormData.engIndustry);
    setEditEngIndustry(newFormData.engIndustry);
    // Initialize companyName
    setCompanyName(newFormData.companyName);
    setEditCompanyName(newFormData.companyName);
    // Initialize jobStartingMonth
    setJobStartingMonth(newFormData.jobStartingMonth);
    setEditJobStartingMonth(newFormData.jobStartingMonth);
    // Initialize jobStartingYear
    setJobStartingYear(newFormData.jobStartingYear);
    setEditJobStartingYear(newFormData.jobStartingYear);
    // Initialize jobEndingMonth
    setJobEndingMonth(newFormData.jobEndingMonth);
    setEditJobEndingMonth(newFormData.jobEndingMonth);
    // Initialize jobEndingYear
    setJobEndingYear(newFormData.jobEndingYear);
    setEditJobEndingYear(newFormData.jobEndingYear);
  };

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      currentlyWorkingEng: editCurrentlyWorkingEng,
      prevExpEng: editPrevExpEng,
      engPosition: editEngPosition,
      engIndustry: editEngIndustry,
      companyName: editCompanyName,
      jobStartingMonth: editJobStartingMonth,
      jobStartingYear: editJobStartingYear,
      jobEndingMonth: editJobEndingMonth,
      jobEndingYear: editJobEndingYear,
      skillsFieldData: editSkillsFieldData,
      skillsAddedList,
    });
  }, [
    editCurrentlyWorkingEng,
    editPrevExpEng,
    editEngPosition,
    editEngIndustry,
    editCompanyName,
    editJobStartingMonth,
    editJobStartingYear,
    editJobEndingMonth,
    editJobEndingYear,
    editSkillsFieldData,
    skillsAddedList,
  ]);

  // useEffect hook to resize the screen
  useEffect(() => {
    const handleResize = () => {
      setButtonBig(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // update skillsList based on skillsFieldData
  useEffect(() => {
    // Filter similar strings to the ones typed by the user
    const newSkillsList = filterSimilarStrings(
      flattenObjectAndRemoveDuplicates(skillsObj),
      skillsFieldData.value
    );

    // Write skillsList
    setSkillsList(newSkillsList);
  }, [skillsFieldData]);

  useEffect(() => {
    // Update skills Added when formData is updated
    if (formData.hasOwnProperty("skillsAddedList")) {
      setSkillsAddedList(formData.skillsAddedList);
    }
  }, []);

  // Routine to extract skills and sort them alphabetically
  const extractAndSortSkills = (skillsObj) => {
    let skList = [];

    const extractSkills = (obj) => {
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          skList = skList.concat(obj[key]);
        } else if (typeof obj[key] === "object") {
          extractSkills(obj[key]);
        }
      }
    };

    extractSkills(skillsObj);

    // Create a set from the list to remove duplicates and then convert it back to an array
    const uniqueSkills = Array.from(new Set(skList));

    // Sort the array alphabetically
    return uniqueSkills.sort();
  };

  // Routine to update skills into skillsAddedList
  const updateSkills = (data) => {
    // Check if the data is not empty and not already in the skillsAddedList
    if (data && data !== "" && !skillsAddedList.includes(data)) {
      setSkillsAddedList((prevSkills) => [...prevSkills, data]);
    }
  };

  // Routine to remove skills from skillsAddedList
  const removeSkill = (skillToRemove) => {
    setSkillsAddedList((prevSkills) =>
      prevSkills.filter((skill) => skill !== skillToRemove)
    );
  };

  // Routine to return the month number
  const getMonthNumber = (monthName) => {
    // Finding the index of the month in the array
    const monthIndex = monthsList.indexOf(monthName);

    // If the month is found, return index + 1, else return 0
    return monthIndex >= 0 ? monthIndex + 1 : 0;
  };

  // Routine to add validation conditions
  const validateForm = () => {
    // Current year
    const currentYear = new Date().getFullYear();

    // Check if starting year is prior to ending year
    const localCurrentlyWorkingEng = editCurrentlyWorkingEng.value;
    const localPrevExpEng = editPrevExpEng.value;
    const localJobStartingMonth = editJobStartingMonth.value.value;
    const localJobStartingYear = editJobStartingYear.value;
    const localJobEndingMonth = editJobEndingMonth.value.value;
    const localJobEndingYear = editJobEndingYear.value;

    if (
      localCurrentlyWorkingEng === "No" &&
      localPrevExpEng === "Yes" &&
      localJobStartingMonth !== "" &&
      localJobStartingYear !== "" &&
      localJobEndingMonth !== "" &&
      localJobEndingYear !== ""
    ) {
      const startYear = parseInt(localJobStartingYear);
      const endYear = parseInt(localJobEndingYear);
      const startMonth = getMonthNumber(localJobStartingMonth);
      const endMonth = getMonthNumber(localJobEndingMonth);

      // Check if the starting year is greater than the ending year
      if (startYear > endYear) {
        setErrorMessage("Starting year cannot be later than ending year");
        return false;
      }

      // If the years are the same, check if the starting month is greater than the ending month
      if (startYear === endYear && startMonth > endMonth) {
        setErrorMessage(
          "Starting month cannot be later than ending month in the same year"
        );
        return false;
      }
    }

    // Invalid starting month
    if (
      localCurrentlyWorkingEng === "No" &&
      localPrevExpEng === "Yes" &&
      localJobStartingMonth !== ""
    ) {
      const startMonth = getMonthNumber(localJobStartingMonth);
      if (startMonth > 12) {
        setErrorMessage("Invalid starting month");
        return false;
      }
    }

    // Invalid ending month
    if (
      localCurrentlyWorkingEng === "No" &&
      localPrevExpEng === "Yes" &&
      localJobEndingMonth !== ""
    ) {
      const endMonth = getMonthNumber(localJobEndingMonth);
      if (endMonth > 12) {
        setErrorMessage("Invalid ending month");
        return false;
      }
    }

    // Check if the ending year is in the future
    if (localCurrentlyWorkingEng === "Yes" && localJobStartingYear !== "") {
      const startYear = parseInt(localJobStartingYear);
      if (startYear > currentYear) {
        setErrorMessage("Ending year cannot be in the future");
        return false;
      }
    }

    // Check if the ending year is in the future
    if (
      localCurrentlyWorkingEng === "No" &&
      localPrevExpEng === "Yes" &&
      localJobEndingYear !== ""
    ) {
      const endYear = parseInt(localJobEndingYear);
      if (endYear > currentYear) {
        setErrorMessage("Ending year cannot be in the future");
        return false;
      }
    }

    setErrorMessage(""); // Clear the error message if validation passes
    return true;
  };

  // Routine to force update of validation fields
  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      currentlyWorkingEng: {
        ...editCurrentlyWorkingEng,
        isValid: validateRadioButtonInput(
          editCurrentlyWorkingEng.value,
          editCurrentlyWorkingEng.isRequired,
          true // isVisible
        ),
      },
      prevExpEng: {
        ...editPrevExpEng,
        isValid: validateRadioButtonInput(
          editPrevExpEng.value,
          editPrevExpEng.isRequired,
          editCurrentlyWorkingEng.value === "No" // isVisible
        ),
      },
      engPostion: {
        ...editEngPosition,
        isValid: validateTextInput(
          editEngPosition.value,
          editEngPosition.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorkingEng.value === "Yes" ||
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      engIndustry: {
        ...editEngIndustry,
        isValid: validateSelectInput(
          editEngIndustry.value.value,
          editEngIndustry.isRequired,
          editCurrentlyWorkingEng.value === "Yes" ||
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      companyName: {
        ...editCompanyName,
        isValid: validateTextInput(
          editCompanyName.value,
          editCompanyName.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorkingEng.value === "Yes" ||
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      jobStartingMonth: {
        ...editJobStartingMonth,
        isValid: validateSelectInput(
          editJobStartingMonth.value.value,
          editJobStartingMonth.isRequired,
          editCurrentlyWorkingEng.value === "Yes" ||
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      jobStartingYear: {
        ...editJobStartingYear,
        isValid: validateTextInput(
          editJobStartingYear.value,
          editJobStartingYear.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorkingEng.value === "Yes" ||
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      jobEndingMonth: {
        ...editJobEndingMonth,
        isValid: validateSelectInput(
          editJobEndingMonth.value.value,
          editJobEndingMonth.isRequired,
          editCurrentlyWorkingEng.value === "No" &&
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
      jobEndingYear: {
        ...editJobEndingYear,
        isValid: validateTextInput(
          editJobEndingYear.value,
          editJobEndingYear.isRequired,
          textInputMaxLength,
          textInputMinLength,
          editCurrentlyWorkingEng.value === "No" &&
            editPrevExpEng.value === "Yes" // isVisible
        ),
      },
    };
  };

  // NEXT button handler
  const nextButtonHandler = () => {
    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    if (allRequiredFieldsValid && validateForm()) {
      // Save the edited values
      setCurrentlyWorkingEng(editCurrentlyWorkingEng);
      setPrevExpEng(editPrevExpEng);
      setEngPosition(editEngPosition);
      setEngIndustry(editEngIndustry);
      setCompanyName(editCompanyName);
      setJobStartingMonth(editJobStartingMonth);
      setJobStartingYear(editJobStartingYear);
      setJobEndingMonth(editJobEndingMonth);
      setJobEndingYear(editJobEndingYear);
      setSkillsFieldData(editSkillsFieldData);

      onClickNextHandler(fieldsData);
    } else {
      setActivateRequiredFields(true);
    }
  };

  // BACK button handler
  const backButtonHandler = () => {
    // Revert the edit fields to the saved fields
    setEditCurrentlyWorkingEng(currentlyWorkingEng);
    setEditPrevExpEng(prevExpEng);
    setEditEngPosition(engPosition);
    setEditEngIndustry(engIndustry);
    setEditCompanyName(companyName);
    setEditJobStartingMonth(jobStartingMonth);
    setEditJobStartingYear(jobStartingYear);
    setEditJobEndingMonth(jobEndingMonth);
    setEditJobEndingYear(jobEndingYear);
    setEditSkillsFieldData(skillsFieldData);

    onClickBackHandler(fieldsData);
  };

  // Routine to handle currentlyWorkingEng
  const handleCurrentlyWorkingEngChange = (value) => {
    setEditCurrentlyWorkingEng(value);
  };

  // Routine to handle prevExpEng
  const handlePrevExpEngChange = (value) => {
    setEditPrevExpEng(value);
  };

  // Routine to handle engPosition
  const handleEngPositionChange = (value) => {
    setEditEngPosition(value);
  };

  // Routine to handle engIndustry
  const handleEngIndustryChange = (value) => {
    setEditEngIndustry(value);
  };

  // Routine to handle companyName
  const handleCompanyNameChange = (value) => {
    setEditCompanyName(value);
  };

  // Routine to handle jobStartingMonth
  const handleJobStartingMonthChange = (value) => {
    setEditJobStartingMonth(value);
  };

  // Routine to handle jobStartingYear
  const handleJobStartingYearChange = (value) => {
    setEditJobStartingYear(value);
  };

  // Routine to handle jobEndingMonth
  const handleJobEndingMonthChange = (value) => {
    setEditJobEndingMonth(value);
  };

  // Routine to handle jobEndingYear
  const handleJobEndingYearChange = (value) => {
    setEditJobEndingYear(value);
  };

  // Routine to handle skillsFieldData
  const handleSkillsFieldDataChange = (value) => {
    // Add skill and clear field
    if (value.value && value.value.value !== "") {
      updateSkills(value.value.value);
      setEditSkillsFieldData({ value: "", label: "" });
    }
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Professional Experience</h2>
            <form className="signup-form">
              <FormFieldRadioButton
                fieldTitle="Are you currently working as an engineer?"
                inOptionsArray={["Yes", "No"]}
                isRequired={true}
                radioButtonsInSingleRow={false}
                activateRequired={activateRequiredFields}
                onFieldChange={handleCurrentlyWorkingEngChange}
                value={editCurrentlyWorkingEng.value}
              />
              <FormFieldRadioButton
                fieldTitle="Do you have previous engineering experience?"
                inOptionsArray={["Yes", "No"]}
                isRequired={true}
                radioButtonsInSingleRow={false}
                activateRequired={activateRequiredFields}
                onFieldChange={handlePrevExpEngChange}
                value={editPrevExpEng.value}
                isVisible={editCurrentlyWorkingEng.value === "No"}
              />
              <FormFieldText
                fieldTitle={"Engineering position"}
                inPlaceholder={
                  "Enter your engineering position (e.g. Automation Engineer)"
                }
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEngPositionChange}
                value={editEngPosition.value}
                isVisible={
                  editCurrentlyWorkingEng.value === "Yes" ||
                  editPrevExpEng.value === "Yes"
                }
              />
              <FormFieldText
                fieldTitle={"Company name"}
                inPlaceholder={"Enter the company name"}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleCompanyNameChange}
                value={editCompanyName.value}
                isVisible={
                  editCurrentlyWorkingEng.value === "Yes" ||
                  editPrevExpEng.value === "Yes"
                }
              />
              <FormFieldSelect
                fieldTitle={"Industry"}
                inPlaceholder={"Enter the working industry"}
                inOptionsArray={industries}
                isRequired={true}
                allowTyping={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEngIndustryChange}
                value={editEngIndustry.value}
                isVisible={
                  editCurrentlyWorkingEng.value === "Yes" ||
                  editPrevExpEng.value === "Yes"
                }
              />
              <div className="row">
                <div className={"col-md-6"}>
                  <FormFieldSelect
                    fieldTitle={"Starting month"}
                    inPlaceholder={"Enter your starting month"}
                    inOptionsArray={monthsList}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleJobStartingMonthChange}
                    value={editJobStartingMonth.value}
                    isVisible={
                      editCurrentlyWorkingEng.value === "Yes" ||
                      editPrevExpEng.value === "Yes"
                    }
                  />
                </div>
                <div className={"col-md-6"}>
                  <FormFieldText
                    fieldTitle={"Starting Year"}
                    inPlaceholder={"Enter the starting year"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleJobStartingYearChange}
                    value={editJobStartingYear.value}
                    allowOnlyIntegers
                    textMaxLength={4}
                    isVisible={
                      editCurrentlyWorkingEng.value === "Yes" ||
                      editPrevExpEng.value === "Yes"
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-md-6 ${
                    editCurrentlyWorkingEng.value === "No" &&
                    editPrevExpEng.value === "Yes"
                      ? ""
                      : "d-none"
                  }`}
                >
                  <FormFieldSelect
                    fieldTitle={"Ending month"}
                    inPlaceholder={"Enter your Ending month"}
                    inOptionsArray={monthsList}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleJobEndingMonthChange}
                    value={editJobEndingMonth.value}
                    isVisible={
                      editCurrentlyWorkingEng.value === "No" &&
                      editPrevExpEng.value === "Yes"
                    }
                  />
                </div>
                <div
                  className={`col-md-6 ${
                    editCurrentlyWorkingEng.value === "No" &&
                    editPrevExpEng.value === "Yes"
                      ? ""
                      : "d-none"
                  }`}
                >
                  <FormFieldText
                    fieldTitle={"Ending Year"}
                    inPlaceholder={"Enter the Ending year"}
                    isRequired={true}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleJobEndingYearChange}
                    value={editJobEndingYear.value}
                    allowOnlyIntegers
                    textMaxLength={4}
                    isVisible={
                      editCurrentlyWorkingEng.value === "No" &&
                      editPrevExpEng.value === "Yes"
                    }
                  />
                </div>
              </div>
              <FormFieldSelect
                fieldTitle={"Skills"}
                inPlaceholder={"Enter a skill and press Enter"}
                inOptionsArray={extractAndSortSkills(skillsObj)}
                isRequired={false}
                activateRequired={activateRequiredFields}
                onFieldChange={handleSkillsFieldDataChange}
                value={editSkillsFieldData.value}
                allowTyping={true}
              />
              <div className="tags-pills">
                {skillsAddedList.map((tag, index) => (
                  <TagPill key={index} tag={tag} onRemove={removeSkill} />
                ))}
              </div>
            </form>
            {errorMessage && (
              <div className="row">
                <div
                  className="col text-center"
                  style={{ color: "red", marginTop: "20px" }}
                >
                  {errorMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row right-bottom-row">
          <div className="col-md-6 right-bottom-col-left">
            <EIIButton
              text="Back"
              color="green"
              btnBig={buttonBig}
              transparent
              onClickHandler={backButtonHandler}
            />
          </div>
          <div className="col-md-6 right-bottom-col-right">
            <EIIButton
              text="Next"
              color="green"
              btnBig={buttonBig}
              onClickHandler={nextButtonHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm1PageProfessionalExp;
