import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const PostTip = ({title, text}) => {

    
    let attributeHtml;
    // Check if the attribute "text" is a string
    if (typeof text === 'string') {
      attributeHtml = <p className='post-tip-text'>{text}</p>;
    } else {
      attributeHtml = text;
    }

    return(
        <>
            <div className="post-tip-box">
                <Row>
                    <Col sm={12} md={2} lg={1} className="post-tip-col">
                        <h4 className='post-tip-title'>Tip</h4>
                    </Col>
                    <Col sm={12} md={10} lg={11} className="post-tip-col">
                        <p className='post-tip-title-text post-tip-text'>{title}</p>
                        {attributeHtml}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PostTip