import React from 'react';
import DemEngCard from "./DemEngCard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faBolt, faGears, faFlaskVial, faDiagramProject } from '@fortawesome/free-solid-svg-icons'


const DemEngBox = () => {
    const dem_eng_list = [
        {
            "id": 1,
            "icon": faBolt,
            "job_title": "Electrical engineer",
            "salary_range": "34 - 68 k€"
        },
        {
            "id": 2,
            "icon": faGears,
            "job_title": "Automation engineer",
            "salary_range": "42 - 65 k€"
        },
        {
            "id": 3,
            "icon": faFlaskVial,
            "job_title": "Chemical engineer",
            "salary_range": "34 - 74 k€"
        },
        {
            "id": 4,
            "icon": faDiagramProject,
            "job_title": "Process engineer",
            "salary_range": "34 - 71 k€"
        }
    ]


    return(
        <>
            <div className="dem-eng-group">
                <Row>
                    {dem_eng_list.map(el =>
                        <Col key={el.id} sm={12} md={6} lg={3} className="dem-eng-card-col">
                            <DemEngCard icon={el.icon} job_title={el.job_title} salary_range={el.salary_range}/>
                        </Col>
                    )}
                </Row>
            </div>
        </>
    )
}

export default DemEngBox