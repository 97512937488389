import React from "react";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import MoveToIrelandCard from "./MoveToIrelandCard";
import CenterTitleTextCont from "./CenterTitleTextCont";
import EIIButton from "../global_utils/EIIButton";
//import MostDemEng from '../images/MoveToIrelandSection/AdobeStock_234283334_Engineers.jpeg';
//import AreasDublin from '../images/MoveToIrelandSection/Areas_Dublin.png';
import DublinAccom from "../images/MoveToIrelandSection/pexels-photomix-company-101808.webp";
import FindJobEng from "../images/MoveToIrelandSection/pexels-vojtech-okenka-392018.webp";
import MoveIrelandEU from "../images/MoveToIrelandSection/pexels-12541596.webp";
import WorkingVisa from "../images/MoveToIrelandSection/passport-ga14150b61_1920.webp";
import HighestPaid from "../images/MoveToIrelandSection/pexels-fauxels-3182759.webp";
import BestAreas from "../images/MoveToIrelandSection/pexels-luciann-photography-3566187.webp";
import IrishLife from "../images/MoveToIrelandSection/irishlife.webp";
import DigitalNomad from "../images/MoveToIrelandSection/digitalnomad.webp";
import TextWithVector from "./TextWithVector";

const MoveToIrelandSection = () => {
  const post_list = [
    {
      id: 1,
      image: DublinAccom,
      title: "Find accommodation in Dublin",
      date: "07 OCT 2023",
      type: "LIFE",
      post_link: "/DublinAccommodation",
    },
    {
      id: 2,
      image: FindJobEng,
      title: "Find an engineering job",
      date: "02 NOV 2023",
      type: "WORK",
      post_link: "/FindEngJobIreland",
    },
    {
      id: 3,
      image: WorkingVisa,
      title: "Apply for a Working Visa",
      date: "14 DEC 2023",
      type: "WORK",
      post_link: "/WorkingVisaIreland",
    },
    {
      id: 4,
      image: MoveIrelandEU,
      title: "Move to Ireland as an EU citizen",
      date: "04 JAN 2024",
      type: "LIFE",
      post_link: "/PostMoveIrelandEU",
    },
    {
      id: 5,
      image: HighestPaid,
      title: "Highest-paid engineers",
      date: "12 FEB 2024",
      type: "WORK",
      post_link: "/PostHighetsPaidEngineers",
    },
    {
      id: 6,
      image: BestAreas,
      title: "Best areas to live in Dublin",
      date: "15 MAR 2024",
      type: "LIFE",
      post_link: "/PostBestAreasToLive",
    },
    {
      id: 7,
      image: BestAreas,
      title: "Special Visa Allowance: North to South America",
      date: "02 APR 2024",
      type: "LIFE",
      post_link: "/PostAmericaWorkVisaIreland",
    },
    {
      id: 8,
      image: IrishLife,
      title: "Adjusting to Life in Ireland: A Guide for Engineers",
      date: "01 SEP 2024",
      type: "LIFE",
      post_link: "/PostAdjustingToLife",
    },
    {
      id: 9,
      image: DigitalNomad,
      title: "Thriving as a Digital Nomad in Ireland",
      date: "05 SEP 2024",
      type: "WORK",
      post_link: "/PostDigitalNomadIreland",
    },
  ];

  const isMobile = () => window.innerWidth < 768; // Example breakpoint for mobile

  const [post_arr, setPosts] = useState([]);
  const [button_text, SetButtonText] = useState("Show More");
  const [currentScrollTop, setCurrentScrollTop] = useState(0);

  // Routine to convert the date to a comparable format
  const parseDate = (dateStr) => {
    return new Date(
      Date.parse(dateStr.replace(/(\d{2}) (\w{3}) (\d{4})/, "$2 $1, $3"))
    );
  };

  // Sort the posts by date before using them
  const sortedPostList = post_list.sort(
    (a, b) => parseDate(b.date) - parseDate(a.date)
  );

  useEffect(() => {
    // Set initial posts and button text based on device type
    const initialPosts = isMobile()
      ? sortedPostList.slice(0, 3)
      : sortedPostList.slice(0, 6);
    setPosts(initialPosts);
    SetButtonText(
      initialPosts.length < sortedPostList.length ? "Show More" : "Show Less"
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newPosts = isMobile()
        ? sortedPostList.slice(0, 3)
        : sortedPostList.slice(0, 6);
      setPosts(newPosts);
      SetButtonText(
        newPosts.length < sortedPostList.length ? "Show More" : "Show Less"
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [post_arr]);

  // Used to scroll to the top of the current section
  useEffect(() => {
    window.scrollTo(0, currentScrollTop);
  }, [currentScrollTop]);

  const DeployPosts = (e) => {
    e.preventDefault();

    // Scroll to the top when "Show more/less" button is pressed
    setCurrentScrollTop(document.documentElement.scrollTop);

    // Deploy only 3 posts and change button text to "Show More"
    if (sortedPostList.length === post_arr.length) {
      SetButtonText("Show More");
      setPosts(sortedPostList.slice(0, 3));
    }
    // Deploy all posts and change button text to "Show More"
    else {
      SetButtonText("Show Less");
      setPosts(sortedPostList);
    }
  };

  return (
    <section id="AboutIreland">
      <div className="colored-section title-section">
        <CenterTitleTextCont
          title={
            <div className="centered-text title-section">
              <h2 className="vector-inline">Doubts about moving to&nbsp;</h2>
              <TextWithVector title_type="h2" title_text="Ireland?" />
            </div>
          }
          text="Whether you're interested in finding a job, exploring the local cuisine, or immersing yourself in the vibrant arts scene, our posts will provide you with valuable insights and tips to help you navigate life in Ireland. Join us as we uncover the many wonders of this fascinating country!"
          content={
            <>
              <Row>
                {post_arr.map((el) => (
                  <MoveToIrelandCard
                    key={el.id}
                    image={el.image}
                    title={el.title}
                    date={el.date}
                    type={el.type}
                    post_link={el.post_link}
                  />
                ))}
              </Row>
              <div className="mov-to-irl-btn-div">
                <EIIButton
                  color="green"
                  text={button_text}
                  onClickHandler={DeployPosts}
                />
              </div>
            </>
          }
        />
      </div>
    </section>
  );
};

export default MoveToIrelandSection;
