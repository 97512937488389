import React, { useState, useEffect } from "react";
import "./CookieBanner.css";
import Cookies from "js-cookie";
import EIIButton from "./EIIButton";

function CookieBanner() {
  const [isVisible, setIsVisible] = useState(!Cookies.get("CookieConsentEII"));

  const handleConsent = (consent) => {
    Cookies.set("CookieConsentEII", consent, { expires: 365 });
    setIsVisible(false);

    if (consent === "accept") {
      // Trigger event or callback for GA initialization
      document.dispatchEvent(new Event("consentGranted"));
    }
  };

  useEffect(() => {
    if (Cookies.get("CookieConsentEII")) {
      setIsVisible(false);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <div className="cookie-banner">
      <p>
        We use cookies to improve your experience. Essential cookies are
        necessary for the site to function, and others (non-essential) are used
        for analytics and marketing. By clicking "Accept", you agree to our use
        of non-essential cookies. For more information, check our&nbsp;
        <a href="/#/PrivacyPolicy">privacy policy</a>.
      </p>
      <div className="cookie-buttons">
        <div className="cookie-button-wrapper">
          <EIIButton
            color="green"
            text="Accept"
            onClickHandler={() => handleConsent("accept")}
          />
        </div>
        <div className="cookie-button-wrapper">
          <EIIButton
            color="green"
            transparent={true}
            text="Reject"
            onClickHandler={() => handleConsent("reject")}
          />
        </div>
      </div>
    </div>
  );
}

export default CookieBanner;
