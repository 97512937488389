import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRouteAgainsLoggedInUsers = ({ children }) => {
  // Check if user exists by inspecting jwt_eii cookie
  if (Cookies.get("jwt_eii")) {
    // Redirect users that are logged in to the home page
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRouteAgainsLoggedInUsers;
