import React from 'react';


const PostParagraph = ({text}) => {    
    /* Text is string */
    if(typeof text === 'string') {
        return(<p className='content-p'>{text}</p>)
    }
    /* Text is html */
    else{
        return(<>{text}</>)
    }
}

export default PostParagraph