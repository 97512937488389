import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../global_utils/UserProvider";
import UASinglePageButtons from "./UASinglePageButtons";
import {
  validateTextInput,
  validateSelectInput,
  validateRadioButtonInput,
} from "../global_utils/FormFieldValidation";
import {
  startMonthYearValidation,
  startEndMonthYearValidation,
} from "../global_utils/GeneralValidation";
import FormFieldText from "../global_utils/FormFieldText";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import FormFieldRadioButton from "../global_utils/FormFieldRadioButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import nfq from "../../assets/data/NFQualifications.json";
import monthsList from "../../assets/data/months.json";

const UASingleStudy = ({ studyIdx, deleteStudyHandler }) => {
  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const { user, loading, RefreshUserData, DeleteStudy } =
    useContext(UserContext);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [degreeTitle, setDegreeTitle] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editDegreeTitle, setEditDegreeTitle] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyLevel, setStudyLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyLevel, setEditStudyLevel] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [studyCenter, setStudyCenter] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStudyCenter, setEditStudyCenter] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [startingMonth, setStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStartingMonth, setEditStartingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [startingYear, setStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editStartingYear, setEditStartingYear] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [currentlyStudying, setCurrentlyStudying] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editCurrentlyStudying, setEditCurrentlyStudying] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [endingMonth, setEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editEndingMonth, setEditEndingMonth] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [endingYear, setEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editEndingYear, setEditEndingYear] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    // Update current study
    let l_studies;
    if (user.studies) {
      l_studies = JSON.parse(JSON.stringify(user.studies));
      l_studies[studyIdx].degreeTitle = editDegreeTitle.value;
      l_studies[studyIdx].studyLevel = editStudyLevel.value.value;
      l_studies[studyIdx].studyCenter = editStudyCenter.value;
      l_studies[studyIdx].startingMonth = editStartingMonth.value.value;
      l_studies[studyIdx].startingYear = editStartingYear.value;
      l_studies[studyIdx].currentlyStudying = editCurrentlyStudying.value;
      l_studies[studyIdx].endingMonth = editEndingMonth.value?.value || "";
      l_studies[studyIdx].endingYear = editEndingYear.value;
    }

    setFieldsData({
      studies: l_studies,
      degreeTitle: editDegreeTitle,
      studyLevel: editStudyLevel,
      studyCenter: editStudyCenter,
      startingMonth: editStartingMonth,
      startingYear: editStartingYear,
      currentlyStudying: editCurrentlyStudying,
      endingMonth: editEndingMonth,
      endingYear: editEndingYear,
    });
  }, [
    editDegreeTitle,
    editStudyLevel,
    editStudyCenter,
    editStartingMonth,
    editStartingYear,
    editCurrentlyStudying,
    editEndingMonth,
    editEndingYear,
    user.studies,
    studyIdx,
  ]);

  // Clear Ending Month and Year if currently studying in this company
  useEffect(() => {
    // If user is currently studying here clear Ending Month and Year
    if (
      editCurrentlyStudying.value === "Yes" &&
      endingMonth.value.value &&
      (endingMonth.value.value !== "" || endingYear.value !== "")
    ) {
      setEditEndingMonth({
        value: {
          value: "",
          label: "",
        },
        isValid: editEndingMonth.isValid,
        isRequired: editEndingMonth.isRequired,
      });
      setEditEndingYear({
        value: "",
        isValid: editEndingYear.isValid,
        isRequired: editEndingYear.isRequired,
      });
    }
  }, [editCurrentlyStudying]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      degreeTitle: {
        value: uData.studies[studyIdx].degreeTitle || "",
        isValid: editDegreeTitle.isValid,
        isRequired: editDegreeTitle.isRequired,
      },
      studyLevel: {
        value: {
          value: uData.studies[studyIdx].studyLevel || "",
          label: uData.studies[studyIdx].studyLevel || "",
        },
        isValid: editStudyLevel.isValid,
        isRequired: editStudyLevel.isRequired,
      },
      studyCenter: {
        value: uData.studies[studyIdx].studyCenter || "",
        isValid: editStudyCenter.isValid,
        isRequired: editStudyCenter.isRequired,
      },
      startingMonth: {
        value: {
          value: uData.studies[studyIdx].startingMonth || "",
          label: uData.studies[studyIdx].startingMonth || "",
        },
        isValid: editStartingMonth.isValid,
        isRequired: editStartingMonth.isRequired,
      },
      startingYear: {
        value: uData.studies[studyIdx].startingYear || "",
        isValid: editStartingYear.isValid,
        isRequired: editStartingYear.isRequired,
      },
      currentlyStudying: {
        value:
          uData.studies[studyIdx].endingYear &&
          uData.studies[studyIdx].endingYear !== ""
            ? "No"
            : "Yes",
        isValid: editCurrentlyStudying.isValid,
        isRequired: editCurrentlyStudying.isRequired,
      },
      endingMonth: {
        value: {
          value: uData.studies[studyIdx].endingMonth || "",
          label: uData.studies[studyIdx].endingMonth || "",
        },
        isValid: editEndingMonth.isValid,
        isRequired: editEndingMonth.isRequired,
      },
      endingYear: {
        value: uData.studies[studyIdx].endingYear || "",
        isValid: editEndingYear.isValid,
        isRequired: editEndingYear.isRequired,
      },
    };

    // Initialize degreeTitle
    setDegreeTitle(newFormData.degreeTitle);
    setEditDegreeTitle(newFormData.degreeTitle);
    // Initialize studyLevel
    setStudyLevel(newFormData.studyLevel);
    setEditStudyLevel(newFormData.studyLevel);
    // Initialize studyCenter
    setStudyCenter(newFormData.studyCenter);
    setEditStudyCenter(newFormData.studyCenter);
    // Initialize startingMonth
    setStartingMonth(newFormData.startingMonth);
    setEditStartingMonth(newFormData.startingMonth);
    // Initialize startingYear
    setStartingYear(newFormData.startingYear);
    setEditStartingYear(newFormData.startingYear);
    // Initialize currentlyStudying
    setCurrentlyStudying(newFormData.currentlyStudying);
    setEditCurrentlyStudying(newFormData.currentlyStudying);
    // Initialize endingMonth
    setEndingMonth(newFormData.endingMonth);
    setEditEndingMonth(newFormData.endingMonth);
    // Initialize endingYear
    setEndingYear(newFormData.endingYear);
    setEditEndingYear(newFormData.endingYear);
  };

  const revalidateFields = () => {
    const textInputMaxLength = 50; // Set these as per your validation rules
    const textInputMinLength = 0;

    return {
      degreeTitle: {
        ...editDegreeTitle,
        isValid: validateTextInput(
          editDegreeTitle.value,
          editDegreeTitle.isRequired,
          200, // textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      studyLevel: {
        ...editStudyLevel,
        isValid: validateSelectInput(
          editStudyLevel.value.value,
          editStudyLevel.isRequired,
          true
        ),
      },
      studyCenter: {
        ...editStudyCenter,
        isValid: validateTextInput(
          editStudyCenter.value,
          editStudyCenter.isRequired,
          textInputMaxLength,
          textInputMinLength,
          true // isVisible
        ),
      },
      startingMonth: {
        ...editStartingMonth,
        isValid: validateSelectInput(
          editStartingMonth.value.value,
          editStartingMonth.isRequired,
          true
        ),
      },
      startingYear: {
        ...editStartingYear,
        isValid: validateTextInput(
          editStartingYear.value,
          editStartingYear.isRequired,
          4, // textInputMaxLength
          textInputMinLength,
          true // isVisible
        ),
      },
      currentlyStudying: {
        ...editCurrentlyStudying,
        isValid: validateRadioButtonInput(
          editCurrentlyStudying.value.value,
          editCurrentlyStudying.isRequired,
          true
        ),
      },
      endingMonth: {
        ...editEndingMonth,
        isValid: validateSelectInput(
          editEndingMonth.value.value,
          editEndingMonth.isRequired,
          editCurrentlyStudying.value === "No" // isVisible
        ),
      },
      endingYear: {
        ...editEndingYear,
        isValid: validateTextInput(
          editEndingYear.value,
          editEndingYear.isRequired,
          4, // textInputMaxLength
          textInputMinLength,
          editCurrentlyStudying.value === "No" // isVisible
        ),
      },
    };
  };

  // Routine to add validation conditions
  const validateForm = () => {
    if (editCurrentlyStudying.value === "No") {
      return startEndMonthYearValidation(
        editStartingMonth.value.value,
        editStartingYear.value,
        editEndingMonth.value.value,
        editEndingYear.value,
        setErrorMessage
      );
    } else if (editCurrentlyStudying.value === "Yes") {
      return startMonthYearValidation(
        editStartingMonth.value.value,
        editStartingYear.value,
        setErrorMessage
      );
    }

    return true;
  };

  // Handler to sumbit data to the backend
  const submitDataHandler = async () => {
    // Disable submit
    setEnableSubmit(false);

    // Revalidate all fields
    const updatedFieldsData = revalidateFields();

    // Validate all required fields
    const allRequiredFieldsValid = Object.values(updatedFieldsData).every(
      (field) => field.isValid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid && validateForm()) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
          {
            studies: fieldsData.studies,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Ask UserProvider to refresh user data
          RefreshUserData();

          // Save the edited degreeTitle
          setDegreeTitle(editDegreeTitle);
          // Save the edited studyLevel
          setStudyLevel(editStudyLevel);
          // Save the edited studyCenter
          setStudyCenter(editStudyCenter);
          // Save the edited startingMonth
          setStartingMonth(editStartingMonth);
          // Save the edited startingYear
          setStartingYear(editStartingYear);
          // Save the edited currentlyStudying
          setCurrentlyStudying(editCurrentlyStudying);
          // Save the edited endingMonth
          setEndingMonth(editEndingMonth);
          // Save the edited endingYear
          setEndingYear(editEndingYear);
        } else {
          console.error("Failed to update user");
        }

        // Enable submit
        setEnableSubmit(true);
      } catch (error) {
        console.error("Error updating user data:", error);
        // Enable submit
        setEnableSubmit(true);
      }

      setEditEnable(false);
    } else {
      // Activate Required Fields
      setActivateRequiredFields(true);

      // Enable submit
      setEnableSubmit(true);
    }
  };

  // Handle to delete data from the backend
  const deleteDataHandler = async () => {
    setIsDeleting(true); // Start of deletion process

    await DeleteStudy(studyIdx); // Perform the deletion

    setIsDeleting(false); // End of deletion process

    // Call delete handler upstream
    deleteStudyHandler();
  };

  // Routine to handle degreeTitle
  const handleDegreeTitleChange = (value) => {
    setEditDegreeTitle(value);
  };

  // Routine to handle studyLevel
  const handleStudyLevelChange = (value) => {
    setEditStudyLevel(value);
  };

  // Routine to handle studyCenter
  const handleStudyCenterChange = (value) => {
    setEditStudyCenter(value);
  };

  // Routine to handle startingMonth
  const handleStartingMonthChange = (value) => {
    setEditStartingMonth(value);
  };

  // Routine to handle startingYear
  const handleStartingYearChange = (value) => {
    setEditStartingYear(value);
  };

  // Routine to handle currentlyStudying
  const handleCurrentlyStudyingChange = (value) => {
    setEditCurrentlyStudying(value);
  };

  // Routine to handle endingMonth
  const handleEndingMonthChange = (value) => {
    setEditEndingMonth(value);
  };

  // Routine to handle endingYear
  const handleEndingYearChange = (value) => {
    setEditEndingYear(value);
  };

  // Handler for NEXT button
  const editButtonHandler = () => {
    setEditEnable(true);
  };

  // Handler for CANCEL button
  const cancelHandler = () => {
    // Cancel is pressed and no degreeTitle is set, delete study
    if (degreeTitle.value === "") {
      deleteDataHandler();
      return;
    }

    // Revert the edit fields to the saved fields
    setEditDegreeTitle(degreeTitle);
    setEditStudyLevel(studyLevel);
    setEditStudyCenter(studyCenter);
    setEditStartingYear(startingYear);
    setEditEndingYear(endingYear);

    // Deactivate required fields
    setActivateRequiredFields(false);

    // Disable Edit
    setEditEnable(false);
  };

  // Always enable Edit if study is not defined
  if (
    !editEnable &&
    user &&
    studyIdx < user.studies.length &&
    user.studies[studyIdx].degreeTitle === "" &&
    editDegreeTitle.value === ""
  ) {
    setEditEnable(true);
  }

  return (
    <>
      {editEnable ? (
        <form className="signup-form">
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Degree title"}
              inPlaceholder={
                "Enter your degree title (e.g. Software Engineering)"
              }
              isRequired={true}
              textMaxLength={200}
              activateRequired={activateRequiredFields}
              onFieldChange={handleDegreeTitleChange}
              value={editDegreeTitle.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldSelect
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"Study Level"}
              inPlaceholder={"Enter your study level"}
              inOptionsArray={nfq.Qualifications}
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleStudyLevelChange}
              value={editStudyLevel.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <FormFieldText
              fieldEnabled={editEnable && enableSubmit}
              fieldTitle={"University / Education Center"}
              inPlaceholder={"Enter your University / Education Center"}
              isRequired={true}
              activateRequired={activateRequiredFields}
              onFieldChange={handleStudyCenterChange}
              value={editStudyCenter.value}
              loading={loading}
            />
          </div>
          <div className="row">
            <div className={"col-md-6"}>
              <FormFieldSelect
                fieldTitle={"Starting month"}
                inPlaceholder={"Enter your starting month"}
                fieldEnabled={editEnable && enableSubmit}
                inOptionsArray={monthsList}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStartingMonthChange}
                value={editStartingMonth.value}
                loading={loading}
              />
            </div>
            <div className={"col-md-6"}>
              <FormFieldText
                fieldTitle={"Starting Year"}
                inPlaceholder={"Enter the starting year"}
                fieldEnabled={editEnable && enableSubmit}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleStartingYearChange}
                value={editStartingYear.value}
                allowOnlyIntegers
                textMaxLength={4}
                loading={loading}
              />
            </div>
          </div>
          <FormFieldRadioButton
            fieldTitle="Are you currently studying this degree?"
            inOptionsArray={["Yes", "No"]}
            fieldEnabled={editEnable && enableSubmit}
            isRequired={true}
            radioButtonsInSingleRow={false}
            activateRequired={activateRequiredFields}
            onFieldChange={handleCurrentlyStudyingChange}
            value={editCurrentlyStudying.value}
            loading={loading}
          />
          <div className="row">
            <div
              className={`col-md-6 ${
                editCurrentlyStudying.value === "No" ? "" : "d-none"
              }`}
            >
              <FormFieldSelect
                fieldTitle={"Ending month"}
                inPlaceholder={"Enter your Ending month"}
                fieldEnabled={editEnable && enableSubmit}
                inOptionsArray={monthsList}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEndingMonthChange}
                value={editEndingMonth.value}
                isVisible={editCurrentlyStudying.value === "No"}
                loading={loading}
              />
            </div>
            <div
              className={`col-md-6 ${
                editCurrentlyStudying.value === "No" ? "" : "d-none"
              }`}
            >
              <FormFieldText
                fieldTitle={"Ending Year"}
                inPlaceholder={"Enter the Ending year"}
                fieldEnabled={editEnable && enableSubmit}
                isRequired={true}
                activateRequired={activateRequiredFields}
                onFieldChange={handleEndingYearChange}
                value={editEndingYear.value}
                allowOnlyIntegers
                textMaxLength={4}
                isVisible={editCurrentlyStudying.value === "No"}
                loading={loading}
              />
            </div>
          </div>
        </form>
      ) : (
        <>
          <p className="subform-title-p">
            {editDegreeTitle ? editDegreeTitle.value : ""}
          </p>
          <p className="subform-text-p">
            <strong>Study center:</strong>{" "}
            {editStudyCenter ? editStudyCenter.value : ""}
          </p>
          <p className="subform-text-p">
            <strong>Study level:</strong>{" "}
            {editStudyLevel ? editStudyLevel.value.value : ""}
          </p>
          <p className="subform-text-p">
            <strong>Study period:</strong>{" "}
            {editStartingMonth ? editStartingMonth.value.value : ""}{" "}
            {editStartingYear ? editStartingYear.value : ""} {" - "}
            {editCurrentlyStudying && editCurrentlyStudying.value === "Yes" ? (
              <>{"Present"}</>
            ) : (
              <>
                {editEndingMonth ? editEndingMonth.value.value : ""}{" "}
                {editEndingYear ? editEndingYear.value : ""}{" "}
              </>
            )}
          </p>
        </>
      )}
      {errorMessage && (
        <div className="row">
          <div
            className="col text-center"
            style={{ color: "red", marginTop: "20px" }}
          >
            {errorMessage}
          </div>
        </div>
      )}
      <UASinglePageButtons
        isEditEnabled={editEnable}
        isDeleting={isDeleting}
        onEditClick={editButtonHandler}
        onSubmitClick={submitDataHandler}
        onCancelClick={cancelHandler}
        onDeleteClick={deleteDataHandler}
        submitButtonDisabled={!enableSubmit}
      />
    </>
  );
};

export default UASingleStudy;
