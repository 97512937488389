// CompletionRectangles.js
import React from "react";
import "./CompletionRectangles.css";

const CompletionRectangles = ({
  numRectangles = 4,
  numRectanglesActive = 1,
  rectangleActiveColor = "green",
  rectangleInactiveColor = "white",
}) => {
  const renderRectangles = () => {
    const rectangles = [];

    for (let i = 0; i < numRectangles; i++) {
      const isRectangleActive = i < numRectanglesActive;
      const rectangleClassName = isRectangleActive
        ? "rectangle active"
        : "rectangle";

      const rectangleStyle = {
        backgroundColor: isRectangleActive
          ? rectangleActiveColor
          : rectangleInactiveColor,
      };

      rectangles.push(
        <div
          key={i}
          className={rectangleClassName}
          style={rectangleStyle}
        ></div>
      );
    }

    return rectangles;
  };

  return (
    <div className="completion-rectangles-container">
      <div className="completion-rectangles">{renderRectangles()}</div>
    </div>
  );
};

export default CompletionRectangles;
