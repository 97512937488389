import React from "react";

const TitleTextLeft = ({
  title,
  title_upper_space,
  content_after_title,
  text,
  text_content,
  mainTitle = false,
  justifyContent = false, // Add justifyContent prop with a default value of false
}) => {
  let title_class = "title-section";
  let content_class = "content-p no-gutters"; // Class for the paragraph content

  if (title_upper_space) {
    title_class += " title-upper-space";
  }

  if (justifyContent) {
    content_class += " justify-content";
  }

  return (
    <>
      <div className={title_class}>
        {mainTitle && <h2>{title}</h2>}
        {!mainTitle && <h3>{title}</h3>}
      </div>
      {content_after_title}
      {text !== null && <p className={content_class}>{text}</p>}
      {text_content !== null && (
        <div className={justifyContent ? "justify-content" : ""}>
          {text_content}
        </div>
      )}{" "}
    </>
  );
};

export default TitleTextLeft;
