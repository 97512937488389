import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  return (
    <section id="Footer" className="footer-section">
      <Row>
        <Col sm={6} md={8} lg={8}>
          <HashLink className="footer-a" smooth to="/#Home">
            <h5 className="footer-title footer-text-link">
              Engineers In Ireland
            </h5>
          </HashLink>
        </Col>
        <Col sm={6} md={4} lg={4}>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <h5 className="footer-title">Sections</h5>
              <HashLink className="footer-a" smooth to="/#Home">
                <h5 className="footer-text footer-text-link">Home</h5>
              </HashLink>
              <HashLink className="footer-a" smooth to="/#DemEng">
                <h5 className="footer-text footer-text-link">Irish Salaries</h5>
              </HashLink>
              <HashLink className="footer-a" smooth to="/#JoinCommunity">
                <h5 className="footer-text footer-text-link">
                  Join our Community
                </h5>
              </HashLink>
              <HashLink className="footer-a" smooth to="/#AboutIreland">
                <h5 className="footer-text footer-text-link">About Ireland</h5>
              </HashLink>
              <HashLink className="footer-a" smooth to="/#AboutUs">
                <h5 className="footer-text footer-text-link">About Us</h5>
              </HashLink>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <h5 className="footer-title">Company</h5>
              <a
                className="footer-a"
                href="https://www.facebook.com/groups/988309832050393"
              >
                <h5 className="footer-text footer-text-link">Facebook</h5>
              </a>
              <HashLink className="footer-a" smooth to="/PrivacyPolicy">
                <h5 className="footer-text footer-text-link">Privacy Policy</h5>
              </HashLink>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr className="footer-line" />
      <Row>
        <Col xs={8} sm={8} md={8} lg={8} className="footer-copyright-col">
          <p className="footer-p">©2023 Engineers In Ireland</p>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} className="footer-icon-col">
          <a
            className="footer-a"
            href="https://www.facebook.com/groups/988309832050393"
          >
            <FontAwesomeIcon
              className="fa-2x footer-icon"
              icon={faSquareFacebook}
            />
          </a>
        </Col>
      </Row>
    </section>
  );
};

export default Footer;
