import React from "react";
import EIIButton from "../global_utils/EIIButton";
import TitleTextLeft from "../global_utils/TitleTextLeft";
import "./ForEngSection.css";

const ForXSubSection = ({
  for_text,
  prim_title,
  content,
  button_text,
  buttonOnClickHandler,
  justifyContent,
  color,
}) => {
  return (
    <>
      <p className="for-eng-p no-gutters">{for_text}</p>
      <TitleTextLeft
        title={prim_title}
        text={content}
        justifyContent={justifyContent}
      />
      <div>
        <EIIButton
          color={color}
          text={button_text}
          customClass="for-eng-btn"
          onClickHandler={buttonOnClickHandler}
        />
      </div>
    </>
  );
};

export default ForXSubSection;
