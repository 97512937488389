import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SignUpImg from "../images/ForEngSection/SignUpPageEII.webp";
import ForEngImg from "../images/ForEngSection/ForEngineersVector.webp";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ForXSubSection from "./ForXSubSection";
import { UserContext } from "../global_utils/UserProvider"; // Import the UserContext
import "./ForEngSection.css";

const ForEngSection = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Use the UserContext to get the current user

  // Handler for Get Started button and image click
  const GetStartedButtonHandler = () => {
    // Do nothing if the user is logged in, otherwise to "/Signup"
    if (!user) {
      navigate("/Signup");
    }
  };

  return (
    <section id="ForEng">
      <div className="content-section">
        <Row>
          <Col sm={12} md={12} lg={6} className="for-eng-signupimg-col">
            <div
              onClick={GetStartedButtonHandler}
              className="clickable-image for-eng-img"
            >
              <img
                className="for-eng-img"
                src={SignUpImg}
                alt="For Engineers"
              />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <ForXSubSection
              for_text={
                <div
                  onClick={GetStartedButtonHandler}
                  className="clickable-image for-eng-titleimg"
                >
                  <img
                    src={ForEngImg}
                    className="for-eng-titleimg"
                    alt="For Engineers"
                  />
                </div>
              }
              prim_title="Create a professional profile online"
              content="Improve your professional profile and let us help you find your ideal job in Ireland. Our experienced recruitment team specializes in the engineering sector in Ireland. They will review your skills and experience, and then reach out to top companies to find great job opportunities for you. We are committed to guiding you towards a successful career in Ireland, matching your abilities and career goals with the right job openings. Join us, and let's work together to take your career to the next level in Ireland."
              justifyContent={true}
              button_text="Get Started"
              buttonOnClickHandler={GetStartedButtonHandler}
              color="green"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ForEngSection;
