import React, { useState, useEffect } from "react";
import EIIButton from "../global_utils/EIIButton";
import GoogleLoginButton from "../global_utils/GoogleLoginButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { gapi } from "gapi-script";
import axios from "axios";
import "./SignUpForm0.css";

const SignUpForm0 = ({ onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVerifVisible, setPasswordVerifVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVerif, setPasswordVerif] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    const handleMouseUp = () => {
      setPasswordVisible(false);
      setPasswordVerifVisible(false);
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const validateEmailFormat = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const checkEmailArleadyInUse = async (l_email) => {
    // Check if email is already in the database
    try {
      console.log(
        `Signup URL: ${process.env.REACT_APP_BACKEND_URL}/api/v1/users/signupCheck/${l_email}`
      );
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/signupCheck/${l_email}`
      );

      // Email already in use and account verified
      if (
        res.data.message === "Email is already in use" &&
        res.data.data.verified === true
      ) {
        setErrorMessage("Email is already in use. Please, log in.");
        return true;
      } else if (
        res.data.message === "Email is already in use" &&
        res.data.data.verified === false
      ) {
        // Email already in use and account NOT verified --> Send verification email
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/sendVerificationEmail`,
          {
            email,
            password,
            passwordConfirm: passwordVerif,
          }
        );
        setErrorMessage(
          "Email is already in use but account not verified. New verification email sent. Please, check your email."
        );
        return true;
      }
    } catch (error) {
      console.error("Signup Check error:", error);
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
      } else {
        // Something else happened in setting up the request
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
      setErrorMessage("Network error. Please try again later.");
      return true;
    }
    setErrorMessage("");
    return false;
  };

  const validateSignUp = async () => {
    const passwordPattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+=\-])[A-Za-z\d@$!%*#?&_+=\-]{8,}$/;

    if (!email || !password || !passwordVerif) {
      setErrorMessage("Please fill in all fields.");
      return false;
    } else if (password !== passwordVerif) {
      setErrorMessage("Passwords do not match.");
      return false;
    } else if (!validateEmailFormat(email)) {
      setErrorMessage("Please provide a valid email address.");
      return false;
    } else if (!passwordPattern.test(password)) {
      setErrorMessage(
        "Password must have at least 8 characters, including letters, numbers, and special characters."
      );
      return false;
    } else {
      if (await checkEmailArleadyInUse(email)) {
        return false;
      }
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validate Sign Up:
    const valid = await validateSignUp();
    if (valid) {
      onSubmit({
        email,
        password,
        passwordConfirm: passwordVerif,
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordMouseDown = () => {
    setPasswordVisible(true);
  };

  const handlePasswordMouseUp = () => {
    setPasswordVisible(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordVerifMouseDown = () => {
    setPasswordVerifVisible(true);
  };

  const handlePasswordVerifMouseUp = () => {
    setPasswordVerifVisible(false);
  };

  const handlePasswordVerifChange = (e) => {
    setPasswordVerif(e.target.value);
  };

  const successResponseGoogle = async (response) => {
    setEmail(response.profileObj.email);

    const emailInUse = await checkEmailArleadyInUse(response.profileObj.email);
    if (response.tokenId && !emailInUse) {
      onSubmit({
        googleId: response.profileObj.googleId,
        email: response.profileObj.email,
        // firstName: response.profileObj.givenName,
        // lastName: response.profileObj.familyName,
      });

      // console.log("Google: ", response);
      // // Send response.tokenId to your backend to verify and create/sign in the user
      // axios
      //   .post(
      //     `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/googleSignUp`,
      //     {
      //       email: response.profileObj.email, // Pass the email obtained from Google
      //     }
      //   )
      //   .then((res) => {
      //     // Handle successful signup/signin here
      //     // For example, redirect the user, display a success message, etc.
      //   })
      //   .catch((err) => {
      //     // Handle errors here
      //     console.error("Google Sign-In error:", err);
      //     setErrorMessage(
      //       "An error occurred with Google Sign-In. Please try again."
      //     );
      //   });
    } else if (!emailInUse) {
      // Handle errors or rejections by the user
      setErrorMessage("Google Sign-In was unsuccessful. Please try again.");
    } else {
      console.log("Sign up error: ", emailInUse, response.tokenId);
      setErrorMessage(
        "Google Sign-Up was unsuccessful. Please try again later or contact the administrator."
      );
    }
  };

  const failureResponseGoogle = (error) => {
    console.error("Google Sign-Un error:", error);
    setErrorMessage(
      "Google Sign-Un was unsuccessful. Please try again later or contact the administrator."
    );
  };

  return (
    <div className="colored-section login-page">
      <div className="login-container">
        <h2 className="login-heading">Create your free account</h2>
        <form className="login-form">
          <input
            type="text"
            placeholder="Email"
            className="login-input"
            onChange={handleEmailChange}
            autoComplete="email"
          />
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
            <span
              className="password-toggle-icon"
              onMouseDown={handlePasswordMouseDown}
              onMouseUp={handlePasswordMouseUp}
              onTouchStart={handlePasswordMouseDown}
              onTouchEnd={handlePasswordMouseUp}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div className="password-field">
            <input
              type={passwordVerifVisible ? "text" : "password"}
              placeholder="Password verification"
              className="login-input"
              value={passwordVerif}
              onChange={handlePasswordVerifChange}
              autoComplete="new-password"
            />
            <span
              className="password-toggle-icon"
              onMouseDown={handlePasswordVerifMouseDown}
              onMouseUp={handlePasswordVerifMouseUp}
              onTouchStart={handlePasswordVerifMouseDown}
              onTouchEnd={handlePasswordVerifMouseUp}
            >
              {passwordVerifVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <EIIButton
            text="Sign Up"
            color="green"
            btnBig
            fullWidth
            onClickHandler={handleSignUp}
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
        <div className="or-separator">
          <div className="or-line"></div>
          <span className="or-text">or</span>
          <div className="or-line"></div>
        </div>
        <GoogleLoginButton
          buttonText="Continue with Google"
          onSuccess={successResponseGoogle}
          onFailure={failureResponseGoogle}
          color="green"
        />

        <div className="login-options">
          <a href="#/LogIn" className="login-link">
            Already have an account? Sign in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm0;
