import React from "react";
import TitleTextLeft from "../global_utils/TitleTextLeft";
import PostParagraph from "../page_home/PostParagraph";

const TermsAndConditions = () => {
  return (
    <>
      <div className="content-section">
        <TitleTextLeft
          title="Terms and Conditions"
          mainTitle={true}
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Welcome to EngineersInIreland.com. The following terms and conditions govern your use of this website, operated by Leno Engineering Limited. By accessing EngineersInIreland.com, you acknowledge and accept these terms and conditions in full. Should you disagree with any part of these terms, refrain from using this website." />
              <PostParagraph text="These terms apply to all visitors, users, and others who access or use the Service. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms." />
            </>
          }
        />

        <TitleTextLeft
          title="Data Protection and Privacy"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Our commitment to your privacy and data security is unwavering. We adhere strictly to the General Data Protection Regulation (GDPR) and other relevant data protection laws. Personal data collected is used solely for the purposes outlined in these terms and is processed lawfully, fairly, and in a transparent manner." />
              <PostParagraph text="Personal data is collected with your explicit consent and is necessary for the performance of our services or to comply with legal obligations. You have the right to access, modify, or request the deletion of your personal data at any time." />
              <PostParagraph text="We implement robust technical and organizational measures to safeguard your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage." />
            </>
          }
        />

        <TitleTextLeft
          title="Comprehensive Data Usage Policy"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="The personal data we collect, including but not limited to your name, email, phone number, educational background, and professional experience, is primarily used to connect you with potential employers and recruiters in Ireland and abroad. This data may be shared with recruiters, recruitment agencies, or other entities seeking engineering professionals." />
              <PostParagraph text="You retain control over your visibility to potential employers. This can be managed in the User Area under Settings, where you can enable or disable the feature allowing recruiters to view your profile." />
            </>
          }
        />

        <TitleTextLeft
          title="Consent, Withdrawal, and User Rights"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Consent for processing your personal data is obtained explicitly through your acceptance of these Terms and Conditions during the registration process. You may withdraw your consent and request the deletion of your account and associated data at any time through the User Area under Settings." />
              <PostParagraph text="In accordance with GDPR, you have several rights regarding your data, including the right to access, rectify, erase, restrict processing, object to processing, and the right to data portability. Requests to exercise these rights can be directed to our contact details provided below." />
            </>
          }
        />

        <TitleTextLeft
          title="Data Sharing and Third-Party Processors"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Your personal data may be shared with trusted third-party service providers, including recruiters and recruitment agencies. These entities are carefully selected to ensure compliance with GDPR and are permitted to process your data solely for the purposes for which it was collected." />
            </>
          }
        />

        <TitleTextLeft
          title="Data Retention Policy"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="Your personal data is retained only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. Upon deactivation or deletion of your account, your personal data is promptly removed from our systems." />
            </>
          }
        />

        <TitleTextLeft
          title="Security Measures and Data Integrity"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="We are committed to maintaining the highest level of data security. Our servers are equipped with advanced security technologies and protocols to protect your personal data from unauthorized access, alteration, disclosure, or destruction." />
            </>
          }
        />

        <TitleTextLeft
          title="Modifications to the Terms and Conditions"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="We reserve the right to modify these Terms and Conditions at any time. Such changes will be effective immediately upon posting on our website. We will notify you of significant changes through email or a prominent notice on our website." />
            </>
          }
        />

        <TitleTextLeft
          title="Contact and Data Protection Officer"
          title_upper_space={true}
          content_after_title={
            <>
              <PostParagraph text="For any queries or concerns regarding your personal data or these Terms and Conditions, please contact us at engineersinireland@lenoeng.ie." />
            </>
          }
        />

        {/* Additional sections and legal disclaimers can be added here */}
      </div>
    </>
  );
};

export default TermsAndConditions;
