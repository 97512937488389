import React, { useState, useEffect, useRef } from "react";
import SplitScreen from "../global_utils/SplitScreen";
import CompletionRectangles from "./CompletionRectangles";
import SignUpForm1PagePersonalData from "./SignUpForm1PagePersonalData";
import SignUpForm1PageProfessionalExp from "./SignUpForm1PageProfessionalExp";
import SignUpForm1PageStudies from "./SignUpForm1PageStudies";
import SignUpForm1PageEnglishLevel from "./SignUpForm1PageEnglishLevel";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SignUpForm1.css";

const SignUpForm1 = ({ onSubmit }) => {
  const numPages = 4;
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const leftTopRowRef = useRef(null);

  const rightSideRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      // On mobile, scroll the container
      const container = document.querySelector(".split-screen-container");
      if (container) {
        container.scrollTop = 0;
      }
    } else {
      // On desktop, scroll only the right side
      const rightSideElement = document.querySelector(".split-screen-right");
      if (rightSideElement) {
        rightSideElement.scrollTop = 0;
      }
    }
  }, [formPage]);

  useEffect(() => {
    const handleResize = () => {
      if (leftTopRowRef.current) {
        const leftHeight = leftTopRowRef.current.offsetHeight;
        const rightTopRow = document.querySelector(".right-top-row");
        const rightHeight = rightTopRow ? rightTopRow.offsetHeight : 0;

        const minHeight = leftHeight >= rightHeight ? leftHeight : "80%";
        setLeftTopRowHeight(minHeight);
      }
    };

    handleResize(); // Call once on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [leftTopRowHeight, setLeftTopRowHeight] = useState("80%");

  // Get the computed styles of the document
  const styles = getComputedStyle(document.documentElement);

  // Access the values of CSS variables
  const leftColor = styles.getPropertyValue("--footer_bkg_color");
  const rightColor = "white";
  const rectangleActiveColor = styles.getPropertyValue("--title_green_color");

  // Create an additional style to compensate navbar height
  const compensateNavbarStyles = {};

  // Submit button handler
  const submitButtonHandler = async (data) => {
    // Set submitting to true
    setSubmitting(true);

    // Deep copy data object
    const dataCopy = JSON.parse(JSON.stringify(data));
    const formDataCopy = JSON.parse(JSON.stringify(formData));

    // Replace existing objects in formDataCopy with the ones in dataCopy
    for (const key in formDataCopy) {
      if (dataCopy.hasOwnProperty(key)) {
        formDataCopy[key] = dataCopy[key];
      }
    }

    // Add any new objests that didn't previously exist
    for (const key in dataCopy) {
      if (!formDataCopy.hasOwnProperty(key)) {
        formDataCopy[key] = dataCopy[key];
      }
    }

    // Submit the data
    await onSubmit(formDataCopy);

    // Set submitting to false
    setSubmitting(false);
  };

  // Next button handler
  const nextButtonHandler = (data) => {
    // Deep copy data object
    const dataCopy = JSON.parse(JSON.stringify(data));

    // Add the received objects to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...dataCopy,
    }));

    // Increase the page number
    if (formPage < numPages) setFormPage(formPage + 1);
  };

  // Back button handler
  const backButtonHandler = (data) => {
    // Deep copy data object
    const dataCopy = JSON.parse(JSON.stringify(data));

    // Add the received objects to formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...dataCopy,
    }));

    // Decrease page number
    if (formPage > 1) setFormPage(formPage - 1);
  };

  // Asign singUpFormPage
  let rightContent;
  switch (formPage) {
    case 1:
      rightContent = (
        <SignUpForm1PagePersonalData
          onClickNextHandler={nextButtonHandler}
          formData={formData}
        />
      );
      break;
    case 2:
      rightContent = (
        <SignUpForm1PageStudies
          onClickNextHandler={nextButtonHandler}
          onClickBackHandler={backButtonHandler}
          formData={formData}
        />
      );
      break;
    case 3:
      rightContent = (
        <SignUpForm1PageProfessionalExp
          onClickNextHandler={nextButtonHandler}
          onClickBackHandler={backButtonHandler}
          formData={formData}
        />
      );
      break;
    case 4:
      rightContent = (
        <SignUpForm1PageEnglishLevel
          onClickNextHandler={submitButtonHandler}
          onClickBackHandler={backButtonHandler}
          formData={formData}
          submitting={submitting}
        />
      );
      break;
    default:
      rightContent = null;
  }

  return (
    <div className="signupform-main-container" style={compensateNavbarStyles}>
      <SplitScreen
        leftColor={leftColor}
        rightColor={rightColor}
        ref={rightSideRef}
        leftContent={
          <div className="container left-side-container">
            <div
              className="row left-top-row"
              ref={leftTopRowRef}
              style={{ minHeight: leftTopRowHeight }}
            >
              <div className="col centered-content">
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Complete your CV
                  <br />
                  to find a job
                  <br />
                  in Ireland!
                </h2>
              </div>
            </div>
            <div className="row left-bottom-row">
              <div className="col left-bottom-col">
                <CompletionRectangles
                  numRectangles={numPages}
                  numRectanglesActive={formPage}
                  rectangleActiveColor={rectangleActiveColor}
                />
              </div>
            </div>
          </div>
        }
        rightContent={rightContent}
      />
    </div>
  );
};

export default SignUpForm1;
