import React from "react";
import PostParagraph from "../PostParagraph";
import PostTip from "../PostTip";
import PostTpl from "../PostTpl";
import "./Posts.css";

const PostDublinAccommodation = () => {
  return (
    <>
      <PostTpl
        title="How to find a job as an engineer in Ireland"
        date="28 MAR 2023"
        type="WORK"
        content={
          <>
            <PostParagraph text="Ireland’s economy has undergone a notorious transformation in the last decades, particularly in its industrial sector. One of the factors that contributed to Ireland’s industrial growth is its low corporation tax, which has attracted many companies from around the world. This has led to a fast development of the country’s industrial sector, particularly in areas such as pharmaceuticals, electronics, and software." />

            <PostParagraph
              text={
                <p className="content-p">
                  According to the{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://www.engineersireland.ie/LinkClick.aspx?fileticket=BXNBOKPanbY%3d&portalid=0&resourceView=1"
                  >
                    Engineering Barometric Report
                  </a>{" "}
                  published in 2022, 81% of engineering directors and managers
                  were confident about the growth in their organizations. Many
                  sectors require qualified engineers due to their development,
                  such as construction and consultancy, and they expect to
                  require more than{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="colored-a"
                    href="https://www.engineersireland.ie/News/engineers-ireland-forecasts-almost-8000-sectoral-jobs-for-2023"
                  >
                    8,000 new engineering positions in 2023
                  </a>
                  .
                </p>
              }
            />

            <PostParagraph text="Ireland is not capable of fulfilling all the engineering positions on its own and that’s why companies require engineers that come from abroad. Many engineers come to Ireland every year attracted by the reputation of the company firms such as Google, Facebook or Amazon and the high salaries of the country. However, how can you as a foreigner find an engineering job in Ireland? Don’t worry, we will explain to you all that you need to know!" />

            <h3 className="post-subtitle">
              Job portals to find engineering jobs in Ireland
            </h3>
            <PostParagraph text="Many local and global portals can help you to find a job in Ireland. This is the list of the most relevant portals to find an engineering job in the country:" />
            <PostParagraph
              text={
                <ol className="content-p">
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.linkedin.com/"
                    >
                      LinkedIn
                    </a>{" "}
                    – This has become the most popular business networking
                    platform where firms based in Ireland post engineering job
                    offers.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://ie.indeed.com/"
                    >
                      Indeed
                    </a>{" "}
                    – This platform aggregates job posting from different
                    sources, making the job search easily accessible from one
                    place.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.monster.ie/"
                    >
                      Monster
                    </a>{" "}
                    – This is one of the most worldwide known job platforms with
                    many job listings and career resources.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.irishjobs.ie/"
                    >
                      IrishJobs
                    </a>{" "}
                    – This is the leading job search website in Ireland
                    containing hundreds of job offers within the country.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://jobsireland.ie/"
                    >
                      JobsIreland
                    </a>{" "}
                    – This is the national employment portal for Ireland that
                    provides job listings and employment services to job
                    seekers.
                  </li>
                  <li className="post-list">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="post-bold-text colored-a"
                      href="https://www.engineersireland.ie/Find-a-job/"
                    >
                      EngineersIreland
                    </a>{" "}
                    – A professional organization for engineers in Ireland that
                    contain national job listings focused on the engineering
                    sector.
                  </li>
                </ol>
              }
            />

            <PostTip
              title="Set job alerts to stay notified!"
              text="Even if you can’t find the job you are looking for immediately, you can configure your search filters and get notified when new jobs are posted. Being quick to apply for a job will also increase the chance of entering the selection process!"
            />

            <PostTip
              title="Contact engineers in Ireland on LinkedIn"
              text="You can send LinkedIn connections to engineers in your field that are already living and working in Ireland. Message them and tell them your intentions to work in Ireland, maybe they can recommend you to their company or provide you with valuable information such as recruiter contacts, salary ranges, etc. Don’t be shy, many nice people are willing to help others!"
            />

            <PostTip
              title="Search Irish recruiters on LinkedIn"
              text="Irish recruiters might not have visibility over your profile as you are not living in the country. However, you can go to the LinkedIn search bar and type something such as “Civil Engineering Recruiter Ireland” and send them a connection request and message them asking for potential jobs. Most likely they will provide you with interesting opportunities that match your profile!"
            />
          </>
        }
      />
    </>
  );
};

export default PostDublinAccommodation;
