import React, { useState, useEffect } from "react";
import TitleTextLeft from "../global_utils/TitleTextLeft";
import CenterTitleTextCont from "./CenterTitleTextCont";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const AboutUsSection = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleZoomIn = () => {
    setIsZoomed(true);
  };

  const handleZoomOut = () => {
    // setIsZoomed(false);
  };

  // Define the text content in a constant
  const textContent = (
    <>
      <p className="content-p">
        Welcome to Engineers in Ireland, where we unite as a diverse group of
        engineering professionals from around the globe. Our shared journey to
        Ireland is marked by a pursuit of career advancement in an environment
        rich with opportunities. This collective experience, involving the
        challenge of adapting to a new professional and cultural landscape,
        forms the core of our mission. We are here to offer support and
        inspiration to engineers contemplating a similar path to ours.
      </p>
      <p className="content-p">
        Understanding the challenges of relocating for work, our community
        extends far beyond basic guidance. We are a comprehensive resource for
        engineers navigating the intricacies of such a significant life
        transition. From offering in-depth insights into Ireland’s evolving job
        market to providing advice on legal and relocation aspects, our goal is
        to facilitate a seamless integration into Ireland’s unique professional
        environment.
      </p>
      <p className="content-p">
        At the heart of Engineers in Ireland lies our commitment to fostering a
        vibrant culture of collaboration and learning. We believe in the power
        of sharing experiences and pooling knowledge for collective growth. Our
        community organizes an array of activities, including technical
        workshops, mentorship sessions, and cultural exchange events, all
        designed to enrich the professional and personal lives of our members.
      </p>
      <p className="content-p">
        As part of our initiative, we emphasize building strong connections not
        just within the engineering sector, but also within the wider Irish
        community. We encourage our members to engage in local projects and
        initiatives, fostering a deeper understanding and appreciation of
        Ireland’s rich heritage and contemporary landscape. This involvement not
        only aids in professional networking but also enhances the overall
        experience of living and working in Ireland.
      </p>
      <p className="content-p">
        Joining Engineers in Ireland means becoming part of a community that
        values innovation, mutual support, and collective achievement. We warmly
        invite engineers from all over the world to join our journey. Together,
        we can navigate the challenges, celebrate the successes, and contribute
        to shaping a thriving and fulfilling future in Ireland’s dynamic
        engineering industry.
      </p>
    </>
  );

  return (
    <section
      id="AboutUs"
      className="about-us-section"
      onMouseEnter={handleZoomIn}
      onMouseLeave={handleZoomOut}
      onTouchStart={handleZoomIn}
      onTouchEnd={handleZoomOut}
    >
      <div className="content-section">
        {/* Smaller than tablet version - Two rows and text centered */}
        {isTablet && (
          <CenterTitleTextCont
            title={"About us"}
            text={
              <div
                className={`about-us-img-circle ${isZoomed ? "zoomed" : ""}`}
                style={{
                  backgroundImage: `url(${
                    require("../images/AboutUs/Icon_2.svg").default
                  })`,
                  marginBottom: "40px",
                }}
              />
            }
            content={textContent}
          />
        )}

        {/* Bigger than tablet version - Two columns in the same row and text on the left */}
        {!isTablet && (
          <Row className="for-rec-reverse-colum-sm">
            <Col sm={12} md={12} lg={10}>
              <TitleTextLeft
                title="About us"
                mainTitle={true}
                text_content={textContent}
                justifyContent={true}
              />
            </Col>
            {!isTablet && (
              <Col sm={12} md={12} lg={2}>
                <div
                  className={`about-us-img-circle ${isZoomed ? "zoomed" : ""}`}
                  style={{
                    backgroundImage: `url(${
                      require("../images/AboutUs/Icon_2.svg").default
                    })`,
                  }}
                />
              </Col>
            )}
          </Row>
        )}
      </div>
    </section>
  );
};

export default AboutUsSection;
