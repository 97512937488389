import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../global_utils/UserProvider";
import EIIButton from "../global_utils/EIIButton";
import UASingleProfessionalExp from "./UASingleProfessionalExp";
import SeparationLine from "../global_utils/SeparationLine";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";

const UAProfessionalExps = () => {
  const [addingProfessionalExp, setAddingProfessionalExp] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { user, AddProfessionalExp } = useContext(UserContext);

  // Clear deleted message
  useEffect(() => {
    if (feedbackMessage) {
      const timer = setTimeout(() => {
        setFeedbackMessage("");
      }, 3000); // 3 seconds for the animation duration

      return () => clearTimeout(timer);
    }
  }, [feedbackMessage]);

  // Helper function to convert month names to numbers
  const monthToNumber = (monthName) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(monthName) + 1;
  };

  const sortExperiences = (experiences) => {
    // Add original index to each experience
    const experiencesWithIndex = experiences.map((experience, index) => ({
      ...experience,
      originalIndex: index,
      numericStartingMonth: monthToNumber(experience.startingMonth),
      numericEndingMonth: experience.endingMonth
        ? monthToNumber(experience.endingMonth)
        : 12,
      isPopulated: experience.engPosition && experience.companyName, // Check if key fields are populated
    }));

    // Sort the experiences
    experiencesWithIndex.sort((a, b) => {
      // Prioritize fully populated entries
      if (a.isPopulated !== b.isPopulated) {
        return a.isPopulated ? -1 : 1;
      }

      const endYearA = a.endingYear || 9999;
      const endYearB = b.endingYear || 9999;
      const endMonthA = a.numericEndingMonth;
      const endMonthB = b.numericEndingMonth;

      if (endYearA !== endYearB) return endYearB - endYearA;
      if (endMonthA !== endMonthB) return endMonthB - endMonthA;
      if (a.startingYear !== b.startingYear)
        return b.startingYear - a.startingYear;
      return b.numericStartingMonth - a.numericStartingMonth;
    });

    return experiencesWithIndex;
  };

  const addNewProfessionalExpHandler = async () => {
    // Check if there's already a professional experience with an empty 'engPosition' field
    const hasEmptyProfessionalExp = user.experiences.some(
      (exp) => !exp.engPosition
    );

    if (hasEmptyProfessionalExp) {
      alert(
        "Please fill out all existing professional experiences before adding a new one."
      );
      return;
    }

    // Send the backend a new professional experience
    setAddingProfessionalExp(true);
    await AddProfessionalExp();
    setAddingProfessionalExp(false);
  };

  const deleteProfessionalExpHandler = async () => {
    setFeedbackMessage("Professional experience has been deleted");
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className="signupform-title">Professional Experience</h2>
            <div className="row">
              {user &&
                sortExperiences(user.experiences).map((item, index) => (
                  <React.Fragment key={item.id || index}>
                    <UASingleProfessionalExp
                      professionalExpIdx={item.originalIndex}
                      deleteProfessionalExpHandler={
                        deleteProfessionalExpHandler
                      }
                    />
                    <SeparationLine />
                  </React.Fragment>
                ))}

              {/* Feedback message */}
              {feedbackMessage && (
                <div className="ua-feedback-message">{feedbackMessage}</div>
              )}
            </div>
            <div className="row">
              <div className="subform-add-button-div subform-btn-col-center">
                <EIIButton
                  text={"+Add a new professional experience"}
                  color={"green"}
                  btnBig={false}
                  onClickHandler={addNewProfessionalExpHandler}
                  disabled={addingProfessionalExp}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UAProfessionalExps;
