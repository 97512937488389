import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import EIIButton from "../global_utils/EIIButton";
import { UserContext } from "../global_utils/UserProvider";
import FormFieldCheckbox from "../global_utils/FormFieldCheckbox";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const UASettings = () => {
  const { user, RefreshUserData, DeleteUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); // React Router v6 useNavigate hook
  const [enableSubmit, setEnableSubmit] = useState(true);

  const [fieldsData, setFieldsData] = useState({});

  const [employerVisibility, setEmployerVisibility] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });
  const [editEmployerVisibility, setEditEmployerVisibility] = useState({
    value: "",
    isValid: false,
    isRequired: true,
  });

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      employerVisibility: editEmployerVisibility,
    });
  }, [editEmployerVisibility]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  // Submit data when value is modified
  useEffect(() => {
    if (user && employerVisibility.value !== "") {
      submitDataHandler();
    }
  }, [employerVisibility]);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      employerVisibility: {
        value: uData?.profileVisibleToEmployers || false,
        isValid: editEmployerVisibility.isValid,
        isRequired: editEmployerVisibility.isRequired,
      },
    };

    // Initialize Language
    setEditEmployerVisibility(newFormData.employerVisibility);
  };

  // Handler to sumbit data to the backend
  const submitDataHandler = async () => {
    // Disable submit
    setEnableSubmit(false);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
        {
          profileVisibleToEmployers: employerVisibility.value,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
          },
        }
      );

      if (response.data.status === "success") {
        // Ask UserProvider to refresh user data
        RefreshUserData();

        // Wait for 3 seconds before re-enabling submit
        setTimeout(() => {
          setEnableSubmit(true);
        }, 2000);
      } else {
        console.error("Failed to update user");
        // Enable submit
        setEnableSubmit(true);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  // Handler to delete user
  const deleteUserHandler = async () => {
    setShowModal(false); // Close the modal
    await DeleteUser(); // Call the function to delete the user

    // Navigate to Home Page
    navigate("/"); // Navigate to the home page
  };

  // Handler to change in employerVisibility
  const handleEmployersVisibilityChange = (value) => {
    // Only change data if value has been upadted
    if (value.value !== user.profileVisibleToEmployers) {
      setEditEmployerVisibility(value);
      setEmployerVisibility(value);
    }
  };

  // Handler to show the confirmation modal
  const showDeleteConfirmation = () => {
    setShowModal(true);
  };

  // Handler to change password
  const changePasswordHandler = () => {
    navigate("/ForgotPassword"); // Navigate to the home page
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className={`signupform-title`}>Settings</h2>
            <p className={"formfield-p"}>Privacy Policy</p>
            <div className="ua-content-div">
              <p className={"ua-content-p"}>
                Access our privacy policy{" "}
                <a className="ua-content-p colored-a" href="#/PrivacyPolicy">
                  here
                </a>
                .
              </p>
            </div>
            <p className={"formfield-p"}>Visibility to potential employers</p>
            <div className="ua-content-div">
              <FormFieldCheckbox
                checkboxLabel={
                  <p style={{ marginBottom: 0 }} className="ua-content-p">
                    I want my profile to be visible to potential employers
                  </p>
                }
                fieldEnabled={enableSubmit}
                isRequired={true}
                isVisible={true}
                activateRequired={false}
                onFieldChange={handleEmployersVisibilityChange}
                value={editEmployerVisibility.value}
              />
            </div>
            <p className={"formfield-p"}>Change your password</p>
            <div className="ua-content-div">
              <EIIButton
                text={"Change password"}
                color={"green"}
                transparent
                btnBig={false}
                onClickHandler={changePasswordHandler}
              />
            </div>
            <p className={"formfield-p"}>Delete User</p>
            <div className="ua-content-div">
              <EIIButton
                text={"Delete user"}
                color={"gray"}
                transparent
                btnBig={false}
                onClickHandler={showDeleteConfirmation}
              />
            </div>
          </div>
        </div>
        <div className="row right-bottom-row">
          {/* Add something content if required */}
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteUserHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UASettings;
