import React, { useState } from "react";
import SignUpForm0 from "./SignUpForm0";
import SignUpForm1 from "./SignUpForm1";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  const navigate = useNavigate();

  const [form0Data, setForm0Data] = useState({});
  const [showForm1, setShowForm1] = useState(false);

  const handleSubmitForm0 = (submitData) => {
    setForm0Data(submitData);
    setShowForm1(true);
  };

  const handleSubmitForm1 = async (submitData) => {
    try {
      let studiesData = [];
      if (submitData.isEngineer.value === "Yes") {
        studiesData = [
          {
            studyLevel: submitData.studyLevel.value.value,
            degreeTitle: submitData.degreeTitle.value,
            studyCenter: submitData.studyCenter.value,
            startingMonth: submitData.studyStartingMonth.value.value,
            startingYear: submitData.studyStartingYear.value,
            endingMonth: submitData.studyEndingMonth.value.value,
            endingYear: submitData.studyEndingYear.value,
          },
        ];
      }

      let experiencesData = [];
      if (
        submitData.currentlyWorkingEng.value === "Yes" ||
        submitData.prevExpEng.value === "Yes"
      ) {
        experiencesData = [
          {
            engPosition: submitData.engPosition.value,
            engIndustry: submitData.engIndustry.value.value,
            companyName: submitData.companyName.value,
            startingMonth: submitData.jobStartingMonth.value?.value || "",
            startingYear: submitData.jobStartingYear.value,
            endingMonth: submitData.jobEndingMonth.value?.value || "",
            endingYear: submitData.jobEndingYear.value,
          },
        ];
      }

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/signup`,
        {
          email: form0Data.email,
          googleId: form0Data.googleId,
          role: "user",
          password: form0Data.password,
          passwordConfirm: form0Data.passwordConfirm,
          firstName: submitData.firstName.value,
          lastName: submitData.lastName.value,
          dateOfBirth: submitData.dateOfBirth.value,
          gender: submitData.gender.value.value,
          phonePrefix: submitData.phonePrefix.value,
          phoneNumber: submitData.phoneNumber.value,
          firstCitizenship: submitData.firstCitizenship.value.value,
          secondCitizenship: submitData.secondCitizenship.value?.value || "",
          readinessToStart: submitData.readinessToStart.value.value,
          preferredWorkLocation: submitData.preferredWorkLocation.value.value,
          isEngineer: submitData.isEngineer.value,
          termsAndConditionsAccepted:
            submitData.termsAndConditionsAccepted.value,
          studies: studiesData,
          experiences: experiencesData,
          skills: submitData.skillsAddedList,
          languages: [
            {
              language: "English",
              grammarLevel: submitData.englishGrammarLevel.value.value,
              speakingLevel: submitData.englishSpeakingLevel.value.value,
              certificate: submitData.englishCertificate.value,
              certificateGrade: submitData.gradeCertificate.value,
              certificateIssuanceYear: submitData.yearEnglishCertificate.value,
            },
          ],
        }
      );

      navigate("/VerificationEmailSent");
    } catch (error) {
      console.error("Signup Check error:", error);
      return false;
    }
  };

  return (
    <div>
      {showForm1 ? (
        <SignUpForm1 onSubmit={handleSubmitForm1} />
      ) : (
        <SignUpForm0 onSubmit={handleSubmitForm0} />
      )}
    </div>
  );
};

export default SignUpPage;
