import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { UserContext } from "../global_utils/UserProvider";
import EIIButton from "../global_utils/EIIButton";
import FormFieldSelect from "../global_utils/FormFieldSelect";
import TagPill from "../global_utils/TagPill";
import "bootstrap/dist/css/bootstrap.min.css";
import "./UserArea.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import skillsObj from "../../assets/data/skills.json";

const UASkills = () => {
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [editEnable, setEditEnable] = useState(false);
  const { user, RefreshUserData } = useContext(UserContext);

  // State for information message
  const [infoMessage, setInfoMessage] = useState("");

  // State for buttonBig
  const [buttonBig, setButtonBig] = useState(window.innerWidth > 768);

  // State for activateRequired
  const [activateRequiredFields, setActivateRequiredFields] = useState(false);

  // State for fieldsData (used to pass data upstream)
  const [fieldsData, setFieldsData] = useState({});

  // State for FormFields
  const [skills, setSkills] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [editSkills, setEditSkills] = useState({
    value: "",
    isValid: false,
    isRequired: false,
  });
  const [skillsAddedList, setSkillsAddedList] = useState([]);
  const [editSkillsAddedList, setEditSkillsAddedList] = useState([]);

  // useEffect Hook for modifying fieldsData
  useEffect(() => {
    setFieldsData({
      skills: editSkillsAddedList,
    });
  }, [editSkillsAddedList]);

  // User data is updated
  useEffect(() => {
    if (user) {
      transformAndSetUserData(user);
    }
  }, [user]);

  // useEffect hook for resizing window and next/back/submit buttons
  useEffect(() => {
    const handleResize = () => {
      setButtonBig(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const transformAndSetUserData = (uData) => {
    const newFormData = {
      skills: {
        value: uData.skills || [],
        isValid: true,
        isRequired: true,
      },
    };

    // Initialize skills
    setSkillsAddedList(newFormData.skills.value);
    setEditSkillsAddedList(newFormData.skills.value);
  };

  const submitSkillsData = async () => {
    // Disable submit
    setEnableSubmit(false);

    // Validate all required fields
    const allRequiredFieldsValid = Object.keys(fieldsData).every(
      (key) => !fieldsData[key].isRequired || fieldsData[key].isValid // The field is required and valid
    );

    // Only proceed if all required fields are valid
    if (allRequiredFieldsValid) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/updateMe`,
          {
            skills: fieldsData.skills,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwt_eii")}`,
            },
          }
        );

        if (response.data.status === "success") {
          // Ask UserProvider to refresh user data
          RefreshUserData();

          // Save the edited skillsAddedList
          setSkillsAddedList(editSkillsAddedList);
        } else {
          console.error("Failed to update user");
        }

        // Enable submit
        setEnableSubmit(true);
      } catch (error) {
        console.error("Error updating user data:", error);
        // Enable submit
        setEnableSubmit(true);
      }

      setEditEnable(false);
    } else {
      // Activate Required Fields
      setActivateRequiredFields(true);

      // Enable submit
      setEnableSubmit(true);
    }
  };

  // Routine to extract skills and sort them alphabetically
  const extractAndSortSkills = (skillsObj) => {
    let skList = [];

    const extractSkills = (obj) => {
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          skList = skList.concat(obj[key]);
        } else if (typeof obj[key] === "object") {
          extractSkills(obj[key]);
        }
      }
    };

    extractSkills(skillsObj);

    // Create a set from the list to remove duplicates and then convert it back to an array
    const uniqueSkills = Array.from(new Set(skList));

    // Sort the array alphabetically
    return uniqueSkills.sort();
  };

  // Routine to update skills into editSkillsAddedList
  const updateSkills = (data) => {
    // Check if the data is not empty and not already in the skillsAddedList
    if (data && data !== "" && !skillsAddedList.includes(data)) {
      setEditSkillsAddedList((prevSkills) => [...prevSkills, data]);
    }
  };

  // Routine to remove skills from editSkillsAddedList
  const removeSkill = (skillToRemove) => {
    setEditSkillsAddedList((prevSkills) =>
      prevSkills.filter((skill) => skill !== skillToRemove)
    );
  };

  // Routine to handle skills
  const handleSkillsChange = (value) => {
    // Add skill and clear field
    if (value.value && value.value.value !== "") {
      updateSkills(value.value.value);
      setEditSkills({ value: "", label: "" });
    }
  };

  // Handler for NEXT button
  const editButtonHandler = () => {
    setEditEnable(true);
  };

  // Handler for CANCEL button
  const cancelHandler = () => {
    // Revert skills list
    setEditSkillsAddedList(skillsAddedList);

    // Deactivate required fields
    setActivateRequiredFields(false);

    // Disable Edit
    setEditEnable(false);
  };

  return (
    <div className="container right-side-container">
      <div className="row right-top-row">
        <div className="right-top-content-container">
          <div className="col">
            <h2 className={`signupform-title`}>Skills</h2>
            {editEnable && (
              <form className="signup-form">
                <div className="row">
                  <FormFieldSelect
                    fieldEnabled={editEnable}
                    inPlaceholder={"Enter a skill and press Enter"}
                    inOptionsArray={extractAndSortSkills(skillsObj)}
                    isRequired={false}
                    activateRequired={activateRequiredFields}
                    onFieldChange={handleSkillsChange}
                    value={editSkills.value}
                    allowTyping={true}
                  />
                </div>
              </form>
            )}
            <div className="tags-pills">
              {editSkillsAddedList.map((tag, index) => (
                <TagPill
                  key={index}
                  tag={tag}
                  onRemove={removeSkill}
                  isEditing={editEnable}
                />
              ))}
            </div>
            {infoMessage && (
              <div className="row">
                <p
                  className="col text-center"
                  style={{
                    color: infoMessage.includes(
                      "nationality doesn't require a working visa in Ireland!"
                    )
                      ? "var(--btn_green_dark2_color)"
                      : "var(--btn_blue_dark_color)",
                    marginTop: "30px",
                  }}
                >
                  {infoMessage.includes(
                    "You require a working visa in Ireland"
                  ) && (
                    <FontAwesomeIcon icon={faFileCircleExclamation} size="1x" />
                  )}
                  {infoMessage}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="row right-bottom-row">
          <div className="row">
            <div className="col-md-6 subform-btn-col-left">
              {editEnable && (
                <EIIButton
                  text={"Cancel"}
                  color={"green"}
                  transparent
                  btnBig={false}
                  onClickHandler={cancelHandler}
                  width="100px"
                  height="50px"
                />
              )}
            </div>
            <div className="col-md-6 subform-btn-col-right">
              {!editEnable && (
                <EIIButton
                  text={"Edit"}
                  color={"green"}
                  transparent
                  btnBig={false}
                  onClickHandler={editButtonHandler}
                  width="100px"
                  height="50px"
                />
              )}
              {editEnable && (
                <EIIButton
                  text={"Submit"}
                  color={"green"}
                  btnBig={false}
                  disabled={!enableSubmit}
                  onClickHandler={submitSkillsData}
                  width="100px"
                  height="50px"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UASkills;
