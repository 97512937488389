import monthsList from "../../assets/data/months.json";

// Routine to return the month number
const getMonthNumber = (monthName) => {
  // Finding the index of the month in the array
  const monthIndex = monthsList.indexOf(monthName);

  // If the month is found, return index + 1, else return 0
  return monthIndex >= 0 ? monthIndex + 1 : 0;
};

// Validate Starting Month Year (not ending)
export const startMonthYearValidation = (
  startingMonthStr,
  startingYearStr,
  setErrorMessageHandler
) => {
  // Current date
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (startingMonthStr !== "" && startingYearStr !== "") {
    const startMonth = getMonthNumber(startingMonthStr);
    const startYear = parseInt(startingYearStr);

    // Invalid starting month
    if (startMonth > 12) {
      setErrorMessageHandler("Invalid starting month");
      return false;
    }

    // Starting date is in the future
    if (
      startYear > currentYear ||
      (startYear === currentYear && startMonth > currentMonth)
    ) {
      setErrorMessageHandler("Starting date can't be in the future");
      return false;
    }
  }

  setErrorMessageHandler(""); // Clear the error message if validation passes
  return true;
};

// Validate Starting / Ending Month and Year
export const startEndMonthYearValidation = (
  startingMonthStr,
  startingYearStr,
  endingMonthStr,
  endingYearStr,
  setErrorMessageHandler
) => {
  // Current date
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (
    startingMonthStr !== "" &&
    startingYearStr !== "" &&
    endingMonthStr !== "" &&
    endingYearStr !== ""
  ) {
    const startYear = parseInt(startingYearStr);
    const endYear = parseInt(endingYearStr);
    const startMonth = getMonthNumber(startingMonthStr);
    const endMonth = getMonthNumber(endingMonthStr);

    // Check starting date
    if (
      !startMonthYearValidation(
        startingMonthStr,
        startingYearStr,
        setErrorMessageHandler
      )
    ) {
      return false;
    }

    // Invalid ending month
    if (endMonth > 12) {
      setErrorMessageHandler("Invalid ending month");
      return false;
    }

    // Ending date is in the future
    if (
      endYear > currentYear ||
      (endYear === currentYear && endMonth > currentMonth)
    ) {
      setErrorMessageHandler("Ending date can't be in the future");
      return false;
    }

    // Ending date is before starting date
    if (
      startYear > endYear ||
      (startYear === endYear && startMonth > endMonth)
    ) {
      setErrorMessageHandler(
        "The ending date can't be earlier than the starting date"
      );
      return false;
    }
  }

  setErrorMessageHandler(""); // Clear the error message if validation passes
  return true;
};
