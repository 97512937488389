import React, { useEffect } from "react";
import { validateTextInput } from "./FormFieldValidation";
import Throbber from "./Throbber";
// import { FiAlertCircle } from "react-icons/fi";
import "./FormField.css";

const FormFieldText = ({
  fieldTitle,
  inPlaceholder,
  isRequired = false,
  activateRequired = false,
  textMaxLength = 50,
  textMinLenght = 0,
  isVisible = true,
  fieldEnabled = true,
  onFieldChange,
  value = "",
  allowOnlyIntegers = false,
  allowOnlyFloats = false,
  loading = false,
  isMultiline = false,
}) => {
  const forceValidate = () => {
    const isValid = validateTextInput(
      value ? value : "",
      isRequired,
      textMaxLength,
      textMinLenght,
      isVisible
    );
    onFieldChange({
      value: value,
      isValid: isValid,
      isRequired,
    });
  };

  useEffect(() => {
    forceValidate();
  }, [value, isVisible]);

  const handleInputChange = (e) => {
    let val = e.target.value;

    if (allowOnlyIntegers) {
      val = val.replace(/\D/g, ""); // Removes non-digits
    } else if (allowOnlyFloats) {
      // Allows digits and only one dot
      val = val
        .split("")
        .filter((char, index, array) => {
          if (char === ".") {
            return array.indexOf(".") === index; // Allow only the first dot
          }
          return !isNaN(char); // Allow digits
        })
        .join("");
    }

    onFieldChange({
      value: val,
      isValid: validateTextInput(
        val,
        isRequired,
        textMaxLength,
        textMinLenght,
        isVisible
      ),
      isRequired,
    });
  };

  if (!isVisible) {
    return null;
  }

  // Set title-blocked class
  let title_blocked_class = "";
  if (!fieldEnabled) title_blocked_class = "title-blocked";

  const inputClass =
    activateRequired &&
    !validateTextInput(
      value,
      isRequired,
      textMaxLength,
      textMinLenght,
      isVisible
    )
      ? "formfield-input-wrapper required"
      : "formfield-input-wrapper";

  return (
    <>
      {fieldTitle && (
        <p className={`formfield-p ${title_blocked_class}`}>
          {fieldTitle}
          {isRequired && fieldEnabled && (
            <span className="required-asterisk">*</span>
          )}
          {!isRequired && fieldEnabled && <span> (optional)</span>}
        </p>
      )}
      <div className={""}>
        {loading ? (
          <Throbber justify="left" className={"loading-field"} />
        ) : isMultiline ? (
          <textarea
            placeholder={inPlaceholder}
            className={`formfield-input formfield-textarea ${inputClass}`}
            value={value}
            disabled={!fieldEnabled}
            onChange={handleInputChange}
            maxLength={textMaxLength}
            rows={6} // You can adjust the number of rows or make it a prop
          />
        ) : (
          <>
            <input
              type={"text"}
              placeholder={inPlaceholder}
              className={`formfield-input ${inputClass}`}
              value={value}
              disabled={!fieldEnabled}
              onChange={handleInputChange}
              maxLength={textMaxLength}
            />
            {/* {activateRequired && !value && (
              <FiAlertCircle className="alert-icon" />
            )} */}
          </>
        )}
      </div>
    </>
  );
};

export default FormFieldText;
