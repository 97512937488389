import React from 'react';


const CenterTitleTextCont = ({title, text, content}) => {

    return(
        <>
            {typeof title === 'string' ? (
                <h2 className="title-section centered-text">{title}</h2>
            ) : (
                <>{title}</>
            )}
            <p className="content-p centered-text">{text}</p>
            {content}
        </>
    )
}

export default CenterTitleTextCont